import { icon_qr } from '../../assets/Media';

export function IconQr() {
  return (
    <>
      <img src={icon_qr} alt="Qr" className="icon icon-custom" />
    </>
  );
}

