import * as React from "react";
import NavigationBar from './NavigationBar'
import { Switch, Route } from "react-router-dom";
import Routes from '../../Routes';
import { Bottom } from "./Bottom";

interface LayoutProps { }

export const Layout = (props: LayoutProps) => {
  return (
    <div>
      <NavigationBar />
      <div className="container">
        <Switch>
          {Routes.map((route: any) => (
            <Route exact path={route.path} key={route.path}>
              <route.component />
            </Route>
          ))}
        </Switch>
      </div>
      <Bottom />
    </div>
  );
};
