import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import AppConfig from "../../constants/AppConfig";
import { Dialog } from "../App/Dialog";
import { WarrantyDetail } from "./WarrantyDetail";
import { StoreContext } from "../../store/StoreProvider";
import { types as typesDialog } from "../../store/Dialog";
import { GetWarrantyRequest, CorpGetWarrantyBy } from "../../store/sagas/Warranty";
import { WarrantyListItem } from "./WarrantyListItem";
import { types } from "../../store/Warranty";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import Loading from "../Loading";
import { useDebounce } from "../../hooks/useDebounce";
// import { Alert } from "@mui/material";
import { Alert } from "../Alert";
import moment from "moment";
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { getI18N } from '../../i18n'

function createData(
  name: string,
  calories: number,
  fat: number,
  carbs: number,
  protein: number
) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
  createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
  createData("Eclair", 262, 16.0, 24, 6.0),
  createData("Cupcake", 305, 3.7, 67, 4.3),
  createData("Gingerbread", 356, 16.0, 49, 3.9),
];

interface WarrantyListProps {
  initDate?: any;
  endDate?: any;
}

// var prevCli = "";
const ACTIVE_DIALOG = "detail_warranty";

// export const WarrantyList = React.memo((props: WarrantyListProps) => {
export const WarrantyList = React.memo(function WarrantyListComponent(props: WarrantyListProps) {
  const [store, dispatch] = React.useContext(StoreContext);

  const { Country } = store?.user;
  const currentLocale: string = Country || "PE";
  const i18n = getI18N({ currentLocale })

  const [loading, setLoad] = React.useState(true);
  const [page, setPage] = React.useState(1);
  const [fetched, setFetch] = React.useState(false);
  // const [currentClient, setClient] = React.useState(prevCli);
  const currentClient = React.useRef("");
  // const currentTabActive = React.useRef(store?.active_pane);
  // const currentFetch = React.useRef(false);

  const [dataPagination, setDataPagination] = React.useState<any>(null);

  const [currentPage, setCurrentPage] = React.useState<number>(dataPagination?.page || 1);
  const pageCount = dataPagination?.pageCount || 0;
  

  const [warrantyData, setWarrantyData] = React.useState(
    !!store?.warranty_list && !!Object.keys(store?.warranty_list).length
      ? store?.warranty_list
      : []
  );

  const AlertRef = React.useRef<any>();

  const setEndLoadDebounce = useDebounce(() => {
    setLoad(false);
  }, 500);

  const getListCorp = (isMounted: boolean = false, page: number = 0)=> {
    const objCorp = {
      CodCliente: store?.selected_client.Id,
      pag: page,
      maxRows: 10,
      FechaInicio: moment(props?.initDate).format("yyyy-MM-DD"),
      FechaFin: moment(props?.endDate).format("yyyy-MM-DD")
    }

    CorpGetWarrantyBy(objCorp).then((response) => {
      // if (!isMounted) return;
      // setFetch(true);
      currentClient.current = store?.selected_client.Id;
      // setClient(store?.selected_client.Id);
      // prevCli = store?.selected_client.Id;
      // setLoad(false);
      if (response.statusCode !== 200) {
        // AlertRef?.current?.open(response.message ?? "Ocurrio un error en el servidor", 'error')
      }

      if (response.statusCode === 200) {
        setWarrantyData(response.value);
        const countList: number = (response.size);
        const sizePage: number = (objCorp.maxRows);

        let lastPage: number = Math.floor(countList / sizePage);

        if (countList % sizePage !== 0) {
          lastPage++;
        }

        const pagination = {
          page: (objCorp.pag + 1),
          pageCount: lastPage
        }

        setDataPagination(pagination);

        // dispatch({ type: types.setWarrantyList, payload: response.value });
        if (response.size <= 0) {
          // AlertRef?.current?.open(response.message ?? "No se encontraron registros", 'error')
        }

        if (response.size > 0) {
          // setWarrantyData(response.value);
        }
      }
    })
  }

  React.useEffect(() => {
    let isMounted = true;

    if (!fetched ) {

      let obj = {
        idTarjeta: -1,
        idMiniCod: -1,
        cardCode: store?.selected_client.Id,
      };

      if (
        !!Object.keys(store?.warranty_list).length &&
        store?.selected_client.Id === store?.warranty_list[0].CardCode
      ) {
        setEndLoadDebounce();
      }

      if (store?.userAppCorp) {

        if (store.active_dialog === "new_warranty" || store?.openCreateWarrantyRequest) {
          return;
        }

        getListCorp(isMounted);

        return () => {
          isMounted = false;
        };
      }

      GetWarrantyRequest(obj).then((res) => {
        if (!isMounted) return;
        setFetch(true);
        setWarrantyData(res);
        // setClient(store?.selected_client.Id);
        currentClient.current = store?.selected_client.Id;
        // prevCli = store?.selected_client.Id;
        setLoad(false);
        if (!!res.length) {
          if (
            !!Object.keys(store?.warranty_list).length &&
            res[0].CardCode !== store?.warranty_list[0].CardCode
          ) {
            dispatch({ type: types.setWarrantyList, payload: res });
          } else if (!store?.warranty_list.length) {
            dispatch({ type: types.setWarrantyList, payload: res });
          }
        } else if (!!Object.keys(store?.warranty_list).length) {
          dispatch({ type: types.setWarrantyList, payload: res });
        }
      });
    }

    return () => {
      isMounted = false;
    };
  }, []);

  /* React.useEffect(() => {

    let isMounted = true;

    if ((currentClient && currentClient !== store?.selected_client.Id)) {

      if (store?.userAppCorp) {

        const objCorp = {
          CodCliente: store?.selected_client.Id,
          pag: 0,
          maxRows: 10,
          FechaInicio: moment(props?.initDate).format("yyyy-MM-DD"),
          FechaFin: moment(props?.endDate).format("yyyy-MM-DD")
        }

        CorpGetWarrantyBy(objCorp).then((response) => {
          if (!isMounted) return;
          setFetch(true);
          setClient(store?.selected_client.Id);
          prevCli = store?.selected_client.Id;
          setLoad(false);
          if (response.statusCode !== 200) {
            // AlertRef?.current?.open(response.message ?? "Ocurrio un error en el servidor", 'error')
          }

          if (response.statusCode === 200) {
            if (response.size <= 0) {
              // AlertRef?.current?.open(response.message ?? "No se encontraron registros", 'error')
            }

            setWarrantyData(response.value);
            dispatch({ type: types.setWarrantyList, payload: response.value });

            if (response.size > 0) {

            }

          }
        })

        return () => {
          isMounted = false;
        };
      }

      let obj = {
        idTarjeta: -1,
        idMiniCod: -1,
        cardCode: store?.selected_client.Id,
      };

      GetWarrantyRequest(obj).then((res) => {
        if (!isMounted) return;
        setFetch(true);
        setWarrantyData(res);
        setClient(store?.selected_client.Id);
        prevCli = store?.selected_client.Id;
        setLoad(false);
        if (!!res.length) {
          dispatch({ type: types.setWarrantyList, payload: res });
        }
      });
    }

    return () => {
      isMounted = false;
    };
  }, [store?.selected_client]); */

  const open = () => {
    if (!store.dialog) {
      dispatch({ type: typesDialog.open, payload: ACTIVE_DIALOG });
    } else {
      dispatch({ type: typesDialog.close });
    }
  };

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setCurrentPage(value);

    getListCorp(false, (value - 1));
  };

  const search = (f: any) => {
    if (!!store?.searchWarranty) {
      let data: any = f.CardName.toLocaleUpperCase().includes(
        store?.searchWarranty.toLocaleUpperCase()
      );
      if (!data || !data.length) {
        data = f.IdMiniCod.toLocaleUpperCase().includes(
          store?.searchWarranty.toLocaleUpperCase()
        );
        if (!!data) return data;
      }
      if (!data || !data.length) {
        data = f.DocEntry.toString().includes(
          store?.searchWarranty.toLocaleUpperCase()
        );
        if (!!data) return data;
      }
      if (!data || !data.length) {
        data = f.DocumentoIdentidadCliente.toLocaleUpperCase().includes(
          store?.searchWarranty.toLocaleUpperCase()
        );
        if (!!data) return data;
      }
      if (!data || !data.length) {
        data = f.CardCode.toLocaleUpperCase().includes(
          store?.searchWarranty.toLocaleUpperCase()
        );
        if (!!data) return data;
      }
      if (!data || !data.length) {
        data = f.Modelo.toLocaleUpperCase().includes(
          store?.searchWarranty.toLocaleUpperCase()
        );
        if (!!data) return data;
      }
      if (!data || !data.length) {
        data = f.UserReg.toLocaleUpperCase().includes(
          store?.searchWarranty.toLocaleUpperCase()
        );
        if (!!data) return data;
      }
      if (!data || !data.length) {
        data = f.UserReg.toLocaleUpperCase().includes(
          store?.searchWarranty.toLocaleUpperCase()
        );
        if (!!data) return data;
      }

      return data;
    } else {
      return true;
    }
  };

  return (
    <div className="warranty-list-container">
      <Loading loading={(store?.userAppCorp ? false : loading)}>
        <TableContainer component={Paper}>
          <Table stickyHeader size="small" aria-label="warranty list">
            <TableHead>
              {store?.userAppCorp && (
                <TableRow>
                  <TableCell colSpan={4} sx={{ fontSize: "1.2rem !important", textAlign: "center" }}>
                    {i18n.WARRANTY_REQUEST}
                  </TableCell>

                </TableRow>
              )}

              {!store?.userAppCorp && (
                <TableRow>
                  <TableCell sx={{ fontSize: "0.8rem !important" }}>
                    {i18n.PRODUCT}
                  </TableCell>
                  <TableCell sx={{ fontSize: "0.8rem !important" }} align="right">
                    #{i18n.SOL}.
                  </TableCell>
                  <TableCell
                    sx={{
                      pl: 1,
                      pr: 1,
                      textAlign: "center",
                      fontSize: "0.8rem !important",
                    }}
                    align="right"
                  >
                    {i18n.STATE}.
                  </TableCell>
                  <TableCell
                    sx={{
                      pl: 1,
                      pr: 1,
                      textAlign: "center",
                      fontSize: "0.8rem !important",
                    }}
                    align="right"
                  >
                    {i18n.DETAIL}.
                  </TableCell>
                </TableRow>
              )}
            </TableHead>
            <TableBody>
              {warrantyData
                ?.filter((f: any) => search(f))
                .sort((a: any, b: any) => {
                  if (a.DocEntry < b.DocEntry) return 1;
                  if (a.DocEntry > b.DocEntry) return -1;
                  return 0;
                })
                .map((row: any) => (
                  <WarrantyListItem
                    key={row.DocEntry ?? row?.IdSolicitud}
                    {...row}
                    callback={open}
                  />
                ))}
            </TableBody>
            {/* <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                colSpan={3}
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    "aria-label": "rows per page",
                  },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter> */}
          </Table>
        </TableContainer>

        {store?.userAppCorp && (
          <Stack spacing={2} mt={1} marginBottom={1}>
            <Pagination count={pageCount} page={currentPage} showFirstButton showLastButton onChange={handlePageChange} color="primary" />
          </Stack>
        )}
      </Loading>
      {store.active_dialog === ACTIVE_DIALOG && (
        <Dialog title={i18n.WARRANTY_REQUEST_DETAIL} closeCallback={open}>
          <WarrantyDetail />
        </Dialog>
      )}

      <Alert ref={AlertRef} />
    </div>
  );
});
