import * as React from "react";
import CircularProgress from "@mui/material/CircularProgress";

interface LoadingProps {
  loading?: boolean;
  children?: any;
  size?: any;
  color?: any;
}

const Loading = (props: LoadingProps) => {
  return (
    <div>{!!props.loading ? <CircularProgress color={props?.color || 'primary'} size={props?.size || 20}  /> : <>{props.children}</>}</div>
  );
};

export default Loading;
