import * as React from "react";
// import SearchIcon from "@mui/icons-material/Search";
// import { ClientCard } from "./ClientCard";
// import Tabs from "@mui/material/Tabs";
// import Tab from "@mui/material/Tab";
// import Box from "@mui/material/Box";
// import { TabPanel } from "../Home/TabPanel";
// import { TabOne } from "../Home/TabOne";
// import { TabTwo } from "../Home/TabTwo";

import { IconButton, TextField } from "@material-ui/core";
import { StoreContext } from "../../store/StoreProvider";
import { Typography } from "@material-ui/core";
// import { types as typesDialog } from "../../store/Dialog";
// import { Dialog } from "./Dialog";
// import { NewWarranty } from "../Home/NewWarranty";
// import { NewWarrantyForm } from "../Home/NewWarrantyForm";
// import { ClientList } from "./ClientList";
// import { Camera } from "./Camera";
// import { AddWarranty } from "../icons/AddWarranty";
// import { Bottom } from "./Bottom";
import { types } from "../../store/Warranty";
import { getI18N } from '../../i18n'

interface ActivationBarProps {}

const validTypes = {
  code: "SC",
  noCode: "SS",
};
const ACTIVE_DIALOG = "new_warranty";

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}



function SearchBar(){
  const [store, dispatch] = React.useContext(StoreContext);
  const [activeSearch, setSearch] = React.useState(false)

  const { Country } = store?.user;
  const currentLocale: string = Country || "PE";
  const i18n = getI18N({ currentLocale })

  const search = (search:string) => {
    if (!search) {
      dispatch({ type: types.clearSearchWarranty });
    } else {
      dispatch({ type: types.setSearchWarranty, payload: search});
    }
  }

  return (
    <>
      <div className="header-title-container">
        {!!activeSearch ? (
          <TextField
            fullWidth
            className="input-maintenance uppercase"
            label="Buscar"
            variant="standard"
            type="search"
            onChange={(event) => search(event.target.value)}
          />
        ) : (
          <Typography variant="h6">{i18n.MY_ACTIVATIONS}</Typography>
        )}
      </div>
      {/* <IconButton
        edge="end"
        className={`pull-right`}
        color="inherit"
        onClick={() => setSearch(!activeSearch)}
      >
        <SearchIcon />
      </IconButton> */}
    </>
  );
}

export const ActivationBar = React.memo(function ActivationBarComponent(
  props: ActivationBarProps
) {

  return (
    <>
      <SearchBar />
    </>
  );
});
