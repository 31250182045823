import * as React from "react";
import { StoreContext } from "../../store/StoreProvider";
import { Typography } from "@material-ui/core";
import { getI18N } from '../../i18n'

interface RewardBarProps { }


function RewardHeaderBar() {
    const [store, dispatch] = React.useContext(StoreContext);
    // const [activeSearch, setSearch] = React.useState(false)

    const { Country } = store?.user;
    const currentLocale: string = Country || "PE";
    const i18n = getI18N({ currentLocale })

    return (
        <>
            <div className="header-title-container">
                <Typography variant="h6">{i18n.REWARDS}</Typography>
            </div>

        </>
    );
}

export const RewardBar = React.memo(function RewardBarComponent(
    props: RewardBarProps
) {

    return (
        <>
            <RewardHeaderBar />
        </>
    );
});
