import * as React from "react";
import Box from "@mui/material/Box";
import DefaultAlert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import Slide from "@mui/material/Slide";
import CloseIcon from "@mui/icons-material/Close";

export const Alert= React.forwardRef((props: any, ref:any) => {
  const [open, setOpen] = React.useState(false);
  const [msg, setMsg] = React.useState('');
  const [severity, setSev] = React.useState(props.severity || "info");

  React.useImperativeHandle(ref, () => ({
    open: (msg: string, severity:string) => {
      setSev(severity);
      setMsg(msg);
      setOpen(true);
      setTimeout(() => {
        setOpen(false);
      }, props.seconds || 3000);
    },
  }));

  return (
    <div className={!open ? 'hidden' : "alert-absolute"}>
      <Box sx={{ width: "100%" }}>
        <Slide direction="up" in={open}>
          <DefaultAlert
            severity={severity}
            sx={{ mb: 2 }}
          >
            {msg}
          </DefaultAlert>
        </Slide>
      </Box>
    </div>
  );
})
