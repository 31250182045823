import React from "react";
import { api } from "./api";

export const getClientsRequest = (payload) => api.get(`/Customer`, payload);
export const getBusinessPartnersRequest = (payload) => api.get(`/BusinessPartners`, payload);

// corporativo
export const getCorpBusinessPartnersRequest = (payload) => api.get(`/SocioNegocio/GetBusinessPartners`, payload, true);

// bmg
export const bmgSendMessageRequest = (payload) => api.bmgPost(`/externo/gardi/send-message`, payload);

// enerjet
export const clientPointsGetExtRequest = (payload) => api.enerjetPost(`/ext/cliente-puntos-get`, payload);
export const clientPointsAddExtRequest = (payload) => api.enerjetPost(`/ext/cliente-puntos-add`, payload);
export const clientAddressExtRequest = (payload) => api.enerjetPost(`/ext/cliente-direcciones`, payload);
export const merchListExtRequest = (payload) => api.enerjetPost(`/ext/merch-list`, payload);
export const merchListHistoryExtRequest = (payload) => api.enerjetPost(`/ext/cliente-merch-historial`, payload);
export const clientOrderAddExtRequest = (payload) => api.enerjetPost(`/ext/pedido-add`, payload);
