import * as React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import moment from "moment";
import IconButton from "@mui/material/IconButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import AppConfig from "../../constants/AppConfig";
import { StoreContext } from "../../store/StoreProvider";
import { types } from "../../store/Warranty";
import Loading from "../Loading";
import { getI18N } from '../../i18n'

const {
  functions: { setColorWarrantyState },
} = AppConfig;

interface WarrantyActivationListItemProps { }

export const WarrantyActivationListItem = (props: any) => {
  const [store, dispatch] = React.useContext(StoreContext);
  const [loading, setLoad] = React.useState(false);

  const { Country } = store?.user;
  const currentLocale: string = Country || "PE";
  const i18n = getI18N({ currentLocale })

  const setDetail = () => {
    setLoad(true);
    props.setDetailParent(props).then(() => {
      setLoad(false);
    }).finally(() => {
      setLoad(false);
    })
  };
  // console.log(props)
  /*const dataMarModelo = store?.selectData.MarModeloV.find((f: any) => f.Cod === props?.Cod);
  const dataModelo = store?.selectData.ModeloV.find((f: any) => f.Name === newObj["Valor"]);*/

  return (
    <>
      <TableRow>
        <TableCell colSpan={4} align="left" sx={{ border: 0, padding: 0 }} style={{ paddingRight: 15 }}>


          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={1}>
              <Grid item xs={10}>
                <div className="col">
                  <h3 className="w-title" style={{ margin: 5, marginLeft: 20 }}>
                    
                    {store?.userAppCorp ? props?.Placa : props?.DatosUsuario ? `${props?.DatosUsuario[3]?.Valor} - ${props?.DatosUsuario[0]?.Valor}` : ""}
                    {/* {props?.DatosUsuario[3]?.Valor} - {props?.DatosUsuario[0]?.Valor} */}
                  </h3>
                </div>
              </Grid>
              <Grid item xs={2}>
                <div className="col" style={{ alignItems: "center" }} >

                  <Loading loading={loading}>
                    <IconButton onClick={setDetail} color="inherit">
                      <VisibilityIcon sx={{ fontSize: "18px" }} />
                    </IconButton>
                  </Loading>
                </div>
              </Grid>
            </Grid>
          </Box>
        </TableCell>
      </TableRow>

      <TableRow
        key={props?.name}
        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
      >
        <TableCell
          scope="row"
          style={{ minWidth: 200, margin: 5, paddingLeft: 20 }}
        >
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={1}>
              <Grid item xs={5}>
                <div className="col">
                  <span className="w-title">{i18n.ACTIVATION_DATE}</span>
                  <span className="w-subtitle">
                    {moment(props?.FecReg ?? props?.FechaRegistro).format("DD/MM/yyyy")}
                  </span>
                </div>
              </Grid>
              <Grid item xs={5}>
                <div className="col">
                  <span className="w-title">{store?.userAppCorp ? i18n.NUMBER_OF_BATTERIES : i18n.PLATE}</span>
                  <span className="w-subtitle">{store?.userAppCorp ? props?.CantMiniCodigos : props?.Placa}</span>
                </div>
              </Grid>
              <Grid item xs={2}>
                <div className="col" style={{ alignItems: "center" }}>
                  <span className="w-title">ID</span>
                  <span className="w-subtitle">#{store?.userAppCorp ? props?.IdTarjeta : props?.Id}</span>
                </div>
              </Grid>
              <Grid item xs={5}>
                <div className="col">
                  <span className="w-title">{i18n.MODEL}</span>
                  <span className="w-subtitle">{props?.Modelo ?? props?.NomModelo}</span>
                </div>
              </Grid>

              <Grid item xs={5}>
                <div className="col">
                  <span className="w-title">{store?.userAppCorp ? "N de revisiones" : `${i18n.BATTERY_CODE}`}</span>
                  <span className="w-subtitle">{store?.userAppCorp ? props?.CantidadRevisiones ?? "0" : props?.IdMiniCod}</span>
                </div>
              </Grid>

            </Grid>
          </Box>
        </TableCell>
        {/* <TableCell align="right">
          <span className="w-number">#{props?.Id}</span>
        </TableCell> */}

        {/* <TableCell align="center" sx={{ pl: 1, pr: 1, textAlign: "center" }}>
          <Loading loading={loading}>
            <IconButton onClick={setDetail}>
              <VisibilityIcon sx={{ fontSize: "18px" }} />
            </IconButton>
          </Loading>
        </TableCell> */}
      </TableRow>
    </>
  );
};
