import * as React from "react";
import Dialog from "@mui/material/Dialog";
// import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@material-ui/core/Button";
// import Radio from "@mui/material/Radio";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";

import { Alert } from "../Alert";
// import Loading from "../Loading";
import { setVersionApp } from "../../store/sagas/Main";
import { StoreContext } from '../../store/StoreProvider';
import { getI18N } from '../../i18n'

interface UpdateVersionModalProps {
  open?: boolean;
}
export const UpdateVersionModal = React.memo((props: UpdateVersionModalProps) => {
  const [store, dispatch] = React.useContext(StoreContext);
  const AlertRef = React.useRef<any>();
  // const [loading, setLoad] = React.useState(false);
  const [hide, setHide] = React.useState(false);

  const { Country } = store?.user;
  const currentLocale: string = Country || "PE";
  const i18n = getI18N({ currentLocale })

  const reloadPageWithoutCache = () => {
    if( 'caches' in window ){
      caches.keys().then((names) => {
        names.forEach((name) => {
          caches.delete(name);
        });
      });
    }

    window.location.reload();
  };

  const send = () => {
    reloadPageWithoutCache();
    /*new Promise((resolve, reject) => {
      setVersionApp({version: process.env.REACT_APP_VERSION}).then((response) => {
        if( response.status === 200 ){
          reloadPageWithoutCache();
        }
      });
    });*/
  };

  return (
    <div style={{}}>
      <Dialog open={props.open ?? false} onClose={() => console.log()} className={!!hide ? "hide-address modal-custom-bmg" : "modal-custom-bmg"}>
        <DialogTitle style={{textAlign: "center"}}>{i18n.VERSION_UPDATE.TITLE}</DialogTitle>
        <DialogContent>
          <FormControl>
            <FormLabel style={{textAlign: "center", color: "#000"}}>
              {i18n.VERSION_UPDATE.DESCRIPTION}
            </FormLabel>

            <Button onClick={send}
              variant="contained"
              color="primary"
              className="btn-bmg-update-version">
                <strong>{i18n.UPDATE}</strong>
            </Button>

            <FormLabel className="mt-2" style={{textAlign: "center",fontSize: "14px"}}>
              {i18n.VERSION_UPDATE.INFO}
            </FormLabel>

          </FormControl>
        </DialogContent>
        
        <Alert ref={AlertRef} />
      </Dialog>
    </div>
  );
});
