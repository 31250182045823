import React from 'react';
import { icon_cart } from '../../assets/Media';

export function IconCart() {
  return (
    <>
      <img src={icon_cart} alt="Recompensa" className="icon" style={{ width: "1.5rem", height: "1.5rem"}} />
    </>
  );
}

