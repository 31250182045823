import spanish from '../i18n/es.json';
import english from '../i18n/en.json';

const LANG = {
	ENGLISH: 'en',
	SPANISH: 'es',
};

const COUNTRY = {
	'NAC': 'es',
	'PE': 'es',
	'TT': 'en',
};

export const getI18N = ({ currentLocale = 'PE', }: { currentLocale: string | undefined;}) => {
	// const currentLang = '';
	if (currentLocale === 'TT') return {...spanish, ...english};
	return spanish;
};