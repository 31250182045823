import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@material-ui/core/Button";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import MobileDatePicker from "@mui/lab/MobileDatePicker";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import VisibilityIcon from "@mui/icons-material/Visibility";
import moment from "moment";
import { Typography } from "@material-ui/core";
import CheckIcon from "@mui/icons-material/Check";
import { Maintenance } from "./Maintenance";
import { StoreContext } from "../../store/StoreProvider";
import { MultiInput } from "../MultiInput";
import Loading from "../Loading";
import Autocomplete from "@mui/material/Autocomplete";

import { AddressModal } from "./AddressModal";
import { types } from "../../store/Warranty";
import { WarrantyCard, WarrantyCardDetail } from "../../store/sagas/Warranty";
import { Bottom } from "../App/Bottom";
import { Ecuador } from "./countries/ecuador";
import { getI18N } from '../../i18n'

var ACTIVE_UPDATE = "";

function DisplaySelects() {
  const [store, dispatch] = React.useContext(StoreContext);
  const { battery } = store;
  let dateLocal =
    battery?.FechaVenta.indexOf("T") != -1
      ? battery?.FechaVenta
      : moment(battery?.FechaVenta, "DD-MM-yyyy").format("LLLL");
  const [value, setValue] = React.useState<Date | null>(new Date(dateLocal));
  const [Garantia, setGarantia] = React.useState(
    battery?.Garantia === "0" ? "" : battery?.Garantia
  );
  const [Mantenimiento, setMantenimiento] = React.useState(
    battery?.Mantenimiento === "0" ? "" : battery?.Mantenimiento
  );

  const { Country } = store?.user;
  const currentLocale: string = Country || "PE";
  const i18n = getI18N({ currentLocale })

  const handleChangeSelect = (event: SelectChangeEvent) => {
    setGarantia(event.target.value);
  };

  const handleChangeDate = (newValue: Date | null) => {
    setValue(newValue);
  };

  return (
    <div className="input-container container-bg">
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={1}>
          <Grid item xs={4}>
            <FormControl fullWidth>
              <MobileDatePicker
                label={i18n.DATE}
                inputFormat="DD-MM-yyyy"
                value={value}
                onChange={handleChangeDate}
                renderInput={(params: any) => (
                  <TextField {...params} required={true} />
                )}
                disabled={true}
                data-keyword={"FecUpd"}
                data-parent={"FecUpd"}
              />
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl fullWidth>
              <InputLabel id="select-garantia-label">
                {i18n.WARRANTY_PERIOD} ({i18n.MONTHS})
              </InputLabel>
              <Select
                required={true}
                className="input-battery"
                labelId="select-garantia-label"
                id="select-garantia"
                value={Garantia}
                label="Garantia"
                onChange={(event) => setGarantia(event.target.value)}
                disabled={Number(battery?.Id) === -1 ? false : true}
                data-keyword={"Garantia"}
                data-parent={"Garantia"}
              >
                <option aria-label="None" value="" />
                <MenuItem value={6}>6</MenuItem>
                <MenuItem value={9}>9</MenuItem>
                <MenuItem value={12}>12</MenuItem>
                <MenuItem value={18}>18</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl fullWidth>
              <InputLabel id="select-mantenimiento-label">
                {i18n.REVIEW_MAINTENANCE_PERIOD} ({i18n.MONTHS})
              </InputLabel>
              <Select
                className="input-battery"
                labelId="select-mantenimiento-label"
                id="select-mantenimiento"
                value={Mantenimiento}
                label="Mantenimiento"
                onChange={(event) => setMantenimiento(event.target.value)}
                disabled={Number(battery?.Id) === -1 ? false : true}
                data-keyword={"Mantenimiento"}
                data-parent={"Mantenimiento"}
              >
                <option aria-label="None" value="" />
                <MenuItem value={1}>1</MenuItem>
                <MenuItem value={2}>2</MenuItem>
                <MenuItem value={3}>3</MenuItem>
                <MenuItem value={4}>4</MenuItem>
                <MenuItem value={5}>5</MenuItem>
                <MenuItem value={6}>6</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}

const UserData = () => {
  const [store, dispatch] = React.useContext(StoreContext);
  const [showUpd, setUpd] = React.useState(false);
  const { battery } = store;

  const { Country } = store?.user;
  const currentLocale: string = Country || "PE";
  const i18n = getI18N({ currentLocale })

  let num = 0;
  for (let index = 0; index < battery?.DatosUsuario.length; index++) {
    if (!!battery?.DatosUsuario[index].Valor) num++;
  }

  const updateRequest = () => {
    ACTIVE_UPDATE = "USER";
    let btn: any = document?.getElementById("btn-submit");
    btn?.click();
  };

  return (
    <details
      className={`details active ${num !== battery?.DatosUsuario.length ? "" : "success"
        }`}
    >
      <summary>
        <strong>{i18n.CLIENT_DATA}</strong>
        <div className="arrow-container d-flex">
          <progress max={battery?.DatosUsuario.length} value={num}></progress>
          <span
            className={
              num !== battery?.DatosUsuario.length ? "primary" : "success"
            }
          >{`(${num}/${battery?.DatosUsuario.length})`}</span>
          <ArrowDropDownIcon />
        </div>
      </summary>
      <div className="details-body">
        {battery?.DatosUsuario.map((item: any, i: number) => {
          let placeholder = item?.PlaceHolder;
          if (!!item?.FlgObligatorio) placeholder = `${placeholder} (*)`;
          let update = Number(battery?.Id) !== -1 ? true : false;

          return (
            <MultiInput
              key={i}
              data={item}
              requred={item?.FlgObligatorio}
              placeholder={placeholder}
              className="input-battery"
              parent="DatosUsuario"
              disabled={true}
              keyword={JSON.stringify(item)}
              update={update}
              onChange={() => {
                if (!showUpd) setUpd(true);
              }}
              callback={updateRequest}
            />
          );
        })}
      </div>
      {!!showUpd && (
        <Button
          fullWidth
          variant="contained"
          className="btn-success"
          color="secondary"
          onClick={updateRequest}
        >
          <strong>{i18n.UPDATE}</strong>
        </Button>
      )}
    </details>
  );
};

const CarData = () => {
  const [store, dispatch] = React.useContext(StoreContext);
  const { battery } = store;

  const { Country } = store?.user;
  const currentLocale: string = Country || "PE";
  const i18n = getI18N({ currentLocale })

  let num = 0;
  for (let index = 0; index < battery?.DatosVehiculo.length; index++) {
    if (!!battery?.DatosVehiculo[index].Valor) num++;
  }

  const [showUpd, setUpd] = React.useState(false);
  const updateRequest = () => {
    ACTIVE_UPDATE = "CAR";
    let btn: any = document?.getElementById("btn-submit");
    btn?.click();
  };

  return (
    <details
      className={`details active ${num != battery?.DatosVehiculo.length ? "" : "success"
        }`}
    >
      <summary>
        <strong>{i18n.VEHICLE_DATA}</strong>
        <div className="arrow-container d-flex">
          <progress max={battery?.DatosVehiculo.length} value={num}></progress>
          <span
            className={
              num != battery?.DatosVehiculo.length ? "primary" : "success"
            }
          >{`(${num}/${battery?.DatosVehiculo.length})`}</span>
          <ArrowDropDownIcon />
        </div>
      </summary>
      <div className="details-body">
        {battery?.DatosVehiculo.map((item: any, i: number) => {
          let placeholder = item?.PlaceHolder;
          if (!!item?.FlgObligatorio) placeholder = `${placeholder} (*)`;
          let update = Number(battery?.Id) != -1 ? true : false;
          return (
            <MultiInput
              key={i}
              data={item}
              requred={item?.FlgObligatorio}
              placeholder={placeholder}
              className="input-battery"
              parent="DatosVehiculo"
              keyword={JSON.stringify(item)}
              update={update}
              onChange={() => {
                if (!showUpd) setUpd(true);
              }}
              callback={updateRequest}
            />
          );
        })}
      </div>
      {!!showUpd && (
        <Button
          fullWidth
          variant="contained"
          className="btn-success"
          color="secondary"
          onClick={updateRequest}
        >
          <strong>{i18n.UPDATE}</strong>
        </Button>
      )}
    </details>
  );
};

interface NewBatteryProps {
  callback?: any;
}

var elementForm: any;


const RenderByCountry = () => {
  const [store, dispatch] = React.useContext(StoreContext);

  switch (store?.user?.Country) {
    case "PE":
      return (
        <>
          <UserData />
          <CarData />
        </>
      );
      break;
    case "EC":
      return (<Ecuador />)
      break;
    case "VE":
      return (<></>)
      break;
    case "CL":
      return (<></>)
      break;

    default:
      return <></>;
      break;
  }
}

export const NewBatteryForm = (props: NewBatteryProps) => {
  const [dense, setDense] = React.useState(false);
  const [index, setIndex] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const [store, dispatch] = React.useContext(StoreContext);
  const [loading, setLoad] = React.useState(false);
  const [newMant, setMant] = React.useState(false);
  const { battery } = store;

  const { Country } = store?.user;
  const currentLocale: string = Country || "PE";
  const i18n = getI18N({ currentLocale })

  const closeToggle = (index: number) => {
    if (newMant) {
      setMant(false);
    }
    setIndex(index);
    setOpen(!open);
  };

  function Model() {
    const [store, dispatch] = React.useContext(StoreContext);
    let Mensaje = !!Object.keys(store?.battery).length
      ? store?.battery?.Modelo
      : "";
    const [model, setModel] = React.useState<string>(Mensaje);

    return (
      <TextField
        fullWidth
        className="input-battery uppercase"
        label="Modelo de bateria"
        variant="outlined"
        id="batteryModel"
        value={model}
        disabled={Number(battery?.Id) === -1 ? false : true}
        onChange={(event) => setModel(event.target.value)}
      />
    );
  }


  const MaintenanceData = () => {
    const [data, setData] = React.useState(
      battery?.DatosMantenimiento.length > 1
        ? battery?.DatosMantenimiento.filter(
          (dataItem: any) => dataItem?.Id != 0
        )
        : battery?.DatosMantenimiento
    );
    return (
      <details className="details" open>
        <summary>
          <strong>{i18n.MAINTENANCE_DATA}</strong>
          <div className="arrow-container">
            <ArrowDropDownIcon />
          </div>
        </summary>
        <List dense={dense}>
          {data
            .sort((a: any, b: any) => {
              if (a.Id < b.Id) return -1;
              if (a.Id > b.Id) return 1;
              return 0;
            })
            .map((item: any, key: number) => (
              <ListItem
                key={key}
                secondaryAction={
                  <IconButton
                    edge="end"
                    aria-label="mantenimiento"
                    onClick={() => closeToggle(key)}
                  >
                    <VisibilityIcon />
                  </IconButton>
                }
              >
                <ListItemAvatar>
                  <Avatar>{item.Id}</Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary="Fecha"
                  secondary={moment(item.FecReg).format("DD/MM/yyyy")}
                />
              </ListItem>
            ))}
        </List>
      </details>
    );
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    setLoad(true);

    let DatosUsuario: any = [],
      DatosVehiculo: any = [],
      Garantia: any = [],
      Mantenimiento: any = [],
      DatosMantenimiento: any = [];
    let { battery } = store;
    for (const key in e.target.elements) {
      if (Object.prototype.hasOwnProperty.call(e.target.elements, key)) {
        const element = e.target.elements[key];
        if (
          !isNaN(Number(key)) &&
          (element.tagName === "INPUT" ||
            (element.tagName !== "FIELDSET" && element.tagName !== "BUTTON"))
        ) {
          if (element.tagName === "INPUT") {
            let originalObj = !!Object.keys(
              element.parentElement.parentElement.dataset
            ).length
              ? element.parentElement.parentElement.dataset
              : !!Object.keys(
                element.parentElement.parentElement.parentElement.dataset
              ).length
                ? element.parentElement.parentElement.parentElement.dataset
                : element.parentElement.dataset;
            if (originalObj.parent === "DatosUsuario") {
              let newObj = JSON.parse(originalObj.keyword);
              newObj["Valor"] = element.value;
              DatosUsuario.push(newObj);
            }
            if (originalObj.parent === "DatosVehiculo") {
              let newObj = JSON.parse(originalObj.keyword);
              newObj["Valor"] = element.value;
              DatosVehiculo.push(newObj);
            }
            if (originalObj.parent === "DatosMantenimiento") {
              let newObj = JSON.parse(originalObj.keyword);
              newObj["Valor"] = element.value;
              DatosMantenimiento.push(newObj);
            }
            if (originalObj.parent === "Garantia") {
              battery["Garantia"] = element.value;
            }
            if (originalObj.parent === "Mantenimiento") {
              battery["Mantenimiento"] = element.value;
            }
            if (Number(key) === 0) {
              battery["FechaVenta"] = moment(element.value, "DD/MM/YYYY").format();
              battery["FecReg"] = moment(element.value, "DD/MM/YYYY").format();
              battery["FecUpd"] = moment(element.value, "DD/MM/YYYY").format();
            }
          }
        }
      }
    }
    let model: any = window?.document?.getElementById("batteryModel");
    battery["Modelo"] = model?.value;
    battery["DatosUsuario"] = DatosUsuario;
    battery["DatosVehiculo"] = DatosVehiculo;
    // // tarjeta sin codigo
    battery["TIPO"] = "TS"

    if (!!Object.keys(store.selected_client).length) {
      dispatch({ type: types.openAddress, payload: battery });
    } else {
      alert("Debe seleccionar un cliente");
      setLoad(false);
    }

  };

  return (
    <div className="new-battery-container">
      <Model />
      <form
        action=""
        onSubmit={handleSubmit}
        className="new-battery-container"
        id="frm-battery"
      >
        <DisplaySelects />
        <div className="details-container">
          <RenderByCountry />
          {Number(battery?.Id) !== -1 && <MaintenanceData />}
        </div>
        <div className="button-container">
          {Number(battery?.Id) !== -1 ? (
            <div>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                className="hidden"
                type="submit"
                disabled={loading}
                id="btn-submit"
              >
                <Loading loading={loading}>
                  <strong>{i18n.REGISTER_BATTERY}</strong>
                </Loading>
              </Button>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={(e) => {
                  e.preventDefault();
                  setMant(true);
                  setIndex(0);
                  setOpen(!open);
                }}
                disabled={loading}
              >
                <Loading loading={loading}>
                  <strong>+ {i18n.REGISTER_MAINTENANCE}</strong>
                </Loading>
              </Button>
            </div>
          ) : (
            <Button
              fullWidth
              variant="contained"
              color="primary"
              type="submit"
              disabled={loading}
              id="btn-submit"
            >
              <Loading loading={loading}>
                <strong>{i18n.REGISTER_BATTERY}</strong>
              </Loading>
            </Button>
          )}
        </div>
      </form>

      <AddressModal callback={props.callback} />

      <Maintenance
        open={open}
        handleClose={closeToggle}
        data={battery}
        index={index}
        new={newMant}
      />
      <Bottom />
    </div>
  );
};
