import React from 'react';
import { icon_reward } from '../../assets/Media';

export function IconReward() {
  return (
    <>
      <img src={icon_reward} alt="Recompensa" className="icon" style={{ width: "1.5rem", height: "1.5rem", marginRight: "1rem", }} />
    </>
  );
}

