import * as React from 'react';
import { useHistory } from "react-router-dom";
import { types } from './store/Dialog';
import { StoreContext } from './store/StoreProvider';

interface BackProps { }

export const Back = (props: BackProps) => {

  const [locationKeys, setLocationKeys] = React.useState<any>([]);
  const [store, dispatch] = React.useContext(StoreContext)
  const history = useHistory();

  React.useEffect(() => {
    return history.listen((location) => {
      if (history.action === "PUSH") {
        setLocationKeys([location.key]);
      }

      if (history.action === "POP") {
        if (locationKeys[1] === location.key) {
          setLocationKeys(([_, ...keys]: any) => keys);
          // Handle forward event
        } else {
          setLocationKeys((keys: any) => [location.key, ...keys]);
          if (store.dialog) {
            dispatch({ type: types.close });
          }
        }

        history.go(1);
      }
    });
  }, [store.dialog, locationKeys]);
  return (
    <>
    </>
  );
};

