import { createTheme } from "@material-ui/core/styles";
const primary = "#FFE600",
  secondary = "#084dcd",
  success = "#37cd08",
  danger = "#a31111",
  blueblack = "#2E5CA7!important";

/*const fontsApp = {
  
};*/

const user_types = {
  seller: "RV",
  partner: "SN",
  sellerApp: "GARDICorpPWA",
  partnerApp: "GARDIPWA",
  corpApp: "PWA GARDI CORP"
};

const user_url = {
  seller: "representante",
  partner: "socio",
};

const userApi = {
  sellerApp: "http://20.14.133.25:8086",
  corpApp: "http://20.14.133.25:8105"
};

const userTypeCode = {
  corporate: 110
}

const userItemsType = {
  DOCUMENT: 1,
  PHONE: 2,
  EMAIL: 3,
  NAMES: 4
}

const theme = createTheme({
  palette: {
    primary: {
      main: primary,
    },
    secondary: {
      main: secondary,
    },
    success: {
      main: success,
    },
  },
});

const validTypes = {
  client: "CLIENT",
  battery: "BATTERY",
  warranty: "WARRANTY",
  warrantyDetail: "WARRANTY_DETAIL",
};

const vehicleItemsType = {
  PLACA: 1,
  COMBUSTION: 2,
  MARCA: 3,
  MODELO: 4,
  TIPO_USO: 5,
  KILOMETRAJE: 6,
  UNIDAD_MOBILE: 7 // corporativo
};

const maintenanceItemsType = {
  VOLTAJE: 1,
  CCA: 3,
  VC_SIN_LUCES: 4,
  VC_CON_LUCES: 5,
  CORRIENTE_SIN_CARGA: 6,
  CORRIENTE_CON_CARGA: 7,
  CELDA_1: 8,
  CELDA_2: 9,
  CELDA_3: 10,
  CELDA_4: 11,
  CELDA_5: 12,
  CELDA_6: 13,
  PRU_ALTA_DESC_AMP: 14,
  CCA_SCANNER: 15,
  KILOMETRAJE: 16,
  PORCENTAJE: 17,
  OBSERVACIONES: 18,
  IMAGEN: 19
};

const AppConfig = {
  THEME: theme,
  types: { ...user_types },
  validTypes,
  userTypeCode,
  vehicleItemsType,
  userItemsType,
  maintenanceItemsType,
  userApi,
  url: { ...user_url },
  functions: {
    setColorWarrantyState: (state: number) => {
      let color: string = secondary;
      switch (state) {
        case 1:
          color = secondary;
          break;
        case 2:
          color = primary;
          break;
        case 3:
          color = success;
          break;
        case 4:
          color = danger;
          break;
        default:
          color = secondary;
          break;
      }

      return color;
    },
    setLabelWarrantyState: (state: number) => {
      let stateLabel: string = "Recibido";
      switch (state) {
        case 1:
          stateLabel = "Recibido";
          break;
        case 2:
          stateLabel = "En proceso";
          break;
        case 3:
          stateLabel = "Aprobado";
          break;
        case 4:
          stateLabel = "Rechazado";
          break;
        default:
          stateLabel = "Recibido";
          break;
      }
      return stateLabel;
    },
    setLabelWarrantyStateEn: (state: number) => {
      let stateLabel: string = "Received";
      switch (state) {
        case 1:
          stateLabel = "Received";
          break;
        case 2:
          stateLabel = "In Progress";
          break;
        case 3:
          stateLabel = "Accepted";
          break;
        case 4:
          stateLabel = "Refused";
          break;
        default:
          stateLabel = "Refused";
          break;
      }
      return stateLabel;
    },
  },
  colors: {
    primary: primary,
    secondary: secondary,
    success: success,
    danger: danger,
    blueblack: blueblack
  },
};

export default AppConfig;
