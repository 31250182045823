import * as React from "react";
import { ClientCard } from "../App/ClientCard";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import CheckIcon from "@mui/icons-material/Check";
import Button from "@material-ui/core/Button";
import { StoreContext } from "../../store/StoreProvider";
import { MultiInput } from "../MultiInput";
import { AddressModal } from "./AddressModal";
import { types } from "../../store/Warranty";
import Autocomplete from "@mui/material/Autocomplete";
import { Alert } from "../Alert";
import { getI18N } from '../../i18n'

interface NewWarrantyProps {
  callback?: any;
}

function Model() {
  const [store, dispatch] = React.useContext(StoreContext);
  let Mensaje = !!Object.keys(store?.newWarranty).length
    ? store?.newWarranty?.Modelo
    : "";
  const [model, setModel] = React.useState<string>(Mensaje);

  const { Country } = store?.user;
  const currentLocale: string = Country || "PE";
  const i18n = getI18N({ currentLocale })

  return (
    <>
      <TextField
        fullWidth
        className="input-battery uppercase"
        label={i18n.BATTERY_MODEL}
        variant="outlined"
        id="batteryModel"
        value={model}
        onChange={(event) => setModel(event.target.value)}
        required={store?.userAppCorp}
      />
      {/* {!!store?.battery?.Placa && (
        <TextField
          fullWidth
          className="input-battery"
          label="Placa de vehículo"
          variant="outlined"
          value={store?.battery?.Placa}
          disabled={true}
        />
      )} */}
    </>
  );
}

function CommentArea() {
  const [store, dispatch] = React.useContext(StoreContext);
  let Mensaje = !!Object.keys(store?.newWarranty).length
    ? store?.newWarranty?.Conversacion[0].Mensaje
    : "";
  const [comment, setComment] = React.useState<string>(Mensaje);

  const { Country } = store?.user;
  const currentLocale: string = Country || "PE";
  const i18n = getI18N({ currentLocale })

  return (
    <div className="comment-area">
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              className="input-battery"
              id="CommentArea"
              label={i18n.COMMENT}
              sx={{ backgroundColor: "white" }}
              variant="outlined"
              value={comment}
              onChange={(event) => setComment(event.target.value)}
              data-parent="DatosComentario"
              required={true}
              data-keyword={JSON.stringify({
                Docentry: 0,
                BaseEntry: 0,
                Id: 0,
                Tipo: store?.user?.tipo,
                Mensaje: "",
                UsrReg: store?.user?.user_login,
                FlgEnviado: false,
                FlgLeido: false,
              })}
            />
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}

const RenderForm = () => {
  const [store, dispatch] = React.useContext(StoreContext);

  if (!!Object.keys(store?.newWarranty).length) {
    return (
      <>
        {store?.newWarranty?.Datos?.map((item: any, i: number) => {
          let placeholder = item?.PlaceHolder;
          if (!!item?.FlgObligatorio) placeholder = `${placeholder} (*)`;

          return (
            <MultiInput
              key={i}
              data={item}
              requred={item?.FlgObligatorio}
              placeholder={placeholder}
              className="input-battery"
              parent="DatosUsuario"
              keyword={JSON.stringify(item)}
            />
          );
        })}
      </>
    );
  }

  return (
    <>
      {store?.warrantyFront?.map((item: any, i: number) => {
        let placeholder = item?.PlaceHolder;
        if (!!item?.FlgObligatorio) placeholder = `${placeholder} (*)`;

        return (
          <MultiInput
            key={i}
            data={item}
            requred={item?.FlgObligatorio}
            placeholder={placeholder}
            className="input-battery"
            parent="DatosUsuario"
            keyword={JSON.stringify(item)}
          />
        );
      })}
    </>
  );
};

export const NewWarrantyForm = (props: NewWarrantyProps) => {
  const [store, dispatch] = React.useContext(StoreContext);
  const [loading, setLoad] = React.useState(false);

  const AlertRef = React.useRef<any>();

  const { Country } = store?.user;
  const currentLocale: string = Country || "PE";
  const i18n = getI18N({ currentLocale })

  const handleSubmit = (e: any) => {
    e.preventDefault();
    setLoad(true);

    let DatosUsuario: any = [],
      DatosComentario: any = [],
      Garantia: any = [],
      Mantenimiento: any = [],
      DatosMantenimiento: any = [];

    for (const key in e.target.elements) {
      if (Object.prototype.hasOwnProperty.call(e.target.elements, key)) {
        const element = e.target.elements[key];
        if (
          !isNaN(Number(key)) &&
          (element.tagName === "INPUT" ||
            (element.tagName !== "FIELDSET" && element.tagName !== "BUTTON"))
        ) {
          if (element.tagName === "INPUT") {
            let originalObj = !!Object.keys(
              element.parentElement.parentElement.dataset
            ).length
              ? element.parentElement.parentElement.dataset
              : !!Object.keys(
                element.parentElement.parentElement.parentElement.dataset
              ).length
                ? element.parentElement.parentElement.parentElement.dataset
                : element.parentElement.dataset;
            if (originalObj.parent === "DatosUsuario") {
              let newObj = JSON.parse(originalObj.keyword);
              newObj["Valor"] = element.value;
              DatosUsuario.push(newObj);
            }
            if (originalObj.parent === "DatosComentario") {
              let newObj = JSON.parse(originalObj.keyword);

              if( element.value.trim() === ""){
                // alert("Ingrese un comentario");
                AlertRef?.current?.open("Ingrese un comentario", "error");
                setLoad(false);
                return;
              }

              newObj["Mensaje"] = element.value;
              DatosComentario.push(newObj);
            }
          }
        }
      }
    }

    let model: any = window?.document?.getElementById("batteryModel");
    let ObjectRequest: any = {
      DocEntry: 0,
      IdTarjeta: -1,
      IdMiniCod: "",
      Placa: "",
      CardCode: "",
      CardName: "",
      FlgAbierto: false,
      // OrigenBat: store?.user?.Country === "PE" ? "IMP" : store?.user?.Country,
      OrigenBat: "IMP",
      Modelo: model?.value,
      Direccion: {
        IdDireccion: "",
        Direccion: "",
        Latitud: "",
        Longitud: "",
      },
      IdEstado: 0,
      UserReg: store?.user?.user_login,
      Conversacion: DatosComentario,
      Datos: DatosUsuario,
      TIPO: "SS",
    };

    console.log(ObjectRequest)

    if (!!Object.keys(store.selected_client).length) {
      dispatch({ type: types.openAddressNewWarranty, payload: ObjectRequest });
    } else {
      alert("Debe seleccionar un cliente");
      setLoad(false);
    }
  };

  return (
    <div className="new-battery-container new-battery-container-solicitud">
      <Grid container spacing={1}>
        <Grid item xs={12} style={{ padding: "1rem", background: "#FFFFFF", borderRadius: "15px" }}>
          <ClientCard callback={() => console.log()} />
          <form action="" onSubmit={handleSubmit} className="new-warranty-from">
            <Model />
            <RenderForm />
            <CommentArea />
            {
              (store?.user?.Country === "PE" || store?.user?.Country === "NAC") ?
                (<div style={{ padding: "1rem 2rem 2rem 1rem", textAlign: "justify" }}>
                  {i18n.WARRANTY_INFO}
                </div>)
                : <></>
            }
            
            <div className="button-container">
              <Button
                fullWidth
                variant="contained"
                color="primary"
                type="submit"
                disabled={store?.fetch || store.openAddress || loading}
                style={{ borderRadius: "20px", marginBottom: "4rem" }}

              >
                <strong>{i18n.REGISTER}</strong>
              </Button>
            </div>
          </form>
        </Grid>
      </Grid>

      <Alert ref={AlertRef} />

      <AddressModal callback={props.callback} type="WARRANTY" />
    </div>
  );
};
