import * as React from "react";
import TextFieldDefault, { TextFieldProps } from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import { StoreContext } from "../store/StoreProvider";
import CheckIcon from "@mui/icons-material/Check";
import AppConfig from "../constants/AppConfig";
import Tooltip from "@mui/material/Tooltip";

type InputCustomProps = {};

export type InputProps = InputCustomProps & TextFieldProps;

export const TextField: any = React.forwardRef(
  (props: InputProps, defRef: any) => {
    return <TextFieldDefault {...props} />;
  }
);

const {
  colors: { success },
} = AppConfig;

var selectedBrand: string;

interface MultiInputProps {
  data: any;
  className?: any;
  placeholder?: any;
  callback?: any;
  onChange?: any;
  parent?: string;
  keyword?: string;
  requred?: boolean;
  disabled?: boolean;
  id?: string;
  update?: boolean;
  InputProps?: any;
  setWarrantyData?: any;
  autoComplete?: String
}

// const IntensivoTaxi = "Intensivo/Taxi",
//   Particular = "Particular",
//   Publico = "Público",
//   Embarcacion = "Embarcación",
//   Pesado = "Pesado",
//   Intensivo = "Intensivo";
const IntensivoTaxi = "INTENSIVO/TAXI",
  Particular = "PARTICULAR",
  Publico = "PUBLICO",
  Embarcacion = "EMBARCACION",
  Pesado = "PESADO",
  Intensivo = "INTENSIVO";

const inputTypes: any = {
  INT: "number",
  DEC: "number",
  EMAIL: "email",
  TXT: "text",
  TXTA: "text",
};

const selectTypes: any = {
  "Tipo de combustión (*)": "combustion",
  "Combustión (*)": "combustion",
  "LISTA-TC": "combustion",
  "Marca de vehiculo": "marca",
  "LISTA-MA": "marca",
  Marca: "marca",
  "LISTA-MO": "modelo",
  "LISTA-TU": "uso",
  "Tipo de uso": "uso",
  "Tipo de uso (*)": "uso",
};

const selectValues: any = {
  "Tipo de combustión (*)": {
    value: "CODCOMB",
    label: "DESCOMB",
  },
  "Combustión (*)": {
    value: "CODCOMB",
    label: "DESCOMB",
  },
  "LISTA-TC": {
    value: "CODCOMB",
    label: "DESCOMB",
  },
  "Marca de vehiculo": {
    value: "CODMARCA",
    label: "DESMARCA",
  },
  "LISTA-MA": {
    value: "CODMARCA",
    label: "DESMARCA",
  },
  Marca: {
    value: "CODMARCA",
    label: "DESMARCA",
  },
  Modelo: {
    value: "CODMODELO",
    label: "DESMODELO",
  },
  "LISTA-MO": {
    value: "CODMODELO",
    label: "DESMODELO",
  },
  "Tipo de uso": {
    value: "Codigo",
    label: "Descripcion",
  },
  "Tipo de uso (*)": {
    value: "Codigo",
    label: "Descripcion",
  },
  "LISTA-TU": {
    value: "Codigo",
    label: "Descripcion",
  },
};
var prevPlate = "";
export const MultiInput = (props: MultiInputProps) => {
  const [localValue, setValue] = React.useState(props?.data?.Valor);
  const [valid, setValid] = React.useState(true);
  const [editable, setEdit] = React.useState(true);
  const [selectData, setSelectData] = React.useState([]);
  const [store, dispatch] = React.useContext(StoreContext);
  const [plateIdCallback, setPlateIdCallback] = React.useState(false);
  const { selectData: storeSelectData } = store;

  const validation = (val: string) => {
    let valid = false;
    switch (props?.data?.Tipo) {
      case "INT":
        valid = new RegExp(/^[0-9]*$/).test(val);
        break;

      case "EMAIL":
        // setValid(
        //   new RegExp(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/).test(
        //     localValue
        //   )
        // );
        setValid(
          new RegExp(/^[^\s@]+@[^\s@]+\.[^\s@]+$/).test(
            localValue
          )
        );
        valid = true;
        break;

      case "TXT":
        valid = true;
        break;
      case "DEC":
        valid = true;
        break;
      case "TXTA":
        valid = true;
        break;

      default:
        break;
    }

    return valid;
  };

  const change = (value: string) => {
    if (validation(value)) {
      setValue(value);
      if (!!props.update && !editable && localValue !== props?.data?.Valor) {
        setEdit(true);
      }
    }
  };

  const setDefaultPlateWithUse = () => {
    const defaultPlate = "EP00";
    let inp: any = document.getElementById("LISTA-TU");
    let inpVal = inp?.value;
    if (inpVal === "Embarcación") {
      if (localValue !== defaultPlate && !plateIdCallback) {
        setPlateIdCallback(true);
        setValue(defaultPlate);
      }
    }
  }

  const plateInputHandler = () => {
    setDefaultPlateWithUse()

    if (
      localValue.length === 3 &&
      localValue.indexOf("-") === -1 &&
      store?.user?.Country === "PE"
    ) {
      setValue(`${localValue}-`);
    }
  };

  const docInputHandler = () => {
    if (localValue.length === 9 && localValue.indexOf("-") === -1) {
      const valueInput = localValue.substr(0, 8);
      const valueInputFin = localValue.substr(8, 10);
      setValue(`${valueInput}-${valueInputFin}`);
    }
  };

  const idCallbackHandler = () => {
    switch (props.id) {
      case "plateInput":
        plateInputHandler();
        break;
      case "docInput":
        docInputHandler();
        break;

      default:
        break;
    }
  };

  React.useEffect(() => {
    if (props?.id === "plateInput" || props?.id === "docInput") {
      idCallbackHandler();
    }
  });

  if (props?.data?.Tipo === "IMG") {
    let img: string = !props?.data?.Valor
      ? ""
      : props?.data?.Valor.indexOf("data:") !== -1
        ? props?.data?.Valor
        : `data:image/png;base64,${props?.data?.Valor}`;
    return (
      <div
        id="img-show-container"
        className={
          !!img ? "show img-show-container img-input-container" : "hidden"
        }
      >
        <img
          src={img}
          alt=""
          className={!!img ? "show img-responsive img-input" : "hidden"}
          id="showImg"
        />
        <TextField
          hidden={true}
          id="imgCustomField"
          className="hidden"
          label={props.placeholder}
          data-parent={props.parent}
          data-keyword={props.keyword}
          variant="outlined"
          type={"hidden"}
          disabled={props?.disabled}
          value={img}
          autoComplete={props?.autoComplete}
        />
      </div>
    );
  }

  const setCountry = (data: any, type: string) => {
    let newData = data;
    switch (store?.user?.Country) {
      case "EC":
        if (type === "combustion") {
          if (data.length > 2) {

            const {
              [data.length - 1]: remove_1,
              [data.length - 2]: remove_2,
              ...rest
            } = data;

            return Object.values(rest);
          }

          return data;
        } else if (type === "uso") {
          newData = [];
          newData.push(
            { Codigo: "001", Descripcion: "Particular" },
            { Codigo: "002", Descripcion: "Particular / Público" },
            { Codigo: "003", Descripcion: "Público" }
          );
        }
        break;
      case "VE":
        if (type === "combustion") {
          // let res = [
          //   { CODCOMB: "0", DESCOMB: "" },
          //   { CODCOMB: "01", DESCOMB: "GASOLINA" },
          //   { CODCOMB: "02", DESCOMB: "DIESEL" },
          // ];
          // return res;
          return data;
        }
        break;
      case "CL":
        if (type === "combustion") {
          //   let res = [
          //     { CODCOMB: "0", DESCOMB: "" },
          //     { CODCOMB: "02", DESCOMB: "BENCINA" },
          //     { CODCOMB: "03", DESCOMB: "GAS" },
          //     { CODCOMB: "04", DESCOMB: "PETROLEO" },
          //   ];
          //   return res;
          return data;
        }

        break;
      default:
        break;
    }
    return newData;
  };

  if (props?.data?.Tipo.includes("-")) {
    // console.log(selectData?.length, selectData)
    if (!selectData?.length) {
      switch (selectTypes[props?.data?.Tipo]) {
        case "combustion":
          let combustion: any = setCountry(
            storeSelectData.CombustionV,
            "combustion"
          );
          setSelectData(combustion);
          break;
        case "marca":
          // TODO: Fisnish the brand and model
          setSelectData(storeSelectData.MarModeloV);
          break;
        case "modelo":
          setSelectData(storeSelectData.ModeloV);
          break;
        case "uso":
          // let customData = [
          //   { Codigo: "0", Descripcion: "" },
          //   { Codigo: "005", Descripcion: IntensivoTaxi },
          //   { Codigo: "006", Descripcion: Embarcacion },
          //   { Codigo: "004", Descripcion: Particular },
          //   { Codigo: "003", Descripcion: Pesado },
          //   { Codigo: "001", Descripcion: Intensivo },
          // ];
          // let uso: any = setCountry(customData, "uso");
          setSelectData(storeSelectData.TipoUsoV);
          break;

        default:
          break;
      }
    }

    const search = (f: any) => {
      if (f.Name) {
        return f.Name?.toLocaleUpperCase() === localStorage["selectedBrand"]?.toLocaleUpperCase();
      }
      return f.DESMARCA?.toLocaleUpperCase() === localStorage["selectedBrand"]?.toLocaleUpperCase();
    }

    const optionsData = selectData?.map((item, index) => {
      let value = item[selectValues[props?.data?.Tipo].value] ?? item["Cod"];
      let label = item[selectValues[props?.data?.Tipo].label] ?? item["Name"];
      // console.log({value, label})
      return {
        value,
        label,
      };
    })

    return (
      <div className="input-text-container select-input-update">
        <Autocomplete
          freeSolo
          disablePortal
          onOpen={() => {
            if (selectTypes[props?.data?.Tipo] === "modelo") {
              let data = storeSelectData.ModeloV.filter((f: any) => search(f));
              setSelectData(data);
            }
          }}
          isOptionEqualToValue={(option, value) => option.label === value}
          data-parent={props.parent}
          data-keyword={props.keyword}
          id={props?.data?.Tipo}
          className=""
          options={optionsData || []}
          sx={{ mt: 1, mb: 1, width: "100%" }}
          value={localValue}
          disabled={props?.disabled}
          onChange={(event: any) => {
            // if (selectTypes[props?.data?.Tipo] === "uso") {
            //   setDefaultPlateWithUse()
            // }
            // setValue(event.target.textContent);
            // if (selectTypes[props?.data?.Tipo] === "marca") {
            //   localStorage.setItem("selectedBrand", event.target.textContent);
            // }
            // if (props?.setWarrantyData) {
            //   props?.setWarrantyData(event.target.textContent);
            // }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={props.placeholder}
              required={props?.requred || false}
            />
          )}
        />
        {!!editable &&
          !!localValue &&
          localValue !== props?.data?.Valor &&
          props?.update && (
            <IconButton
              aria-label="Guardar"
              onClick={() => props?.callback()}
              className="input-icon-text"
            >
              <CheckIcon color={"success"} />
            </IconButton>
          )}
      </div>
    );
  }

  if (
    !!editable &&
    !!localValue &&
    localValue !== props?.data?.Valor &&
    props?.update &&
    !!props?.onChange
  ) {
    props?.onChange();
  }

  const minMax = (num: number, min = false) => {
    let str = "",
      numMin = 3,
      numCont = "9",
      numMax = num;

    switch (store?.user?.Country) {
      case "PE":
      case "NAC":
        if (props?.data?.DescDet === "Placa de auto") {
          numMax = 7;
        }
        // else if (props?.data?.DescDet === "Celular de cliente") {
        //   numMax = 9;
        //   numMin = 1;
        // }
        break;
      case "EC":
        numCont = "8";
        if (props?.data?.DescDet === "Documento de identificación") {
          numMax = 10;
        } else if (props?.data?.DescDet === "Celular de cliente") {
          numMax = 10;
          numMin = 1;
        } else if (props?.data?.DescDet === "Placa de auto") {
          numMax = 7;
        }
        break;
      case "VE":
        numCont = "9";
        if (props?.data?.DescDet === "Documento de identificación") {
          numMax = 8;
          numMin = 1;
        } else if (props?.data?.DescDet === "Celular de cliente") {
          numMax = 7;
          numMin = 1;
        } else if (props?.data?.DescDet === "Placa de auto") {
          numMax = 7;

        }
        break;
      case "CL":
        numCont = "9";
        if (props?.data?.DescDet === "Documento de identificación") {
          numMax = 10;
        } else if (
          props?.data?.DescDet.toLocaleLowerCase() === "kilometraje de auto"
        ) {
          numMax = 3;
        } else if (props?.data?.DescDet === "Celular de cliente") {
          numMax = 9;
          numMin = 1;
        } else if (props?.data?.DescDet === "Placa de auto") {
          numMax = 7;
        }
        break;
      case "DO":
        if (props?.data?.DescDet === "Placa de auto") {
          numMax = 7;
        }
        break;
      case "TT":
        if (props?.data?.IdCab === 1 && props?.data?.IdDet === AppConfig.userItemsType.DOCUMENT) {
          numMax = 11;
          numMin = 1;
        }
        break;
      default:
        break;
    }

    for (let index = 0; str.length < numMax; index++) {
      str += numCont;
    }
    if (!!min) {
      str = str.slice(numMin);
    }

    return str;
    // if (!!min) {
    //   numMax = numMax - numMin;
    // }

    // return numMax;
  };

  const minMaxLength = (num: number, min = false) => {
    let str = "",
      numMin = 3,
      numCont = "9",
      numMax = num;

    switch (store?.user?.Country) {
      case "PE":
      case "NAC":
        if (props?.data?.DescDet === "Placa de auto") {
          numMax = 7;
        } else if (props?.data?.DescDet === "Documento de identificación") {
          numMax = 11;
          numMin = 3;
        } else if (
          props?.data?.DescDet.toLocaleLowerCase() === "kilometraje de auto"
        ) {
          numMax = 8;
          numMin = 4;
        }
        // else if (props?.data?.DescDet === "Celular de cliente") {
        //   numMax = 9;
        // }
        break;
      case "EC":
        if (props?.data?.DescDet === "Documento de identificación") {
          numMax = 13;
        } else if (
          props?.data?.DescDet.toLocaleLowerCase() === "kilometraje de auto"
        ) {
          numMax = 8;
          numMin = 4;
        } else if (props?.data?.DescDet === "Celular de cliente") {
          numMax = 10;
        } else if (props?.data?.DescDet === "Placa de auto") {
          numMax = 7;
        }
        break;
      case "VE":
        if (props?.data?.DescDet === "Documento de identificación") {
          numMax = 8;
          numMin = 0;
        } else if (
          props?.data?.DescDet.toLocaleLowerCase() === "kilometraje de auto"
        ) {
          numMax = 8;
          numMin = 4;
        } else if (props?.data?.DescDet === "Celular de cliente") {
          numMax = 7;
        } else if (props?.data?.DescDet === "Placa de auto") {
          numMax = 7;
        }
        break;
      case "CL":
        if (props?.data?.DescDet === "Documento de identificación") {
          numMax = 10;
        } else if (
          props?.data?.DescDet.toLocaleLowerCase() === "kilometraje de auto"
        ) {
          numMax = 8;
          numMin = 4;
        } else if (props?.data?.DescDet === "Celular de cliente") {
          numMax = 9;
        } else if (props?.data?.DescDet === "Placa de auto") {
          numMax = 7;
        }
        break;
      case "DO":
        if (props?.data?.DescDet === "Placa de auto") {
          numMax = 7;
        } else if (
          props?.data?.DescDet.toLocaleLowerCase() === "kilometraje de auto"
        ) {
          numMax = 8;
          numMin = 4;
        }
        break;
      case "TT":
        if (props?.data?.IdCab === 1 && props?.data?.IdDet === AppConfig.userItemsType.DOCUMENT) {
          numMax = 11;
          numMin = 11;
        }
        break;
      default:
        break;
    }

    if (!!min) {
      numMax = numMax - numMin;
    }

    return numMax;
  };

  return (
    <div className="input-text-container">
      <TextField
        fullWidth
        id={props?.id || ""}
        required={props.requred}
        className={`${props?.className}  ${inputTypes[props?.data?.Tipo] === "text" ? "uppercase" : ""
          } ${!!editable &&
            !!localValue &&
            localValue !== props?.data?.Valor &&
            props?.update
            ? "input-success"
            : ""
          }`}
        label={props.placeholder}
        data-parent={props.parent}
        data-keyword={props.keyword}
        variant="outlined"
        value={localValue}
        autoComplete={props?.autoComplete}
        onChange={(event: any) => {
          if (props?.data?.IdCab === 1 && props?.data?.IdDet === AppConfig.userItemsType.DOCUMENT && store?.user?.Country !== "CL") {
            let v = /^[0-9-]*$/;

            if (event.target.value.match(v)) {
              change(event.target.value);
            } else if (event.target.value.length < localValue.length) {
              change(event.target.value);
            }
          } else if (props?.data?.DescDet === "Documento de identificación" && store?.user?.Country === "CL") {
            let v = /^[0-9kK-]*$/;
            if (event.target.value.match(v)) {
              change(event.target.value);
            }
          } else if (inputTypes[props?.data?.Tipo] === "number") {
            /*let v = /^[1-9]\d*(\.\d+)?$/;
            if (event.target.value.match(v)) {
            }*/
            change(event.target.value);
          } else if (props?.data?.IdCab === 2 && props?.data?.IdDet === AppConfig.vehicleItemsType.KILOMETRAJE) {
            let v = /^[0-9]*$/;
            if (event.target.value.match(v)) {
              change(event.target.value);
            }

            /* } else if (props?.data?.DescDet === "Placa de auto" && store?.user?.Country === "DO") {
              let v = /^([A-Za-z][0-9]*)$/;
  
              if (event.target.value.match(v) || event.target.value === "") {
                change(event.target.value);
              } */

          } else {
            change(event.target.value);
          }
        }}
        type={inputTypes[props?.data?.Tipo] || "text"}
        disabled={props?.disabled}
        InputProps={{
          inputProps: {
            min: minMax(props?.data?.Minimo, true),
            max: minMax(props?.data?.Maximo),
            step: `${props?.data?.Tipo === "DEC" ? "any" : "1"}`,
            minLength: minMaxLength(props?.data?.Minimo, true),
            maxLength: minMaxLength(props?.data?.Maximo),
          },
          ...props.InputProps,
        }}
        step={`${props?.data?.Tipo === "DEC" ? "" : "1"}`}
        lang="en"
        min={minMax(props?.data?.Minimo, true)}
        max={minMax(props?.data?.Maximo)}
      />

      {!!editable &&
        !!localValue &&
        localValue !== props?.data?.Valor &&
        props?.update && (
          <Tooltip title="Guardar">
            <IconButton
              aria-label="Guardar"
              onClick={() => props?.callback()}
              className="input-icon-text"
            >
              <CheckIcon color={"success"} />
            </IconButton>
          </Tooltip>
        )}
    </div>
  );
};

export { IntensivoTaxi, Particular, Embarcacion, Pesado, Intensivo, Publico };
