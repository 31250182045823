import React from "react";
import {
    setVersionAppRequest,
    getVersionAppRequest,
    getVersionAppCorpRequest
} from "../../apis/main";

export const getVersionApp = (payload, corpApp) => {
    return new Promise((resolve, reject) => {
        try {

            if( corpApp ){
                getVersionAppCorpRequest(payload).then((res) => {
                    if (res.status === 200) {
                        resolve(res.json());
                    } else {
                    }
                });
                return;
            }

            getVersionAppRequest(payload).then((res) => {
                if (res.status === 200) {
                    resolve(res.json());
                } else {
                }
            });
        } catch (error) {
            console.log(error);
            reject(error);
        }
    });
};

export const setVersionApp = (payload) => {
    return new Promise((resolve, reject) => {
        try {
            setVersionAppRequest(payload).then((res) => {
                if (res.status === 200) {
                    resolve(res.json());
                } else {
                }
            });
        } catch (error) {
            console.log(error);
            reject(error);
        }
    });
};

// corporativo

export const getVersionAppCorp = (payload) => {
    return new Promise((resolve, reject) => {
        try {
            getVersionAppCorpRequest(payload).then((res) => {
                if (res.status === 200) {
                    resolve(res.json());
                } else {
                }
            });
        } catch (error) {
            console.log(error);
            reject(error);
        }
    });
};