import React from "react";
import {
  getClientsRequest,
  getBusinessPartnersRequest,
  getCorpBusinessPartnersRequest,
  bmgSendMessageRequest,
  clientPointsGetExtRequest,
  clientPointsAddExtRequest,
  clientAddressExtRequest,
  merchListExtRequest,
  merchListHistoryExtRequest,
  clientOrderAddExtRequest
} from "../../apis/clients";

export const getClients = (payload) => {
  return new Promise((resolve, reject) => {
    try {
      getClientsRequest(payload).then((res) => {
        if (res.status === 200) {
          resolve(res.json());
        } else {
        }
      });
    } catch (error) {
      console.log(error);
      reject(error);
    }
  });
};
export const getBusinessPartners = (payload) => {
  return new Promise((resolve, reject) => {
    try {
      getBusinessPartnersRequest(payload).then((res) => {
        if (res.status === 200) {
          resolve(res.json());
        } else {
        }
      });
    } catch (error) {
      console.log(error);
      reject(error);
    }
  });
};

// corporativo
export const getCorpBusinessPartners = (payload) => {
  return new Promise((resolve, reject) => {
    try {
      getCorpBusinessPartnersRequest(payload).then((res) => {
        if (res.status === 200) {
          resolve(res.json());
        } else {
        }
      });
    } catch (error) {
      console.log(error);
      reject(error);
    }
  });
};

// bmg
export const bmgSendMessage = (payload) => {
  return new Promise((resolve, reject) => {
    try {
      bmgSendMessageRequest(payload).then((res) => {
        if (res.status === 200) {
          resolve(res.json());
        } else {
        }
      });
    } catch (error) {
      console.log(error);
      reject(error);
    }
  });
};


// enerjet
export const clientPointsGetExt = (payload) => {
  return new Promise((resolve, reject) => {
    try {
      clientPointsGetExtRequest(payload).then((res) => {
        if (res.status === 200) {
          resolve(res.json());
        } else {
        }
      });
    } catch (error) {
      console.log(error);
      reject(error);
    }
  });
};

export const clientPointsAddExt = (payload) => {
  return new Promise((resolve, reject) => {
    try {
      clientPointsAddExtRequest(payload).then((res) => {
        if (res.status === 200) {
          resolve(res.json());
        } else {
        }
      });
    } catch (error) {
      console.log(error);
      reject(error);
    }
  });
};

export const clientAddressExt = (payload) => {
  return new Promise((resolve, reject) => {
    try {
      clientAddressExtRequest(payload).then((res) => {
        if (res.status === 200) {
          resolve(res.json());
        } else {
        }
      });
    } catch (error) {
      console.log(error);
      reject(error);
    }
  });
};

export const merchListExt = (payload) => {
  return new Promise((resolve, reject) => {
    try {
      merchListExtRequest(payload).then((res) => {
        if (res.status === 200) {
          resolve(res.json());
        } else {
        }
      });
    } catch (error) {
      console.log(error);
      reject(error);
    }
  });
};

export const merchListHistoryExt = (payload) => {
  return new Promise((resolve, reject) => {
    try {
      merchListHistoryExtRequest(payload).then((res) => {
        if (res.status === 200) {
          resolve(res.json());
        } else {
        }
      });
    } catch (error) {
      console.log(error);
      reject(error);
    }
  });
};

export const clientOrderAddExt = (payload) => {
  return new Promise((resolve, reject) => {
    try {
      clientOrderAddExtRequest(payload).then((res) => {
        if (res.status === 200) {
          resolve(res.json());
        } else {
        }
      });
    } catch (error) {
      console.log(error);
      reject(error);
    }
  });
};
