import * as React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import moment from "moment";
import IconButton from "@mui/material/IconButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import AppConfig from "../../constants/AppConfig";
import { GetWarrantyRequest, CorpWarrantyRequestById } from "../../store/sagas/Warranty";
import { StoreContext } from "../../store/StoreProvider";
import { types } from "../../store/Warranty";
import Loading from "../Loading";
import { Alert } from "../Alert";
import { getI18N } from '../../i18n'

const {
  functions: { setColorWarrantyState },
  colors: { blueblack }
} = AppConfig;

interface WarrantyListItemProps { }

export const WarrantyListItem = (props: any) => {
  const [store, dispatch] = React.useContext(StoreContext);
  const [loading, setLoad] = React.useState(false);
  const AlertRef = React.useRef<any>();

  const { Country } = store?.user;
  const currentLocale: string = Country || "PE";
  const i18n = getI18N({ currentLocale })

  const setDetail = () => {

    let idMiniCod = !!props?.IdMiniCod ? props?.IdMiniCod : `D${props?.DocEntry}`
    setLoad(true);
    let obj = {
      idTarjeta: props?.IdTarjeta,
      idMiniCod: idMiniCod,
      cardCode: store?.selected_client.Id,
    };

    if (store?.userAppCorp) {
      const objCorp = {
        IdSolicitudGarantia: props?.IdSolicitud
      }
      CorpWarrantyRequestById(objCorp).then((response) => {
        if (response?.statusCode !== 200) {
          setLoad(false);
          AlertRef?.current?.open(response.message ?? "No se encontraron resultados", 'error')
          // alert(response?.message ?? "No se encontraron registros");
        }

        if (response?.statusCode === 200) {
          dispatch({ type: types.setWarrantyDetail, payload: response?.value });
          setLoad(false);
          props?.callback();

        }
      })
      return;
    }

    GetWarrantyRequest(obj).then((response) => {
      if (!!response.length) {
        dispatch({ type: types.setWarrantyDetail, payload: response[0] });
        setLoad(false);
        props?.callback();
      } else {
        setLoad(false);
        alert("No se encontrarion registros");
      }
    });
  };

  return (
    <>
      <TableRow>
        <TableCell colSpan={4} align="left" sx={{ border: 0, padding: 0 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <div className="col">
                  <h3 className="w-title" style={{ margin: 5, marginLeft: 20 }}>
                    {store?.userAppCorp ? props?.CardName : props?.NombreCliente} - {store?.userAppCorp ? props?.CardCode : props?.DocumentoIdentidadCliente}

                    {store?.userAppCorp && (
                      <IconButton onClick={() => console.log()} style={{marginLeft: "1rem"}}>
                        <RadioButtonCheckedIcon
                          sx={{
                            color: setColorWarrantyState(Number(props?.IdEstado)),//blueblack
                            fontSize: "18px",
                          }}
                        />
                      </IconButton>
                    )}
                  </h3>
                </div>
              </Grid>
            </Grid>
          </Box>
        </TableCell>
      </TableRow>

      <TableRow
        key={props?.name}
        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
      >
        <TableCell
          scope="row"
          style={{ minWidth: 200, margin: 5, paddingLeft: 40 }}
        >
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <div className="col">
                  <span className="w-title">{store?.userAppCorp ? i18n.ACTIVATION_DATE : i18n.DATE}</span>
                  <span className="w-subtitle">
                    {moment(props?.FecReg).format("DD/MM/yyyy")}
                  </span>
                </div>
              </Grid>
              {props?.Placa && <Grid item xs={6}>
                <div className="col">
                  <span className="w-title">{i18n.PLATE}</span>
                  <span className="w-subtitle">{props?.Placa}</span>
                </div>
              </Grid>}

              <Grid item xs={6}>
                <div className="col">
                  <span className="w-title">{i18n.MODEL}</span>
                  <span className="w-subtitle">{props?.Modelo}</span>
                </div>
              </Grid>
              {(props?.IdMiniCod || props?.MiniCodigo) && <Grid item xs={6}>
                <div className="col">
                  <span className="w-title">{i18n.BATTERY_CODE}</span>
                  <span className="w-subtitle">{props?.IdMiniCod ?? props?.MiniCodigo}</span>
                </div>
              </Grid>}

            </Grid>
          </Box>
        </TableCell>
        <TableCell align="right">
          {store?.userAppCorp && (
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <div className="col">
                    <span className="w-title">#{i18n.SOL}</span>
                    <span className="w-subtitle">{props?.IdSolicitud}</span>
                  </div>
                </Grid>
              </Grid>
            </Box>
          )}

          {!store?.userAppCorp && (
            <span className="w-number">#{props?.DocEntry}</span>
          )}
        </TableCell>
        {!store?.userAppCorp && (
          <TableCell align="center" sx={{ pl: 1, pr: 1, textAlign: "center" }}>
            <IconButton onClick={() => console.log()}>
              <RadioButtonCheckedIcon
                sx={{
                  color: setColorWarrantyState(Number(props?.IdEstado)),//blueblack
                  fontSize: "18px",
                }}
              />
            </IconButton>
          </TableCell>
        )}

        <TableCell align="center" sx={{ pl: 1, pr: 1, textAlign: "center" }}>
          <Loading loading={loading}>
            <IconButton onClick={setDetail} color="inherit">
              <VisibilityIcon sx={{ fontSize: "18px" }} />
            </IconButton>
          </Loading>
        </TableCell>
      </TableRow>
      <Alert ref={AlertRef} />
    </>
  );
};
