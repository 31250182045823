import * as React from "react";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AppConfig from "../../constants/AppConfig";
import Box from "@mui/material/Box";
import Button from "@material-ui/core/Button";
import Grid from "@mui/material/Grid";
import moment from "moment";
import TextField from "@mui/material/TextField";
import { StoreContext } from "../../store/StoreProvider";
import { types } from "../../store/Warranty";
import { WarrantyCard } from "../../store/sagas/Warranty";
import Loading from "../Loading";
import { Camera } from "../App/Camera";
import { MultiInput } from "../MultiInput";
import { AddressModal } from "./AddressModal";
import { Bottom } from "../App/Bottom";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { getI18N } from '../../i18n'

const {
  colors: { primary },
} = AppConfig;

interface MaintenanceProps {
  open: boolean;
  handleClose: any;
  data?: any;
  index?: number;
  new?: boolean;
}

export const Maintenance = (props: MaintenanceProps) => {

  const [loading, setLoad] = React.useState(false);
  const [scroll, setScroll] = React.useState<DialogProps["scroll"]>("paper");
  const [store, dispatch] = React.useContext(StoreContext);

  const dataPropsBattery = (store?.openMaintenance) ? store?.battery : (props?.data ?? store?.battery);
  const [codeBattery, setCodeBattery] = React.useState("");

  const { Country } = store?.user;
  const currentLocale: string = Country || "PE";
  const i18n = getI18N({ currentLocale })

  const handleSubmit = (e: any) => {
    e.preventDefault();
    setLoad(true);
    let obj = [];

    for (const key in e.target.elements) {
      if (Object.prototype.hasOwnProperty.call(e.target.elements, key)) {
        const element = e.target.elements[key];
        if (
          !isNaN(Number(key)) &&
          (element.tagName === "INPUT" ||
            (element.tagName !== "FIELDSET" && element.tagName !== "BUTTON"))
        ) {

          if (element.tagName === "INPUT" && Number(key) !== 0 && element?.parentElement?.parentElement?.dataset?.keyword) {
            let org = JSON.parse(
              element.parentElement.parentElement.dataset.keyword
            );
            org.Valor = element.value;

            if (store?.userAppCorp) {
              org.MiniCodigos = codeBattery;
            }

            obj.push(org);
          }
        }
      }
    }

    let res = {
      Direccion: (!store?.userAppCorp) ? store.battery?.DatosMantenimiento[0]?.Direccion : null,
      Estado: "100",
      FecReg: moment().format(),
      FecUpd: moment().format(),
      Fecha: moment().format(),
      Id: (!store?.userAppCorp) ? Number(store.battery?.DatosMantenimiento[0]?.Id) + 1 : null,
      IdDireccion: (!store?.userAppCorp) ? store.battery?.DatosMantenimiento[0]?.IdDireccion : null,
      Latitud: "",
      Longitud: "",
      UsrReg: "",
      UsrUpd: "",
      Datos: obj,
    };

    // prosp?.data

    let battery = {
      ...dataPropsBattery,
      DatosMantenimiento: [res],
    };

    if (!!Object.keys(store.selected_client).length) {
      dispatch({ type: types.openAddressMaintenance, payload: battery });
    } else {
      alert("Debe seleccionar un cliente");
      setLoad(false);
    }

    return {};
  };

  const callback = () => {
    props?.handleClose()
  }

  const descriptionElementRef = React.useRef<HTMLElement>(null);
  React.useEffect(() => {
    if (props?.open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }

    if (store?.userAppCorp) {
      const codeBatteryDisplay = (props?.new === true) ?
        (store?.openMaintenance ? (dataPropsBattery?.DatosMantenimiento && dataPropsBattery.DatosMantenimiento[0].Datos[0]['MiniCodigos']) : (dataPropsBattery?.DatosBaterias && (dataPropsBattery?.DatosBaterias[0]['Minicodigo'])))
        : ((!props?.new) ? (dataPropsBattery?.DatosMantenimiento && dataPropsBattery?.DatosMantenimiento[0]['MiniCodigos']) : "");

      setCodeBattery(codeBatteryDisplay);
    }

  }, [props?.open]);

  const ItemForm = (item: any) => {
    return (
      <Grid item xs={item.IdDet === AppConfig.maintenanceItemsType.OBSERVACIONES || item.IdDet === AppConfig.maintenanceItemsType.IMAGEN ? 12 : 4}>
        <MultiInput
          data={item}
          requred={item?.FlgObligatorio}
          placeholder={item?.PlaceHolder ?? item?.Placeholder}
          parent="DatosUsuario"
          keyword={JSON.stringify(item)}
          className="input-maintenance"
          disabled={item.disabled}
        />
      </Grid>
    );
  };

  const Form = () => {
    let Datos: any = []
    if (!!store?.openMaintenance) {
      Datos = store?.battery?.DatosMantenimiento[0]?.Datos;
    } else {

      if (store?.userAppCorp) {
        Datos = props?.data?.DatosMantenimiento;
      } else {
        Datos = props?.data?.DatosMantenimiento[props?.index || 0]?.Datos || [];
      }
    }

    if (!!props?.new && !store?.openMaintenance) {
      Datos = Datos.map((item: any) => ({ ...item, Valor: "" }));
    }

    let voltage: any = Datos.find(({ IdDet }: any) => IdDet === AppConfig.maintenanceItemsType.VOLTAJE)
    let cca: any = Datos.find(({ IdDet }: any) => IdDet === AppConfig.maintenanceItemsType.CCA)
    let wd_lights: any = Datos.find(({ IdDet }: any) => IdDet === AppConfig.maintenanceItemsType.VC_SIN_LUCES)
    let wt_lights: any = Datos.find(({ IdDet }: any) => IdDet === AppConfig.maintenanceItemsType.VC_CON_LUCES)
    let km: any = Datos.find(({ IdDet }: any) => IdDet === AppConfig.maintenanceItemsType.KILOMETRAJE)
    let obs: any = Datos.find(({ IdDet }: any) => IdDet === AppConfig.maintenanceItemsType.OBSERVACIONES)

    let csc: any = Datos.find(({ IdDet }: any) => IdDet === AppConfig.maintenanceItemsType.CORRIENTE_SIN_CARGA)
    let ccc: any = Datos.find(({ IdDet }: any) => IdDet === AppConfig.maintenanceItemsType.CORRIENTE_CON_CARGA)
    let cd1: any = Datos.find(({ IdDet }: any) => IdDet === AppConfig.maintenanceItemsType.CELDA_1)
    let cd2: any = Datos.find(({ IdDet }: any) => IdDet === AppConfig.maintenanceItemsType.CELDA_2)
    let cd3: any = Datos.find(({ IdDet }: any) => IdDet === AppConfig.maintenanceItemsType.CELDA_3)
    let cd4: any = Datos.find(({ IdDet }: any) => IdDet === AppConfig.maintenanceItemsType.CELDA_4)
    let cd5: any = Datos.find(({ IdDet }: any) => IdDet === AppConfig.maintenanceItemsType.CELDA_5)
    let cd6: any = Datos.find(({ IdDet }: any) => IdDet === AppConfig.maintenanceItemsType.CELDA_6)
    let img: any = Datos.find(({ IdDet }: any) => IdDet === AppConfig.maintenanceItemsType.IMAGEN)

    return (
      <>
        <Grid item xs={3}>
          <MultiInput
            data={voltage}
            requred={voltage?.FlgObligatorio}
            placeholder={voltage?.PlaceHolder ?? voltage?.Placeholder}
            parent="DatosUsuario"
            keyword={JSON.stringify(voltage)}
            className="input-maintenance"
            disabled={!props?.new}
          />
        </Grid>

        <Grid item xs={3}>
          <MultiInput
            data={cca}
            requred={cca?.FlgObligatorio}
            placeholder={cca?.PlaceHolder ?? cca?.Placeholder}
            parent="DatosUsuario"
            keyword={JSON.stringify(cca)}
            className="input-maintenance"
            disabled={!props?.new}
          />
        </Grid>

        <Grid item xs={3}>
          <MultiInput
            data={wd_lights}
            requred={wd_lights?.FlgObligatorio}
            placeholder={wd_lights?.PlaceHolder ?? wd_lights?.Placeholder}
            parent="DatosUsuario"
            keyword={JSON.stringify(wd_lights)}
            className="input-maintenance"
            disabled={!props?.new}
          />
        </Grid>
        <Grid item xs={3}>
          <MultiInput
            data={wt_lights}
            requred={wt_lights?.FlgObligatorio}
            placeholder={wt_lights?.PlaceHolder ?? wt_lights?.Placeholder}
            parent="DatosUsuario"
            keyword={JSON.stringify(wt_lights)}
            className="input-maintenance"
            disabled={!props?.new}
          />
        </Grid>

        <Grid item xs={12}>
          <MultiInput
            data={km}
            requred={km?.FlgObligatorio}
            placeholder={km?.PlaceHolder ?? km?.Placeholder}
            parent="DatosUsuario"
            keyword={JSON.stringify(km)}
            className="input-maintenance"
            disabled={!props?.new}
          />
        </Grid>

        {!store?.userAppCorp && (
          <Grid item xs={12}>
            <MultiInput
              data={img}
              requred={img?.FlgObligatorio}
              placeholder={img?.PlaceHolder ?? img?.Placeholder}
              parent="DatosUsuario"
              keyword={JSON.stringify(img)}
              className="input-maintenance"
              disabled={!props?.new}
            />
          </Grid>
        )}

        <Grid item xs={12}>
          <MultiInput
            data={obs}
            requred={obs?.FlgObligatorio}
            placeholder={obs?.PlaceHolder ?? obs?.Placeholder}
            parent="DatosUsuario"
            keyword={JSON.stringify(obs)}
            className="input-maintenance"
            disabled={!props?.new}
          />
        </Grid>

        <Grid item xs={12}>
          <details className="details">
            <summary>
              {i18n.ADDITIONAL_TECHNICAL_DATA}{" "}
              <div className="arrow-container d-flex">
                <ArrowDropDownIcon />
              </div>
            </summary>

            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                flex: 1,
                padding: "10px 0",
              }}
            >
              <Grid item xs={4} style={{ marginBottom: 8 }}>
                <MultiInput
                  data={csc}
                  requred={csc?.FlgObligatorio}
                  placeholder={csc?.PlaceHolder ?? csc?.Placeholder}
                  parent="DatosUsuario"
                  keyword={JSON.stringify(csc)}
                  className="input-maintenance"
                  disabled={!props?.new}
                />
              </Grid>
              <Grid item xs={4} style={{ marginBottom: 8 }}>
                <MultiInput
                  data={ccc}
                  requred={ccc?.FlgObligatorio}
                  placeholder={ccc?.PlaceHolder ?? ccc?.Placeholder}
                  parent="DatosUsuario"
                  keyword={JSON.stringify(ccc)}
                  className="input-maintenance"
                  disabled={!props?.new}
                />
              </Grid>
              <Grid item xs={4} style={{ marginBottom: 8 }}>
                <MultiInput
                  data={cd1}
                  requred={cd1?.FlgObligatorio}
                  placeholder={cd1?.PlaceHolder ?? cd1?.Placeholder}
                  parent="DatosUsuario"
                  keyword={JSON.stringify(cd1)}
                  className="input-maintenance"
                  disabled={!props?.new}
                />
              </Grid>
              <Grid item xs={4} style={{ marginBottom: 8 }}>
                <MultiInput
                  data={cd2}
                  requred={cd2?.FlgObligatorio}
                  placeholder={cd2?.PlaceHolder ?? cd2?.Placeholder}
                  parent="DatosUsuario"
                  keyword={JSON.stringify(cd2)}
                  className="input-maintenance"
                  disabled={!props?.new}
                />
              </Grid>
              <Grid item xs={4} style={{ marginBottom: 8 }}>
                <MultiInput
                  data={cd3}
                  requred={cd3?.FlgObligatorio}
                  placeholder={cd3?.PlaceHolder ?? cd3?.Placeholder}
                  parent="DatosUsuario"
                  keyword={JSON.stringify(cd3)}
                  className="input-maintenance"
                  disabled={!props?.new}
                />
              </Grid>
              <Grid item xs={4} style={{ marginBottom: 8 }}>
                <MultiInput
                  data={cd4}
                  requred={cd4?.FlgObligatorio}
                  placeholder={cd4?.PlaceHolder ?? cd4?.Placeholder}
                  parent="DatosUsuario"
                  keyword={JSON.stringify(cd4)}
                  className="input-maintenance"
                  disabled={!props?.new}
                />
              </Grid>
              <Grid item xs={4} style={{ marginBottom: 8 }}>
                <MultiInput
                  data={cd5}
                  requred={cd5?.FlgObligatorio}
                  placeholder={cd5?.PlaceHolder ?? cd5?.Placeholder}
                  parent="DatosUsuario"
                  keyword={JSON.stringify(cd5)}
                  className="input-maintenance"
                  disabled={!props?.new}
                />
              </Grid>
              <Grid item xs={4} style={{ marginBottom: 8 }}>
                <MultiInput
                  data={cd6}
                  requred={cd6?.FlgObligatorio}
                  placeholder={cd6?.PlaceHolder ?? cd6?.Placeholder}
                  parent="DatosUsuario"
                  keyword={JSON.stringify(cd6)}
                  className="input-maintenance"
                  disabled={!props?.new}
                />
              </Grid>
            </div>
          </details>
        </Grid>
      </>
    );

    if (!Datos.find((e: any) => e.Tipo === "IMG")) {
      Datos = [
        ...Datos,
        {
          DescCab: "",
          DescDet: "Imagen",
          Estado: "",
          FecReg: moment().format(),
          FecUpd: moment().format(),
          FlgObligatorio: false,
          Maximo: 0,
          Minimo: 0,
          PlaceHolder: "Imagen",
          Tipo: "IMG",
          UserReg: "",
          Valor: "",
        },
      ];
    }

    let form = Datos.map((item: any, key: number) => {
      let placeholder = item.DescDet;
      if (item.FlgObligatorio) placeholder = `${placeholder} (*)`;

      return <ItemForm key={key} {...item} placeholder={placeholder} disabled={!props?.new} />;
    });

    return form;
  };

  const dataBatteriesCorp = props?.data?.DatosBaterias ?? store?.battery?.DatosBaterias;

  return (
    <div>
      <Dialog
        open={props?.open}
        onClose={props.handleClose}
        scroll={scroll}
        fullScreen
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <AppBar
          sx={{ position: "relative" }}
          style={{ backgroundColor: primary }}
        >
          <Toolbar>
            <IconButton
              edge="start"
              onClick={props.handleClose}
              aria-label="close"
              style={{ color: "#000" }}
            >
              <ArrowBackIcon />
            </IconButton>
            <Typography
              sx={{ ml: 2, flex: 1 }}
              variant="h6"
              component="div"
              align="center"
              style={{ color: "#000" }}
            >
              {props?.new && i18n.REGISTER} {store?.userAppCorp ? i18n.REVISION : i18n.MAINTENANCE}
            </Typography>
            {!!props?.new && <Camera />}
          </Toolbar>
        </AppBar>
        <DialogContent dividers={scroll === "paper"}>
          <Box sx={{ flexGrow: 1, pb: 6 }}>
            <form action="" onSubmit={handleSubmit} className="form-maintenance" style={{ padding: "4vh 2vw" }}>
              <Grid container spacing={1}>
                <Grid item xs={12} style={{ padding: "1rem", background: "#FFFFFF", borderRadius: "15px" }}>
                  <Grid container spacing={1}>
                    {store?.userAppCorp && (
                      <Grid item xs={12}>
                        <FormControl fullWidth>
                          <Select
                            className="input-battery"
                            labelId="select-code-battery-label"
                            id="select-code-battery"
                            value={codeBattery}
                            label="CodigoBateria"
                            name="CodigoBateria"
                            onChange={(event) => {
                              event.preventDefault();
                              setCodeBattery(event.target.value);
                            }}
                            data-keyword={"CodigoBateria"}
                            data-parent={"CodigoBateria"}
                            required={true}
                            disabled={!props?.new}
                          >
                            {dataBatteriesCorp?.map((item: any, i: number) => {
                              return (
                                <MenuItem key={`MC${i}`} value={item.Minicodigo}>{item.Minicodigo}</MenuItem>
                              )
                            })}
                          </Select>
                        </FormControl>
                      </Grid>
                    )}

                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        className="input-maintenance uppercase"
                        label={i18n.BATTERY_MODEL}
                        variant="outlined"
                        value={dataPropsBattery?.Modelo}
                        disabled={true}
                      />
                    </Grid>
                    <Form />
                  </Grid>
                </Grid>

                {props?.new && (
                  <div
                    style={{ marginTop: 10, marginBottom: 10, width: "100%" }}
                  >
                    <Button
                      fullWidth
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={store?.fetch || store.openAddress || loading}
                      style={{ borderRadius: "20px" }}
                    >
                      <Loading loading={loading}>
                        <strong>{i18n.REGISTER}</strong>
                      </Loading>
                    </Button>
                  </div>
                )}
              </Grid>
            </form>
          </Box>
          <Bottom />
        </DialogContent>

        <AddressModal callback={callback} type="MAINTENANCE" />
      </Dialog>
    </div>
  );
};
