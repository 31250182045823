import * as React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Grid from "@mui/material/Grid";
import {
  clientAddress,
  CombustionV,
  getWarranty,
  MarModeloV,
  TipoUsoV,
} from "../../store/sagas/Warranty";
import { StoreContext } from "../../store/StoreProvider";
import { types } from "../../store/Warranty";
import { types as typesDialog } from "../../store/Dialog";
import Loading from "../Loading";
import { getI18N } from '../../i18n'

const ACTIVE_DIALOG = "new_code";
const emails = ["username@gmail.com", "user02@gmail.com"];

export interface SimpleDialogProps {
  open: boolean;
  batteryList: any;
  handleClose: any;
  type: string;
  callback: any;
}

function ListItemComponent(obj:any){

  const [loading, setLoad] = React.useState(false);
  const [store, dispatch] = React.useContext(StoreContext)

  const { Country } = store?.user;
  const currentLocale: string = Country || "PE";
  const i18n = getI18N({ currentLocale })

    const setBattery = () => {
      setLoad(true)
      let objReq = {
        idTarjeta: obj?.IdTarjetaGarantia,
        qrCode: obj?.CodBateria,
        placa: "",
        ruc: "",
        cardCode: obj?.Documento,
        origenBat: obj.type,
      };

      getWarranty(objReq).then((dataWarranty) => {
        new Promise(function (resolve, reject) {
          CombustionV({country: store?.user?.Country}).then((dataComb) => {
            MarModeloV().then((data) => {
              let brands: any = [];
              let models: any = [];
              let dataMarMod = data.filter((d: any) => {
                let res;
                if (brands.indexOf(d.DESMARCA) === -1) {
                  brands.push(d.DESMARCA);
                  res = {
                    CODMARCA: d.CODMARCA,
                    DESMARCA: d.DESMARCA,
                  };
                }
                return res;
              });

              let dataMod = data.filter((d: any) => {
                let res;
                if (models.indexOf(d.DESMODELO) === -1) {
                  models.push(d.DESMODELO);
                  res = {
                    CODMODELO: d.CODMODELO,
                    DESMODELO: d.DESMODELO,
                  };
                }
                return res;
              });

              TipoUsoV({country: store?.user?.Country}).then((dataTipo) => {
                clientAddress({
                  key: store?.selected_client?.Id,
                }).then((address) => {
                  let obj = {
                    clientAddress: address,
                    selectData: {
                      CombustionV: dataComb,
                      MarModeloV: dataMarMod,
                      ModeloV: dataMod,
                      TipoUsoV: dataTipo,
                    },
                    dataWarranty: dataWarranty[0],
                    searchCode: dataWarranty[0].IdMiniCod,
                  };
                  resolve(dispatch({ type: types.setBattery, payload: obj }));
                });
              });
            });
          });
        }).then(() => {
          setTimeout(() => {
            setLoad(false);
            dispatch({
              type: typesDialog.open,
              payload: ACTIVE_DIALOG,
            });
          }, 500);
        });
      });
    };


    return (
      <ListItem
        disabled={loading}
        button
        onClick={setBattery}
        className="battery-list-item"
        secondaryAction={
          <IconButton edge="end" aria-label="delete">
            {
              !!loading ? (<Loading loading={loading} />) : (<ArrowForwardIosIcon />)
            }
          </IconButton>
        }
      >
        <Grid container spacing={2}>
          <Grid item xs={12} className="">
            <strong>COD:</strong> {obj?.CodBateria}
          </Grid>
          <Grid item xs={6} className="text-muted pt-0 mt-0">
            Mod: {obj?.Modelo}
          </Grid>
          <Grid item xs={6} className="text-muted pt-0 mt-0">
            {i18n.PLATE}: {obj?.Placa}
          </Grid>
        </Grid>
      </ListItem>
    );
}


export function BatteryListModal(props:SimpleDialogProps) {

  const [store, dispatch] = React.useContext(StoreContext)

  const { Country } = store?.user;
  const currentLocale: string = Country || "PE";
  const i18n = getI18N({ currentLocale })

  const handleListItemClick= (obj:any) => {
    props?.callback(obj);
  }

  return (
    <div>
      <Dialog onClose={props?.handleClose} open={props?.open} className="battery-list-modal">
        <DialogTitle>{i18n.BATTERY_RESULTS}</DialogTitle>
        <List sx={{ pt: 0 }} className="battery-list-container">
          {props.batteryList.map((obj: any, i: number) => (
            <ListItemComponent {...obj} key={i} callback={handleListItemClick} type={props.type} />
          ))}
        </List>
      </Dialog>
    </div>
  );
}


