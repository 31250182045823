import * as React from "react";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
// import HomeIcon from "@mui/icons-material/Home";
import StarsIcon from "@mui/icons-material/Stars";
import Paper from "@mui/material/Paper";
import { StoreContext } from "../../store/StoreProvider";
import { types } from "../../store/Dialog";
// import { types as typesWarranty } from "../../store/Warranty";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import TaskIcon from '@mui/icons-material/Task';
import { getI18N } from '../../i18n'

interface BottomProps { }

export const Bottom = (props: BottomProps) => {
  // const [value, setValue] = React.useState(0);
  const [store, dispatch] = React.useContext(StoreContext);

  const { Country } = store?.user;
  const currentLocale: string = Country || "PE";
  const i18n = getI18N({ currentLocale })

  return (
    <Paper
      sx={{ position: "fixed", bottom: 0, left: 0, right: 0, zIndex: 2 }}
      elevation={3}
    >
      <BottomNavigation
        showLabels
        value={store.active_pane}
        onChange={(event, newValue) => {
          dispatch({ type: types.change, payload: newValue, updateVersion: store?.updateVersionApp });
        }}
      >
        <BottomNavigationAction label={i18n.RECORD} icon={<TaskIcon />} />
        <BottomNavigationAction label={i18n.WARRANTIES} icon={<StarsIcon />} />
        <BottomNavigationAction
          label={i18n.MY_ACTIVATIONS}
          icon={
            <CheckCircleOutlineIcon />
          }
        />
      </BottomNavigation>
    </Paper>
  );
};
