import React from 'react';
import { icon_boat } from '../../assets/Media';

export function IconBoat() {
  return (
    <>
      <img src={icon_boat} alt="Boat" className="icon icon-custom-tab"/>
    </>
  );
}

