import * as React from "react";
import Dialog, { DialogProps } from "@mui/material/Dialog";
// import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@material-ui/core/Button";
// import Radio from "@mui/material/Radio";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";

import Box from "@mui/material/Box";
import { TabOne } from "../Home/TabOne";
import { TabTwo } from "../Home/TabTwo";
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';

interface WarrantyRequestModalProps {
  open?: boolean;
  onClose: () => void;
  callbackOne?: any;
  callbackTwo?: any;
}

const validTypes = {
  code: "SC",
  noCode: "SS",
};

export const WarrantyRequestModal = (props: WarrantyRequestModalProps) => {
  // const [store, dispatch] = React.useContext(StoreContext);
  // const AlertRef = React.useRef<any>();
  // const [loading, setLoad] = React.useState(false);
  // const [hide, setHide] = React.useState(false);
  // const [maxWidth, setMaxWidth] = React.useState<DialogProps['maxWidth']>('sm');
  // maxWidth={maxWidth} 

  return (
    <div>
      <Dialog fullWidth={true} open={props.open ?? false} onClose={() => console.log()} className={"modal-custom-bmg"} >
        <DialogTitle style={{ textAlign: "center", background: "#FFFFFF" }}>
          <IconButton
            aria-label="close"
            onClick={props.onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent style={{ background: "#FFFFFF" }} className="container-modal-warranty-request">
          <Box sx={{ borderColor: "divider" }} style={{ marginTop: "1rem" }}>
            <TabOne
              callback={props.callbackOne}
              type={validTypes.code}
            />
          </Box>

          <Box sx={{ pl: 1, pr: 1 }} style={{ textAlign: "center" }}>
            <TabTwo
              callback={props.callbackTwo}
              type={validTypes.noCode}
            />
          </Box>

        </DialogContent>
      </Dialog>
    </div>
  );
};
