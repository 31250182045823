import * as React from "react";
import { StoreContext } from "../../../store/StoreProvider";
// import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { IntensivoTaxi, MultiInput, Particular, Pesado } from "../../MultiInput";
// import Button from "@material-ui/core/Button";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import TextFieldDefault, { TextFieldProps } from "@mui/material/TextField";
import { InputAdornment } from "@material-ui/core";
// interface BoliviaProps { }
var ACTIVE_UPDATE = "";

type InputCustomProps = {};
type InputProps = InputCustomProps & TextFieldProps;
const TextField: any = React.forwardRef((props: InputProps, defRef: any) => {
  return <TextFieldDefault {...props} />;
});

const UserData = () => {
  const [store, dispatch] = React.useContext(StoreContext);
  const [showUpd, setUpd] = React.useState(false);

  const [docType, setDocType] = React.useState(
    store?.inputSelectCountry?.bo_tipDoc || "CI"
  );

  const [docTypeNac, setDocTypeNac] = React.useState(
    store?.inputSelectCountry?.bo_docNac || ""
  );

  const [docTypeNacComplement, setDocTypeNacComplement] = React.useState("");
  const [docTypeNacExpedition, setDocTypeNacExpedition] = React.useState("");

  // const [codePhone, setCodePhone] = React.useState(
  //   store?.inputSelectCountry?.bo_codePhone || "591"
  // );
  const [numberPhone, setNumberPhone] = React.useState(
    store?.inputSelectCountry?.bo_numPhone || ""
  );

  const { battery } = store;

  let num = 0;
  for (let index = 0; index < battery?.DatosUsuario.length; index++) {
    if (!!battery?.DatosUsuario[index].Valor) num++;
  }

  const updateRequest = () => {
    // ACTIVE_UPDATE = "USER";
    let btn: any = document?.getElementById("btn-submit");
    btn?.click();
  };

  let update = Number(battery?.Id) !== -1 ? true : false;
  if (!!battery?.TienePreTarjeta) {
    update = true;
  }

  let doc: any = battery?.DatosUsuario.find(
    ({ DescDet }: any) =>
      DescDet.toLocaleLowerCase() ===
      "Documento de identificación".toLocaleLowerCase()
  );

  let numDocumentValue = doc.Valor.split(" ");

  if (numDocumentValue.length > 0) {

    doc = {
      ...doc,
      // Valor: numDocumentValue[1],
    };

    if (numDocumentValue.length === 1) {
      if (docTypeNac !== numDocumentValue[0]) {
        doc.Valor = numDocumentValue[0];
      }
    }

    if (numDocumentValue.length === 2) {
      if (docType !== numDocumentValue[0]) {
        setDocType(numDocumentValue[0]);
      }

      if (docTypeNac !== numDocumentValue[1]) {
        doc.Valor = numDocumentValue[1];
        setDocTypeNac(numDocumentValue[1]);
      }
    }

    if (numDocumentValue.length === 3 && docType === "CI") {
      if (docTypeNac !== numDocumentValue[0]) {
        doc.Valor = numDocumentValue[0];
      }
    }

    if (numDocumentValue.length === 4) {

      if (docType !== numDocumentValue[0]) {
        setDocType(numDocumentValue[0]);
      }

      if (docTypeNac !== numDocumentValue[1]) {
        doc.Valor = numDocumentValue[1];
        setDocTypeNac(numDocumentValue[1]);
      }
    }
  }

  doc = {
    ...doc,
    Maximo: 8,
    Minimo: 8,
  };

  let phone: any = battery?.DatosUsuario.find(
    ({ DescDet }: any) =>
      DescDet.toLocaleLowerCase() === "Celular de cliente".toLocaleLowerCase()
  );

  let numPhoneValue = phone.Valor.split(" ");

  if (numPhoneValue.length > 1) {

    if (numberPhone !== numPhoneValue[1]) {
      setNumberPhone(numPhoneValue[1]);
    }

    phone = {
      ...phone,
      Valor: numPhoneValue[1],
    };
  }

  React.useEffect(() => {
    if (numDocumentValue.length === 1) {
      if (docTypeNac !== numDocumentValue[0]) {
        setDocTypeNac(numDocumentValue[0]);
      }
    }

    if (numDocumentValue.length === 3 && docType === "CI") {

      if (docTypeNac !== numDocumentValue[0]) {
        setDocTypeNac(numDocumentValue[0]);
      }

      if (numDocumentValue[1]?.trim()) {
        setDocTypeNacComplement(numDocumentValue[1].trim());
      }

      if (numDocumentValue[2]?.trim()) {
        setDocTypeNacExpedition(numDocumentValue[2].trim());
      }
    }

    if (numDocumentValue.length === 4 && docType === "CI") {
      if (numDocumentValue[2]?.trim()) {
        setDocTypeNacComplement(numDocumentValue[2].trim());
      }

      if (numDocumentValue[3]?.trim()) {
        setDocTypeNacExpedition(numDocumentValue[3].trim());
      }
    }
    
    if( numPhoneValue.length === 1 ){
      setNumberPhone(numPhoneValue[0]);
    }

  }, [])

  let email: any = battery?.DatosUsuario.find(
    ({ DescDet }: any) =>
      DescDet.toLocaleLowerCase() === "Correo de cliente".toLocaleLowerCase()
  );

  let name: any = battery?.DatosUsuario.find(
    ({ DescDet }: any) =>
      DescDet.toLocaleLowerCase() === "Nombre cliente".toLocaleLowerCase()
  );

  return (
    <details
      className={`details active ${num !== battery?.DatosUsuario.length ? "" : "success"
        }`}
    >
      <summary>
        <strong>Datos de cliente</strong>
        <div className="arrow-container d-flex">
          {/* <progress max={battery?.DatosUsuario.length} value={num}></progress> */}
          <span
            // className={
            //   num !== battery?.DatosUsuario.length ? "primary" : "success"
            // }
          >{`(${num}/${battery?.DatosUsuario.length})`}</span>
          <ArrowDropDownIcon />
        </div>
      </summary>
      <div className="details-body">
        <div className="row" style={{ display: "flex", gap: "0.5rem" }}>
          <div className="col-sm-2" style={{ flex: 0.5 }}>
            <FormControl fullWidth style={{ marginTop: 5 }}>
              <InputLabel id="demo-simple-select-label">
                Tipo de Doc.
              </InputLabel>
              <Select
                id="tipDoc"
                value={docType}
                label="Tipo de documento"
                onChange={(event) => {
                  setDocType(event.target.value);
                  setDocTypeNac("");
                  setDocTypeNacComplement("");
                  setDocTypeNacExpedition("");
                }}
                disabled={update}
                data-keyword={"Tipo de documento"}
                data-parent={"Tipo de documento"}
              >
                <MenuItem value={"CI"}>CI</MenuItem>
                <MenuItem value={"NIT"}>NIT</MenuItem>
              </Select>
            </FormControl>
          </div>

          <div className="col-sm-10" style={{ flex: 1 }}>
            {docType === "CI" ?
              <div className="row" style={{ display: "flex", gap: "0.5rem" }}>
                <div className="col-sm-6" style={{ flex: 1 }}>
                  <FormControl fullWidth style={{ marginTop: 5 }}>
                    <TextField
                      fullWidth
                      id="docNac"
                      disabled={update}
                      required={true ?? doc?.FlgObligatorio}
                      className={` ${"uppercase"} ${update ? "input-success" : ""}`}
                      label={"Cédula"}
                      data-parent={"DatosUsuario"}
                      data-keyword={JSON.stringify(doc)}
                      variant="outlined"
                      value={docTypeNac}
                      onChange={(event: any) => {
                        let v = /^[0-9]*$/;
                        if (event.target.value.match(v)) {
                          setDocTypeNac(event.target.value);
                        }

                      }}
                      type={"text"}
                      InputProps={{
                        inputProps: {
                          min: 7,
                          max: 7,
                          minLength: 7,
                          maxLength: 7,
                        },
                      }}
                      step={"any"}
                      min={7}
                      max={7}
                    />
                  </FormControl>
                </div>
                <div className="col-sm-3" style={{ flex: 0.5 }}>
                  <FormControl fullWidth style={{ marginTop: 5 }}>
                    <TextField
                      fullWidth
                      id="docNacComplement"
                      disabled={update}
                      required={true ?? doc?.FlgObligatorio}
                      className={` ${"uppercase"} ${update ? "input-success" : ""}`}
                      label={"Complemento"}
                      // data-parent={"DatosUsuario"}
                      // data-keyword={JSON.stringify(doc)}
                      variant="outlined"
                      value={docTypeNacComplement}
                      onChange={(event: any) => {
                        let v = /^[a-zA-Z0-9]*$/;

                        if (event.target.value.match(v)) {
                          setDocTypeNacComplement(event.target.value);
                        }

                      }}
                      type={"text"}
                      InputProps={{
                        inputProps: {
                          min: 1,
                          max: 3,
                          minLength: 1,
                          maxLength: 3,
                        },
                      }}
                      step={"any"}
                      min={1}
                      max={3}
                    />
                  </FormControl>
                </div>
                <div className="col-sm-3" style={{ flex: 0.5 }}>
                  <FormControl fullWidth style={{ marginTop: 5 }}>
                    <TextField
                      fullWidth
                      id="docNacExpedition"
                      disabled={update}
                      required={true ?? doc?.FlgObligatorio}
                      className={` ${"uppercase"} ${update ? "input-success" : ""}`}
                      label={"Expedición"}
                      // data-parent={"DatosUsuario"}
                      // data-keyword={JSON.stringify(doc)}
                      variant="outlined"
                      value={docTypeNacExpedition}
                      onChange={(event: any) => {
                        let v = /^[a-zA-Z]*$/;

                        if (event.target.value.match(v)) {
                          setDocTypeNacExpedition(event.target.value);
                        }
                      }}
                      type={"text"}
                      InputProps={{
                        inputProps: {
                          min: 2,
                          max: 2,
                          minLength: 2,
                          maxLength: 2,
                        },
                      }}
                      step={"any"}
                      min={2}
                      max={2}
                    />
                  </FormControl>
                </div>
              </div> : <FormControl fullWidth style={{ marginTop: 5 }}>
                <TextField
                  fullWidth
                  id="docNac"
                  disabled={update}
                  required={true ?? doc?.FlgObligatorio}
                  className={` ${"uppercase"} ${update ? "input-success" : ""}`}
                  label={"NIT"}
                  data-parent={"DatosUsuario"}
                  data-keyword={JSON.stringify(doc)}
                  variant="outlined"
                  value={docTypeNac}
                  onChange={(event: any) => {
                    let v = /^[0-9]*$/;

                    if (event.target.value.match(v)) {
                      setDocTypeNac(event.target.value);
                    }
                  }}
                  type={"text"}
                  InputProps={{
                    inputProps: {
                      min: 11,
                      max: 11,
                      minLength: 11,
                      maxLength: 11,
                    },
                  }}
                  step={"any"}
                  min={11}
                  max={11}
                />
              </FormControl>
            }
          </div>
        </div>

        <FormControl fullWidth style={{ marginTop: 5 }}>
          <TextField
            fullWidth
            id="numPhone"
            disabled={update}
            required={true ?? phone?.FlgObligatorio}
            className={` ${"uppercase"} ${update ? "input-success" : ""}`}
            label={"Celular"}
            data-parent={"DatosUsuario"}
            data-keyword={JSON.stringify(phone)}
            variant="outlined"
            value={numberPhone}
            autoComplete="off"
            onChange={(event: any) => {
              if (!showUpd) setUpd(true);
              let v = /^[0-9]*$/;

              if (event.target.value.match(v)) {
                setNumberPhone(event.target.value);
              }
            }}
            type={"text"}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">+591</InputAdornment>
              ),
              inputProps: {
                min: 8,
                max: 8,
                minLength: 8,
                maxLength: 8,
              },
            }}
            step={"any"}
            min={8}
            max={8}
          />
        </FormControl>

        <MultiInput
          data={email}
          disabled={update}
          requred={false ?? email?.FlgObligatorio}
          placeholder={email.PlaceHolder}
          className="input-battery"
          parent="DatosUsuario"
          keyword={JSON.stringify(email)}
          update={update}
          onChange={() => {
            if (!showUpd) setUpd(true);
          }}
          callback={updateRequest}
        />
        <MultiInput
          data={name}
          disabled={update}
          requred={name?.FlgObligatorio}
          placeholder={name.PlaceHolder}
          className="input-battery"
          parent="DatosUsuario"
          keyword={JSON.stringify(name)}
          update={update}
          onChange={() => {
            if (!showUpd) setUpd(true);
          }}

          callback={updateRequest}
        />
      </div>
    </details >
  );
};

export const Bolivia = (props: any) => {
  return (
    <>
      <UserData />
    </>
  );
};
