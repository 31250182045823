import * as React from 'react';
import { StoreContext } from '../../../store/StoreProvider';
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { MultiInput } from '../../MultiInput';
import Button from "@material-ui/core/Button";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import TextFieldDefault, { TextFieldProps } from "@mui/material/TextField";
import { InputAdornment } from '@material-ui/core';

interface ChileProps { }

var ACTIVE_UPDATE = "";

type InputCustomProps = {};
type InputProps = InputCustomProps & TextFieldProps;
const TextField: any = React.forwardRef((props: InputProps, defRef: any) => {
  return <TextFieldDefault {...props} />;
});

const UserData = () => {
  const [store, dispatch] = React.useContext(StoreContext);
  const [showUpd, setUpd] = React.useState(false);
  const [docType, setDocType] = React.useState("RUT");
  const [docTypeNac, setDocTypeNac] = React.useState(
    store?.inputSelectCountry?.ch_tipDoc || ""
  );
  const { battery } = store;

  let num = 0;
  for (let index = 0; index < battery?.DatosUsuario.length; index++) {
    if (!!battery?.DatosUsuario[index].Valor) num++;
  }

  const updateRequest = () => {
    ACTIVE_UPDATE = "USER";
    let btn: any = document?.getElementById("btn-submit");
    btn?.click();
  };

  let update = Number(battery?.Id) !== -1 ? true : false;
  if (!!battery?.TienePreTarjeta) {
    update = true;
  }
  let doc: any = battery?.DatosUsuario.find(
    ({ DescDet }: any) =>
      DescDet.toLocaleLowerCase() ===
      "Documento de identificación".toLocaleLowerCase()
  );

  // let numDocValue = doc.Valor.split("-");

  // let numDocumentValue = doc.Valor.split("-");
  // numDocumentValue = numDocumentValue.length === 1 ? doc.Valor.split(" ") : numDocumentValue;
  let numDocumentValue = doc.Valor.split(" ");
    
  if (numDocumentValue.length > 1) {
    // if (numDocumentValue.length === 3) {
    
      doc = {
        ...doc,
        Valor: numDocumentValue[1],
      };
    // }
    
  }

  doc = {
    ...doc,
    Tipo: "TXT",
  };

  let phone: any = battery?.DatosUsuario.find(
    ({ DescDet }: any) =>
      DescDet.toLocaleLowerCase() === "Celular de cliente".toLocaleLowerCase()
  );

  // let numPhoneValue = phone.Valor.split(" ");
  let numphone = phone?.Valor;
  let numPhoneValue = numphone.substr(2, 9);
  // phone = {
  //   ...phone,
  //   Valor: numPhoneValue[1] || numPhoneValue[0],
  // };

  if (!!phone.Valor) {
    if (numPhoneValue.length > 1) {

      phone = {
        ...phone,
        // Valor: numPhoneValue[1] || numPhoneValue[0],
        Valor: numPhoneValue,
      };
    }
  }

  let email: any = battery?.DatosUsuario.find(
    ({ DescDet }: any) =>
      DescDet.toLocaleLowerCase() === "Correo de cliente".toLocaleLowerCase()
  );

  let name: any = battery?.DatosUsuario.find(
    ({ DescDet }: any) =>
      DescDet.toLocaleLowerCase() === "Nombre cliente".toLocaleLowerCase()
  );

  // num !== battery?.DatosUsuario.length

  return (
    <details
      className={`details active ${num !== battery?.DatosUsuario.length ? "" : "success"
        }`}
    >
      <summary>
        <strong>Datos de cliente</strong>
        <div className="arrow-container d-flex">
          {/* <progress max={battery?.DatosUsuario.length} value={num}></progress> */}
          <span
            // className={
            //   num !== battery?.DatosUsuario.length ? "primary" : "success"
            // }
          >{`(${num}/${battery?.DatosUsuario.length})`}</span>
          <ArrowDropDownIcon />
        </div>
      </summary>
      <div className="details-body">
        <div className="row" style={{ display: "flex" }}>
          <div className="col-sm-2" style={{ flex: 1 }}>
            <FormControl fullWidth style={{ marginTop: 5 }}>
              <InputLabel id="demo-simple-select-label">
                Tipo de Doc.
              </InputLabel>
              <Select
                id="tipDoc"
                disabled={update}
                value={docType}
                label="Tipo de documento"
                onChange={(event) => setDocType(event.target.value)}
                data-keyword={"Tipo de documento"}
                data-parent={"Tipo de documento"}
              >
                <MenuItem value={"RUT"}>RUT</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="col-sm-10" style={{ flex: 2 }}>
            <MultiInput
              data={doc}
              id={"docInput"}
              disabled={update}
              requred={doc?.FlgObligatorio}
              placeholder={"Número de documento"}
              className="input-battery"
              parent="DatosUsuario"
              keyword={JSON.stringify(doc)}
              update={update}
              autoComplete="off"
              onChange={() => {
                if (!showUpd) setUpd(true);
              }}
              callback={updateRequest}
            />
          </div>
        </div>

        {/* <MultiInput
          data={phone}
          disabled={update}
          requred={phone?.FlgObligatorio}
          placeholder={"Celular"}
          className="input-battery"
          parent="DatosUsuario"
          keyword={JSON.stringify(phone)}
          update={update}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">+56</InputAdornment>
            ),
            inputProps: {
              min: 9,
              max: 9,
              minLength: 9,
              maxLength: 9,
            },
          }}
          onChange={() => {
            if (!showUpd) setUpd(true);
          }}
          callback={updateRequest}
        /> */}

        <FormControl fullWidth style={{ marginTop: 5 }}>
          <TextField
            fullWidth
            id="numPhone"
            disabled={update}
            required={false ?? phone?.FlgObligatorio}
            className={` ${"uppercase"} ${update ? "input-success" : ""}`}
            label={"Celular"}
            data-parent={"DatosUsuario"}
            data-keyword={JSON.stringify(phone)}
            variant="outlined"
            value={((phone?.Valor) ? phone.Valor : undefined)}
            autoComplete="off"
            onChange={() => {
              if (!showUpd) setUpd(true);
            }}
            type={"text"}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">+56</InputAdornment>
              ),
              inputProps: {
                min: 9,
                max: 9,
                minLength: 9,
                maxLength: 9,
              },
            }}
            step={"any"}
            min={9}
            max={9}
          />
        </FormControl>

        <MultiInput
          data={email}
          disabled={update}
          requred={true ?? email?.FlgObligatorio}
          placeholder={email.PlaceHolder}
          className="input-battery"
          parent="DatosUsuario"
          keyword={JSON.stringify(email)}
          update={update}
          autoComplete="off"
          onChange={() => {
            if (!showUpd) setUpd(true);
          }}
          callback={updateRequest}
        />
        <MultiInput
          data={name}
          disabled={update}
          requred={name?.FlgObligatorio}
          placeholder={name.PlaceHolder}
          className="input-battery"
          parent="DatosUsuario"
          keyword={JSON.stringify(name)}
          update={update}
          autoComplete="off"
          onChange={() => {
            if (!showUpd) setUpd(true);
          }}
          callback={updateRequest}
        />
      </div>
      {/* {!!showUpd && (
        <Button
          fullWidth
          variant="contained"
          className="btn-success"
          color="secondary"
          onClick={updateRequest}
        >
          <strong>Actualizar</strong>
        </Button>
      )} */}
    </details>
  );
};

const CarData = () => {
  const [store, dispatch] = React.useContext(StoreContext);
  const { battery } = store;
  const [comb, setComb] = React.useState();
  const [typeUse, setTU] = React.useState();


  let num = 0;
  for (let index = 0; index < battery?.DatosVehiculo.length; index++) {
    if (!!battery?.DatosVehiculo[index].Valor) num++;
  }

  const [showUpd, setUpd] = React.useState(false);
  const updateRequest = () => {
    ACTIVE_UPDATE = "CAR";
    let btn: any = document?.getElementById("btn-submit");
    btn?.click();
  };

  return (
    <details
      className={`details active ${num !== battery?.DatosVehiculo.length ? "" : "success"
        }`}
    >
      <summary>
        <strong>Datos de vehiculo</strong>
        <div className="arrow-container d-flex">
          {/* <progress max={battery?.DatosVehiculo.length} value={num}></progress> */}
          <span
            className={
              num !== battery?.DatosVehiculo.length ? "primary" : "success"
            }
          >{`(${num}/${battery?.DatosVehiculo.length})`}</span>
          <ArrowDropDownIcon />
        </div>
      </summary>
      <div className="details-body">
        {battery?.DatosVehiculo.map((item: any, i: number) => {
          let placeholder = item?.PlaceHolder;
          if (!!item?.FlgObligatorio) placeholder = `${placeholder} (*)`;
          let update = Number(battery?.Id) !== -1 ? true : false;
          let id = "";
          switch (item?.DescDet) {
            case "Placa de auto":
              id = "plateInput";
              placeholder = "patente";
              break;
            case "Tipo de uso":
              id = "useInput";
              break;
            case "Documento de identificación":
              id = "docInput";
              break;
            default:
              break;
          }

          return (

            <MultiInput
              key={i}
              id={id}
              data={item}
              requred={item?.FlgObligatorio}
              placeholder={placeholder}
              className="input-battery"
              parent="DatosVehiculo"
              keyword={JSON.stringify(item)}
              update={update}
              setWarrantyData={(value: any) => {
                if (
                  item?.PlaceHolder === "Tipo de Uso" ||
                  item?.PlaceHolder === "Tipo de uso"
                ) {
                  setTU(value);
                }
              }}
              onChange={() => {
                if (!showUpd) setUpd(true);
              }}
              callback={updateRequest}
            />
          );
        })}
      </div>
      {/* {!!showUpd && (
        <Button
          fullWidth
          variant="contained"
          className="btn-success"
          color="secondary"
          onClick={updateRequest}
        >
          <strong>Actualizar</strong>
        </Button>
      )} */}
    </details>
  );
};

export const Chile = (props: any) => {
  return (
    <>
      <UserData />
      {/* <CarData /> */}
    </>
  );
};

