import React from 'react';
import { icon_label_cart } from '../../assets/Media';

export function IconLabelCart() {
  return (
    <>
      <img src={icon_label_cart} alt="Recompensa" className="icon"/>
    </>
  );
}

