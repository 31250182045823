import * as React from 'react';
import {QrReader} from "react-qr-reader";

interface ScannerProps {
  callback: Function
}

export const Scanner = (props: ScannerProps) => {
  const [result, setResult] = React.useState('')
  const [scanEnabled, setScanEnabled] = React.useState(true)

  const handleError = (e: any) => {
    console.log('==e==================================');
    console.log(e);
    console.log('===e=================================');
  }

  const handleScan = (result: string) => {
    if (!!result && scanEnabled) {
      setResult(result)
      props?.callback(result)
      setScanEnabled(false)
    }
  }

  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  const videoConstraints = {
    facingMode: isMobile ? { exact: 'environment' } : 'user'
  };

  return (
    <div className="qr-reader-wrapper">
      <QrReader
        className="qr-reader"
        delay={300}
        onError={handleError}
        onScan={handleScan}
        constraints={videoConstraints}
      />
      <p>{result}</p>
    </div>
  );
};
