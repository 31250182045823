import * as React from 'react';
import Box from "@mui/material/Box";
import { imgContactus } from "../../assets/Media";
// import { StoreContext } from '../../store/StoreProvider';
// import { types } from '../../store/Dialog';

interface ContactusProps { }

function loadScriptDom(url: string, uuidKey: string) {

    if( document.getElementById(uuidKey) ){
        return false;
    }

    var head = document.getElementsByTagName('head')[0];
    var script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = url;
    script.id = uuidKey
    // script.onreadystatechange = callback;
    // script.onload = callback;
    head.appendChild(script);
}

export const ContactusComponent = (props: ContactusProps) => {

    // const [loadScript, setLoadScript] = React.useState(false);

    React.useEffect(() => {
        loadScriptDom('https://liveconnect.chat/es/CDN/liveconnect/liveconnect-widget.js', 'bmg-liveconnect-w');
        // setLoadScript(true);
    }, [])

    // const [store, dispatch] = React.useContext(StoreContext);
    return (
        <div className="container-contact-us" style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "calc(100vh - 100px)", width: "100vw" }}>
            <Box className='flex-center-column-contactus' sx={{ width: '100%', height: '100%' }}>
                <img src={imgContactus} alt='contactus' className='img-contactus' height="100%"></img>
                <liveconnect-widget canal-key={process.env.REACT_APP_LIVE_CONNECT}></liveconnect-widget>
            </Box>
        </div>
    );
};

