import * as React from "react";
import DialogDefault from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { StoreContext } from "../../store/StoreProvider";
import { types } from "../../store/Dialog";
import AppConfig from "../../constants/AppConfig";

const {
  colors: { primary },
} = AppConfig;

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const Dialog = React.memo(function DialogComponent(props: any) {

  const [store, dispatch] = React.useContext(StoreContext);
  const { dialog } = store;

  const handleClickOpen = () => {
    dispatch({ type: types.open })
  };

  const handleClose = () => {
    if (!!props.closeCallback) {
      props.closeCallback()
    } else {
      dispatch({ type: types.close })
    }
  };

  return (
    <div>
      <DialogDefault
        scroll={'body'}
        fullScreen
        open={dialog || props.open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative" }} style={{ backgroundColor: primary }}>
          <Toolbar>
            {!props?.hiddenClose && (
              <IconButton
                edge="start"
                onClick={handleClose}
                aria-label="close"
                style={{ color: '#000' }}
              >
                <ArrowBackIcon />
              </IconButton>
            )}

            {
              typeof (props?.title) !== 'string' ?
                (
                  <>
                    {props?.title}
                  </>
                ) :
                (
                  <Typography className="cardName-title" sx={{ ml: 2, flex: 1 }} variant="h6" component="div" align="center" style={{ color: '#000' }}>
                    {props?.title}
                  </Typography>
                )
            }
            {
              props?.right
            }
          </Toolbar>
        </AppBar>

        {
          props.children
        }
      </DialogDefault>
    </div>
  );
})
