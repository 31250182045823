import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import InputBase from "@mui/material/InputBase";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import { Typography } from "@material-ui/core";
import Grid from '@mui/material/Grid';

import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import PhoneAndroidOutlinedIcon from '@mui/icons-material/PhoneAndroidOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';

import { IconSearch } from "../icons/IconSearch";

import { StoreContext } from '../../store/StoreProvider';
import { getClients, getBusinessPartners, getCorpBusinessPartners } from "../../store/sagas/Clients";
import { types } from '../../store/Clients';
import Loading from '../Loading';
import { clientAddress, CorpClientAddress } from '../../store/sagas/Warranty';
import { useDebounce } from "../../hooks/useDebounce";
import AppConfig from '../../constants/AppConfig';
import { BannerModal } from "../Home/BannerModal";
import { getI18N } from '../../i18n'

const {
  types: { seller },
} = AppConfig;

interface ClientListProps {
  callback?: any;
}

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
  paddingTop: "10px",
  paddingBottom: "10px",
  paddingRight: "5px",
  paddingLeft: "5px",
  // borderBottom: "solid 2px #e0e0e0",
  borderBottom: "solid 1px #000000"
}));


const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    // paddingLeft: theme.spacing(1),
    transition: theme.transitions.create("width"),
    width: "100%",
    // backgroundColor: "#e0e0e05c",
    backgroundColor: "#FFFFFF",
    // borderRadius: theme.shape.borderRadius,
    borderRadius: "12px",
    marginLeft: "2vw",
    marginRight: "2vw",
    paddingLeft: "2rem"
  },
}));

function SearchComponent({ setData }: any) {
  const [filter, setFilter] = React.useState("");
  const [store, dispatch] = React.useContext(StoreContext);
  const [loading, setLoad] = React.useState(false);

  const { Country } = store?.user;
  const currentLocale: string = Country || "PE";
  const i18n = getI18N({ currentLocale })

  const search_place = (search: string) => {
    if (search.length >= 4) {
      setLoad(true);
      let obj = {
        user_id: store.user.id,
        user_type: store.user.tipo,
        lastUpdate: null,
        maxRows: 100,
        sort: "",
        pag: 0,
        search,
      };

      // if( store?.userAppCorp ){
      obj.sort = "D";
      getCorpBusinessPartners(obj).then((clients) => {
        setLoad(false);
        setData(clients.value);
      });
      return;
      // }

      // getBusinessPartners(obj).then((clients) => {
      //   setLoad(false);
      //   setData(clients.value);
      // });
    }
  };

  const searchDebounce = useDebounce(search_place, 400);

  return (
    <>
      <Search className="search-clients">
        <IconSearch />
        <StyledInputBase
          placeholder={`${i18n.SEARCH}...`}
          inputProps={{ "aria-label": "buscar" }}
          value={filter}
          onChange={(event) => {
            setFilter(event.target.value);
            if (event.target.value.length >= 4) {
              searchDebounce(event.target.value);
            }
          }}
        />
      </Search>

      {!!loading && (
        <div
          className="text-center d-flex"
          style={{ justifyContent: "center", paddingTop: "5vh" }}
        >
          <Loading loading={loading} />
        </div>
      )}
    </>
  );
}

function ClientListFunc(props: ClientListProps) {

  const [store, dispatch] = React.useContext(StoreContext)
  const [localData, setData] = React.useState(store?.clients_lite);
  const [filter, setFilter] = React.useState("");
  const [loading, setLoad] = React.useState(false);

  const { Country } = store?.user;
  const currentLocale: string = Country || "PE";
  const i18n = getI18N({ currentLocale })

  React.useEffect(() => {
    if (store?.clients_lite.length) setData(store?.clients_lite);
  }, [store?.clients_lite]);

  React.useEffect(() => {
    if (!store?.clients_lite.length) {
      setLoad(true)
      let obj = {
        user_id: store.user.id,
        user_type: store.user.tipo,
        lastUpdate: null,
        maxRows: 100,
        sort: "",
        pag: 0,
        search: "",
      };

      // if( store?.userAppCorp ){
      obj.sort = "D";
      obj.maxRows = 10;
      getCorpBusinessPartners(obj).then((clients) => {
        setLoad(false);
        dispatch({ type: types.setClients, payload: clients.value });
        setData(clients.value);
      });

      // }else{
      //   getBusinessPartners(obj).then((clients) => {
      //     setLoad(false);
      //     dispatch({ type: types.setClients, payload: clients.value });
      //     setData(clients.value);
      //   });
      // }


    }
    window.addEventListener("scroll", handleScroll);
  }, []);

  const handleScroll = () => {

    if (
      Math.ceil(window.innerHeight + document.documentElement.scrollTop) !==
      document.documentElement.offsetHeight
    )
      return;

  };

  const action = (id: any) => {
    let client: any;
    if (store?.user?.tipo === seller) {
      client = localData.find((client: any) => client.Id === id);
    } else {
      client = store.clients.find((client: any) => client.Id === id);
    }

    // if( store?.userAppCorp ){
    const paramsRequest = {
      CodCliente: client.Id
    }

    CorpClientAddress(paramsRequest).then((data) => {

      const address = data.value;
      if (!!props.callback) {
        props.callback();
        dispatch({ type: types.selectClientLite, payload: client, address });
      } else {
        dispatch({ type: types.selectClient, payload: client, address });
      }

    })
    // }else{
    //   clientAddress({
    //     key: client.Id,
    //   }).then((address) => {
    //     if (!!props.callback) {
    //       props.callback();
    //       dispatch({ type: types.selectClientLite, payload: client, address });
    //     } else {
    //       dispatch({ type: types.selectClient, payload: client, address });
    //     }
    //   });
    // }
  }

  let typeDocumentLabel = "";

  switch (store?.user?.Country) {
    case "PE":
    case "NAC":
      typeDocumentLabel = "DNI / RUC";
      break;
    case "VE":
      typeDocumentLabel = "CI / RIF";
      break;
    case "CL":
      typeDocumentLabel = "RUT";
      break;
    case "EC":
      typeDocumentLabel = "CI / RUC / CE";
      break;
    case "DO":
      typeDocumentLabel = "CI / RNC";
      break;
    case "BO":
      typeDocumentLabel = "CI / NIT";
      break;
    case "TT":
      typeDocumentLabel = "NIC / TIN";
      break;
  }

  const ListItemData = React.memo(({ Ruc, Email, Phone, Address, RzSocial, Id, position, docIdentidad }: any) => (

    <>
      <ListItem button onClick={() => action(Id)}>
        <div className="list-container">
          <Typography variant="subtitle1" gutterBottom component="div" style={{ marginBottom: 0, textTransform: "uppercase", fontWeight: 'bold', }}>
            {RzSocial}
          </Typography>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <div className="list-detail-container">
                <span className="text-muted">{typeDocumentLabel}: {docIdentidad}</span>
                <span className="text-muted"> <EmailOutlinedIcon fontSize="small" /> {i18n.EMAIL}: {Email}</span>
                <span className="text-muted"> <PhoneAndroidOutlinedIcon fontSize="small" /> {i18n.PHONE}.: {Phone}</span>
                <span className="text-muted"> <LocationOnOutlinedIcon fontSize="small" /> {i18n.DIRECTION}: {Address}</span>
              </div>
            </Grid>
            {/* <Grid item xs={8}>
              <div className="list-detail-container">
                <span className="text-muted">Email: {Email}</span>
                <span className="text-muted">Dirección: {Address}</span>
              </div>
            </Grid> */}
          </Grid>
        </div>
      </ListItem>
      <Divider />
    </>
  ));


  const search = (f: any) =>
    f.RzSocial.toLocaleUpperCase().includes(filter.toLocaleUpperCase()) ||
    filter === "";


  return (
    <>
      <SearchComponent setData={setData} />

      {!!loading ? (
        <div
          className="text-center d-flex"
          style={{ justifyContent: "center", paddingTop: "5vh" }}
        >
          <Loading loading={loading} />
        </div>
      ) : (
        <List
          sx={{
            width: "100%",
            bgcolor: "background.paper",
            position: "relative",
            overflow: "auto",
            maxHeight: "90vh",
            "& ul": { padding: 0 },
          }}
        >
          {!!localData &&
            !!localData.length &&
            localData.map((item: any, key: number) => {
              return <ListItemData {...item} key={key} position={key} />;
            })}
        </List>
      )}

      {store?.user?.tipo === "SN" && (
        <BannerModal open={store?.openModalBanner} imgstatic={true} />
      )}

    </>
  );
};

export const ClientList = React.memo(ClientListFunc)