import * as React from 'react';
import LogoutIcon from "@mui/icons-material/Logout";
import { ClientCard } from './ClientCard';
import {
  IconButton,
} from "@material-ui/core";
import { StoreContext } from '../../store/StoreProvider';
import { types } from '../../store/Auth';
import AppConfig from '../../constants/AppConfig';
import { types as typesDialog } from "../../store/Dialog";

const { types: { seller } } = AppConfig

interface HomeBarProps { }

export const HomeBar = React.memo((props: HomeBarProps) => {
  const [store, dispatch] = React.useContext(StoreContext);

  // const [openClient, setOpenClient] = React.useState(false);

  const logoutClient = () => {
    if (!!Object.keys(store?.selected_client).length) {
      if (window?.confirm(`Para quitar al cliente ${store?.selected_client?.RzSocial}, se borrara la información ingresada\n¿Está seguro de esto?`) === true) {
        dispatch({ type: types.logoutClient });
      }
    }
  };

  React.useEffect(() => {
    if ((!store?.selected_client?.Id)) {
      // setOpenClient(true);
      dispatch({ type: typesDialog.open, payload: 'client' });
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <ClientCard />
      {store?.user?.tipo === seller && !store?.userAppCorp && (
        <IconButton
          edge="end"
          className={`pull-right`}
          color="inherit"
          aria-label="logout"
          onClick={logoutClient}
        >
          <LogoutIcon />
        </IconButton>
      )}
    </>
  );
});
