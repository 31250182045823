import * as React from "react";
import { StoreContext } from "../../../store/StoreProvider";
// import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { IntensivoTaxi, MultiInput, Particular, Pesado } from "../../MultiInput";
// import Button from "@material-ui/core/Button";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import TextFieldDefault, { TextFieldProps } from "@mui/material/TextField";
// import { DisplaySelects } from "../DisplaySelects";
// import { InputAdornment } from "@material-ui/core";
// interface VenezuelaProps { }
var ACTIVE_UPDATE = "";

type InputCustomProps = {};
type InputProps = InputCustomProps & TextFieldProps;
const TextField: any = React.forwardRef((props: InputProps, defRef: any) => {
  return <TextFieldDefault {...props} />;
});

const UserData = () => {
  const [store, dispatch] = React.useContext(StoreContext);
  const [showUpd, setUpd] = React.useState(false);

  const [docType, setDocType] = React.useState(
    store?.inputSelectCountry?.ve_tipDoc || "CI"
  );
  const [docTypeNac, setDocTypeNac] = React.useState(
    store?.inputSelectCountry?.ve_docNac || "V-"
  );
  const [codePhone, setCodePhone] = React.useState(
    store?.inputSelectCountry?.ve_codePhone || "0424"
  );
  const [numberPhone, setNumberPhone] = React.useState(
    store?.inputSelectCountry?.ve_numPhone || ""
  );
  // console.log(store?.inputSelectCountry)
  
  const { battery } = store;

  let num = 0;
  for (let index = 0; index < battery?.DatosUsuario.length; index++) {
    if (!!battery?.DatosUsuario[index].Valor) num++;
  }

  const updateRequest = () => {
    ACTIVE_UPDATE = "USER";
    let btn: any = document?.getElementById("btn-submit");
    btn?.click();
  };

  let update = Number(battery?.Id) !== -1 ? true : false;
  if (!!battery?.TienePreTarjeta) {
    update = true;
  }

  let doc: any = battery?.DatosUsuario.find(
    ({ DescDet }: any) =>
      DescDet.toLocaleLowerCase() ===
      "Documento de identificación".toLocaleLowerCase()
  );

  // (doc?.Valor)

  let numDocumentValue = doc.Valor.split(" ");
  // numDocumentValue = numDocumentValue.length === 1 ? doc.Valor.split(" ") : numDocumentValue;

  if (numDocumentValue.length > 1) {

    if (numDocumentValue.length === 2) {
      if (docTypeNac !== numDocumentValue[1]) {
        setDocTypeNac(numDocumentValue[1]);
      }
    }

    if (numDocumentValue.length === 3) {
      if (docTypeNac !== numDocumentValue[0]) {
        setDocType("RIF");
        setDocTypeNac(numDocumentValue[0]);
      }
    }

    doc = {
      ...doc,
      Valor: numDocumentValue[1],
    };

  }

  doc = {
    ...doc,
    Maximo: 9,
    Minimo: 7,
  };

  let phone: any = battery?.DatosUsuario.find(
    ({ DescDet }: any) =>
      DescDet.toLocaleLowerCase() === "Celular de cliente".toLocaleLowerCase()
  );
  // console.log(phone.Valor)
  if (!!phone.Valor) {
    let numPhoneValue = phone.Valor.split(" ");
    
    // console.log(numPhoneValue)
    if (numPhoneValue.length > 1) {

      if( numberPhone !== numPhoneValue[1] ){
        setNumberPhone(numPhoneValue[1]);
      }

      phone = {
        ...phone,
        Valor: numPhoneValue[1],
      };

      let cmon = `${numPhoneValue[0]}`;
      if (codePhone !== cmon) {
        setCodePhone(cmon);
      }
    }
  }

  let email: any = battery?.DatosUsuario.find(
    ({ DescDet }: any) =>
      DescDet.toLocaleLowerCase() === "Correo de cliente".toLocaleLowerCase()
  );

  let name: any = battery?.DatosUsuario.find(
    ({ DescDet }: any) =>
      DescDet.toLocaleLowerCase() === "Nombre cliente".toLocaleLowerCase()
  );

  //   match = event.target.value.match(v);
  // if (match) {
  //   return true;
  // }
  return (
    <details
      className={`details active ${num !== battery?.DatosUsuario.length ? "" : "success"
        }`}
    >
      <summary>
        <strong style={{ color: "#000" }}>Datos de cliente</strong>
        <div className="arrow-container d-flex">
          {/* <progress max={battery?.DatosUsuario.length} value={num}></progress> */}
          <span
            // className={
            //   num !== battery?.DatosUsuario.length ? "primary" : "success"
            // }
          >{`(${num}/${battery?.DatosUsuario.length})`}</span>
          <ArrowDropDownIcon />
        </div>
      </summary>
      <div className="details-body">
        <div className="row" style={{ display: "flex" }}>
          <div className="col-sm-2" style={{ flex: 0.7 }}>
            <FormControl fullWidth style={{ marginTop: 5 }}>
              <InputLabel id="demo-simple-select-label">
                Tipo de Doc.
              </InputLabel>
              <Select
                id="tipDoc"
                value={docType}
                label="Tipo de documento"
                onChange={(event) => {
                  let index = ["J", "G", "V"].find(
                    (element) =>
                      docTypeNac.toLocaleUpperCase().indexOf(element) !== -1
                  );

                  let indexCI = ["V", "E"].find(
                    (element) =>
                      docTypeNac.toLocaleUpperCase().indexOf(element) !== -1
                  );

                  let isUpdateDocType = false;

                  let is_rif = event.target.value === "RIF";
                  setDocType(event.target.value);
                  if (is_rif && !index) {
                    isUpdateDocType = true;
                  } else if (!indexCI && !is_rif) {
                    isUpdateDocType = true;
                  }

                  if (isUpdateDocType) {
                    let doc = docTypeNac;
                    let result = doc
                      .replace(/-/i, "")
                      .replace(/V/i, "")
                      .replace(/E/i, "")
                      .replace(/J/i, "")
                      .replace(/G/i, "");//g
                    setDocTypeNac(`V-${result}`);
                  }
                }}
                disabled={update}
                data-keyword={"Tipo de documento"}
                data-parent={"Tipo de documento"}
              >
                <MenuItem value={"CI"}>CI</MenuItem>
                <MenuItem value={"RIF"}>RIF</MenuItem>
              </Select>
            </FormControl>
          </div>

          <div className="col-sm-10" style={{ flex: 3 }}>
            <FormControl fullWidth style={{ marginTop: 5 }}>
              <TextField
                fullWidth
                id="docNac"
                disabled={update}
                required={doc?.FlgObligatorio}
                className={` ${"uppercase"} ${update ? "input-success" : ""}`}
                label={"Número de documento"}
                data-parent={"DatosUsuario"}
                data-keyword={JSON.stringify(doc)}
                variant="outlined"
                // value={(doc?.Valor) ? doc.Valor : docTypeNac}
                value={docTypeNac}
                onChange={(event: any) => {
                  let selfValidation: any = () => {
                    if (event.target.value.length < docTypeNac.length) {
                      return true;
                    } else {
                      let is_ci = docType === "CI";
                      let v = /^[0-9]*$/;

                      if (is_ci) {

                        let indexCI = ["V", "E"].find(
                          (element) =>
                            event.target.value
                              .toLocaleUpperCase()
                              .indexOf(element) !== -1
                        );

                        if (indexCI) {
                          if (
                            event.target.value.toLocaleUpperCase() === "V" ||
                            event.target.value.toLocaleUpperCase() === "E"
                          ) {
                            setDocTypeNac(`${event.target.value}-`);
                          } else {
                            if (event.target.value.indexOf("-") !== -1) {
                              let obj = event.target.value.split("-");
                              if (obj[obj.length - 1].match(v)) {
                                setDocTypeNac(
                                  `${obj[0]}-${obj[obj.length - 1]}`
                                );
                                return false;
                              } else {
                                return false;
                              }
                            } else {
                              let value = event.target.value.slice(1);
                              if (value.match(v)) {
                                setDocTypeNac(
                                  `${event.target.value[0]}-${value}`
                                );
                              }
                              return false;
                            }
                          }

                          return false;
                        } else {
                          return false;
                        }

                      } else if (docType === "RIF") {
                        let index = ["J", "G", "V"].find(
                          (element) =>
                            event.target.value
                              .toLocaleUpperCase()
                              .indexOf(element) !== -1
                        );
                        if (index) {
                          if (
                            event.target.value.toLocaleUpperCase() === "V" ||
                            event.target.value.toLocaleUpperCase() === "G" ||
                            event.target.value.toLocaleUpperCase() === "J"
                          ) {
                            setDocTypeNac(`${event.target.value}-`);
                          } else {
                            if (event.target.value.indexOf("-") !== -1) {
                              let obj = event.target.value.split("-");
                              if (obj[obj.length - 1].match(v)) {
                                setDocTypeNac(
                                  `${obj[0]}-${obj[obj.length - 1]}`
                                );
                                return false;
                              } else {
                                return false;
                              }
                            } else {
                              let value = event.target.value.slice(1);
                              if (value.match(v)) {
                                setDocTypeNac(
                                  `${event.target.value[0]}-${value}`
                                );
                              }
                              return false;
                            }
                          }
                          return false;
                        } else {
                          return false;
                        }
                      }
                    }

                    return false;
                  };

                  if (!!selfValidation()) {
                    setDocTypeNac(event.target.value);
                  }
                }}
                type={"text"}
                InputProps={{
                  inputProps: {
                    // min: docType === "CI" ? 9 : 11,
                    // max: docType === "CI" ? 9 : 11,
                    // minLength: docType === "CI" ? 9 : 11,
                    // maxLength: docType === "CI" ? 9 : 11,
                    min: 9,
                    max: 13,
                    minLength: 9,
                    maxLength: 13,
                  },
                }}
                step={"any"}
                // min={docType === "CI" ? 9 : 11}
                // max={docType === "CI" ? 9 : 11}
                min={9}
                max={13}
              />
            </FormControl>

            {/* <MultiInput
              data={doc}
              disabled={update}
              requred={doc?.FlgObligatorio}
              placeholder={"Número de documento"}
              className="input-battery"
              parent="DatosUsuario"
              keyword={JSON.stringify(doc)}
              update={update}
              onChange={() => {
                if (!showUpd) setUpd(true);
              }}
              callback={updateRequest}
            /> */}
          </div>
        </div>
        <div className="row" style={{ display: "flex" }}>
          <div className="col-sm-1" style={{ flex: 0.7 }}>
            <span
              style={{
                display: "flex",
                justifyContent: "center",
                padding: "20px 0",
                fontSize: "1.1em",
                color: "#383838",
              }}
            >
              +58
            </span>
          </div>
          <div className="col-sm-1 dropdown-code" style={{ flex: 0.7 }}>
            <FormControl fullWidth style={{ marginTop: 5 }}>
              <InputLabel id="demo-simple-select-label">Código</InputLabel>
              <Select
                id="codePhone"
                value={codePhone}
                label=""
                onChange={(event) => setCodePhone(event.target.value)}
                disabled={update}
                data-keyword={""}
                data-parent={""}
              >
                <MenuItem value={"58424"}>424</MenuItem>
                <MenuItem value={"58426"}>426</MenuItem>
                <MenuItem value={"58414"}>414</MenuItem>
                <MenuItem value={"58416"}>416</MenuItem>
                <MenuItem value={"58412"}>412</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="col-sm-10" style={{ flex: 3 }}>
            {/* <MultiInput
              data={phone}
              disabled={update}
              requred={phone?.FlgObligatorio}
              placeholder={"Celular"}
              className="input-battery"
              parent="DatosUsuario"
              keyword={JSON.stringify(phone)}
              update={update}
              onChange={() => {
                if (!showUpd) setUpd(true);
              }}
              callback={updateRequest}
            /> */}
            <FormControl fullWidth style={{ marginTop: 5 }}>
              <TextField
                fullWidth
                id="numPhone"
                data={phone}
                disabled={update}
                required={phone?.FlgObligatorio}
                className={` ${"uppercase"} ${update ? "input-success" : ""}`}
                label={"Celular"}
                data-parent={"DatosUsuario"}
                data-keyword={JSON.stringify(phone)}
                variant="outlined"
                value={numberPhone}
                onChange={(event: any) => {
                  // if (!showUpd) setUpd(true);
                  setNumberPhone(event.target.value);
                }}
                type={"text"}
                InputProps={{
                  inputProps: {
                    min: 7,
                    max: 7,
                    minLength: 7,
                    maxLength: 7,
                  },
                }}
                step={"any"}
                min={7}
                max={7}
              />
            </FormControl>
          </div>
        </div>
        <MultiInput
          data={email}
          disabled={update}
          requred={true ?? email?.FlgObligatorio}
          placeholder={email.PlaceHolder}
          className="input-battery"
          parent="DatosUsuario"
          keyword={JSON.stringify(email)}
          update={update}
          onChange={() => {
            if (!showUpd) setUpd(true);
          }}
          callback={updateRequest}
        />
        <MultiInput
          data={name}
          disabled={update}
          requred={name?.FlgObligatorio}
          placeholder={name.PlaceHolder}
          className="input-battery"
          parent="DatosUsuario"
          keyword={JSON.stringify(name)}
          update={update}
          onChange={() => {
            if (!showUpd) setUpd(true);
          }}
          
          callback={updateRequest}
        />
      </div>
      {/* {!!showUpd && (
        <Button
          fullWidth
          variant="contained"
          className="btn-success"
          color="secondary"
          onClick={updateRequest}
        >
          <strong>Actualizar</strong>
        </Button>
      )} */}
    </details>
  );
};

const CarData = (props: any) => {
  // console.log(props)
  const [store, dispatch] = React.useContext(StoreContext);
  const { battery } = store;
  const [comb, setComb] = React.useState();
  const [typeUse, setTU] = React.useState();

  let num = 0;
  for (let index = 0; index < battery?.DatosVehiculo.length; index++) {
    if (!!battery?.DatosVehiculo[index].Valor) num++;
  }

  const [showUpd, setUpd] = React.useState(false);
  const updateRequest = () => {
    ACTIVE_UPDATE = "CAR";
    let btn: any = document?.getElementById("btn-submit");
    btn?.click();
  };

  return (
    <details
      className={`details active ${num !== battery?.DatosVehiculo.length ? "" : "success"
        }`}
    >
      <summary>
        <strong>Datos de vehiculo</strong>
        <div className="arrow-container d-flex">
          {/* <progress max={battery?.DatosVehiculo.length} value={num}></progress> */}
          <span
            className={
              num !== battery?.DatosVehiculo.length ? "primary" : "success"
            }
          >{`(${num}/${battery?.DatosVehiculo.length})`}</span>
          <ArrowDropDownIcon />
        </div>
      </summary>
      <div className="details-body">
        {battery?.DatosVehiculo.map((item: any, i: number) => {
          let placeholder = item?.PlaceHolder;
          if (!!item?.FlgObligatorio) placeholder = `${placeholder} (*)`;
          let update = Number(battery?.Id) !== -1 ? true : false;
          let id = "";
          switch (item?.DescDet) {
            case "Placa de auto":
              id = "plateInput";
              break;
            case "Tipo de uso":
              id = "useInput";
              break;
            default:
              break;
          }
          return (
            <MultiInput
              key={i}
              id={id}
              data={item}
              requred={item?.FlgObligatorio}
              placeholder={placeholder}
              className="input-battery"
              parent="DatosVehiculo"
              keyword={JSON.stringify(item)}
              update={update}
              setWarrantyData={(value: any) => {
                if (
                  item?.PlaceHolder === "Tipo de Uso" ||
                  item?.PlaceHolder === "Tipo de uso"
                ) {
                  setTU(value);
                }

                if (
                  item?.PlaceHolder === "Combustión" ||
                  item?.PlaceHolder === "Tipo de combustión"
                ) {
                  setComb(value);
                }

              }}
              onChange={(e: any) => {
                if (!showUpd) setUpd(true);

              }}
              callback={updateRequest}
            />
          );
        })}
      </div>
      {/* {!!showUpd && (
        <Button
          fullWidth
          variant="contained"
          className="btn-success"
          color="secondary"
          onClick={updateRequest}
        >
          <strong>Actualizar</strong>
        </Button>
      )} */}
    </details>
  );
};

export const Venezuela = (props: any) => {
  // const [customWarranty, setCustomWarranty] = React.useState<any>();
  // const [customRev, setCustomRev] = React.useState<any>();

  return (
    <>
      {/* <DisplaySelects
        customWarranty={customWarranty}
        setCustomWarranty={setCustomWarranty}
        customRev={customRev}
        setCustomRev={setCustomRev}
      /> */}
      <UserData />
      {/* <CarData
        // customWarranty={customWarranty}
        // setCustomWarranty={setCustomWarranty}
        // customRev={customRev}
        // setCustomRev={setCustomRev}
      /> */}
    </>
  );
};
