import React from 'react';
import { icon_ship } from '../../assets/Media';

export function IconShip() {
  return (
    <>
      <img src={icon_ship} alt="Ship" className="icon icon-custom-tab"/>
    </>
  );
}

