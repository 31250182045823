import React from "react";
import AppConfig from '../constants/AppConfig';
// import { type } from "os";

const types = {
  setClients: "SET_CLIENTS",
  selectClient: "SELECT_CLIENT",
  setSeflClients: "SELECT_SELF_CLIENT",
  selectClientLite: "SELECT_CLIENT_LITE",
  rewardClientCartList: "REWARD_CLIENT_CART_LIST",
};

const { userTypeCode } = AppConfig

const ClientReducer = (state, action) => {
  switch (action.type) {
    case types.setSeflClients:
      return {
        ...state,
        selected_client: action.payload[0],
        clients: action.payload,
        clients_lite: action.payload
          .map(({ Ruc, Email, Phone, Address, RzSocial, Id, docIdentidad, CANAL_SN }) => ({
            Ruc,
            Email,
            Phone,
            Address,
            RzSocial,
            Id,
            docIdentidad,
            CANAL_SN
          }))
          .sort((a, b) => {
            if (a.RzSocial < b.RzSocial) return -1;
            if (a.RzSocial > b.RzSocial) return 1;
            return 0;
          }),
      };
    case types.setClients:
      return {
        ...state,
        clients: action.payload,
        clients_lite: action.payload
          .map(({ Ruc, Email, Phone, Address, RzSocial, Id, docIdentidad, CANAL_SN }) => ({
            Ruc,
            Email,
            Phone,
            Address,
            RzSocial,
            Id,
            docIdentidad,
            CANAL_SN
          }))
          .sort((a, b) => {
            if (a.RzSocial < b.RzSocial) return -1;
            if (a.RzSocial > b.RzSocial) return 1;
            return 0;
          }),
      };
    case types.selectClient:
      return {
        ...state,
        selected_client: action.payload,
        clientAddress: action.address,
        dialog: false,
        active_dialog: "",
        // searchCode: "",
        userAppCorp: (action.payload?.CANAL_SN === userTypeCode.corporate),
        openModalBanner: false
      };
    case types.selectClientLite:
      return {
        ...state,
        clientAddress: action.address,
        selected_client: action.payload,
        // searchCode: "",
        userAppCorp: (action.payload?.CANAL_SN === userTypeCode.corporate),
        openModalBanner: false
      };
    case types.rewardClientCartList:
      return {
        ...state,
        rewardDataCart: action.payload || {},
      }
    default:
      return state;
  }
};

export { ClientReducer, types };
