import * as React from "react";
import Dialog, {DialogProps} from "@mui/material/Dialog";
// import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@material-ui/core/Button";

// import Radio from "@mui/material/Radio";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import { Alert } from "../Alert";
// import Loading from "../Loading";
import { setVersionApp } from "../../store/sagas/Main";
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { StoreContext } from '../../store/StoreProvider';
import { getI18N } from '../../i18n'

interface SuccessModalProps {
  open?: boolean;
  onClose: () => void;
}
export const SuccessModal = (props: SuccessModalProps) => {
  const [store, dispatch] = React.useContext(StoreContext);
  // const [hide, setHide] = React.useState(false);
  const [maxWidth, setMaxWidth] = React.useState<DialogProps['maxWidth']>('xs');

  const { Country } = store?.user;
  const currentLocale: string = Country || "PE";
  const i18n = getI18N({ currentLocale })

  return (
    <div style={{}}>
      <Dialog fullWidth={true} maxWidth={maxWidth} open={props.open ?? false} onClose={() => console.log()} className={"modal-custom-bmg"}>
        <DialogTitle style={{ textAlign: "center", background: "#FFFFFF" }}>
          <IconButton
            aria-label="close"
            onClick={props.onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent style={{ textAlign: "center", background: "#FFFFFF" }}>
          <FormControl >
            <FormLabel style={{ textAlign: "center", color: "#000", fontSize: "24px" ,marginTop: "0.5rem", marginBottom: "1rem"}}>
              ¡{i18n.SUSCCESSFUL_REGISTRATION}!
            </FormLabel>
          </FormControl>
        </DialogContent>
      </Dialog>
    </div>
  );
};
