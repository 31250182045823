import * as React from 'react';
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { bg_title, img_trophy_lightning, img_banner_niveles } from "../../assets/Media";
import { StoreContext } from '../../store/StoreProvider';
import { IconCart } from "../icons/IconCart";
import { Typography } from "@material-ui/core";
import { Stack } from '@mui/material';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { merchListExt, merchListHistoryExt, clientPointsGetExt } from "../../store/sagas/Clients";
import IconButton from "@mui/material/IconButton";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { RewardCartList } from "./RewardCartList";
import { Dialog } from "../App/Dialog";
import { types as typesDialog } from "../../store/Dialog";
import { ProductCard } from "../App/ProductCard";
import moment from "moment";
import { Alert } from "../Alert";
import { getI18N } from '../../i18n'

interface RewardProps { }
// const ACTIVE_DIALOG = "reward_cart_list";

export const RewardComponent = (props: RewardProps) => {
  interface Product {
    itemCode: string;
    itemName: string;
    urlImage: string;
    precio: number;
    cantidad: number;
    stock: number;
    onAddToCart: (itemCode: string) => void;
    onRemoveFromCart: (itemCode: string) => void;
  }

  interface History {
    fecha: string;
    importe: number;
    detalle: any[];
  }

  const [store, dispatch] = React.useContext(StoreContext);
  const [poinstClient, setPointsClient] = React.useState<number>(0);
  const [levelClient, setLevelClient] = React.useState<Number>(0);
  const [dataProducts, setDataProducts] = React.useState<Product[]>([]);
  const [quantityInCart, setQuantityInCart] = React.useState<number>(0);
  const [dataGiftHistory, setDataGiftHistory] = React.useState<History[]>([]);

  const [openDialogCart, setOpenDialogCart] = React.useState<Boolean>(false);
  const [dataProductsInCart, setDataProductsInCart] = React.useState<Product[]>([]);

  const [page, setPage] = React.useState<number>(1);
  const [loading, setLoading] = React.useState<boolean>(false);
  const observer = React.useRef<IntersectionObserver | null>(null);
  const bottomBoundaryRef = React.useRef<HTMLDivElement>(null);

  const [countList, setCountList] = React.useState<number>(0);
  const [sizePage, setSizePage] = React.useState<number>(0);
  const [lastPage, setLastPage] = React.useState<number>(1);

  const AlertRef = React.useRef<any>();

  const { Country } = store?.user;
  const currentLocale: string = Country || "PE";
  const i18n = getI18N({ currentLocale })

  // const clientId: string = store?.selected_client?.UserId;
  const clientId: string = store?.user?.user_login;
  const cardCodeParent: string = store?.user?.cardCode2 ?? store?.user.id;
  // const cardCodeParent: string = (clientId?.slice(0, 2) === "AC" || clientId?.slice(0, 1) === "A") ? store?.user.id : clientId;
  
  React.useEffect(() => {
    const dataRequestPromotions = {
      "cardCodePadre": cardCodeParent,
      "cardCode": clientId,
      "almacenCode": "1010",
      "marcas": [
        1,
        2
      ],
      "cardType": store?.user.tipo
    }

    merchListExt(dataRequestPromotions).then((response) => {
      if (response.status === "ok") {
        setDataProducts(response.data ?? []);
      }
    })

    // obtener puntos del cliente
    clientPointsGetExt({ "cardCode": clientId, "cardCodePadre": cardCodeParent, }).then((response) => {
      if (response.status === "ok") {
        setPointsClient(response.data);
        setLevelClient(response.nivel);
      }
    })
  }, [clientId, store?.user.tipo, cardCodeParent]);

  React.useEffect(() => {
    const fetchDataHistory = async () => {
      setLoading(true);

      const dataRequestHistory = {
        "cardCodePadre": cardCodeParent,
        "cardCode": clientId,
        "page": page,
        "size": 20
      }

      merchListHistoryExt(dataRequestHistory).then((response) => {
        setLoading(false);
        if (response.status === 'ok') {
          const countList: number = response?.count;
          const sizePage: number = response?.size;
          let lastPage: number = Math.floor(countList / sizePage);

          setCountList(response.count);
          setSizePage(response?.size);

          if (countList % sizePage !== 0) {
            lastPage++
          }

          setLastPage(lastPage);
          const dataHistory = response?.data || [];
          setDataGiftHistory(dataGiftHistory => [...dataGiftHistory, dataHistory]);
          setPage(page + 1);
        }
      })
    }

    const handleIntersection: IntersectionObserverCallback = (entries) => {
      const entry = entries[0];
      if (entry.isIntersecting && !loading && (page <= lastPage)) {
        fetchDataHistory();

        if ((page === lastPage)) {
          observer.current?.disconnect();
        }
      }
    };

    const options: IntersectionObserverInit = {
      root: document.querySelector('.container-history'),
      rootMargin: '200px',
      threshold: 1.0
    };

    observer.current = new IntersectionObserver(handleIntersection, options);
    if (bottomBoundaryRef.current) {
      observer.current.observe(bottomBoundaryRef.current);
    }

    return () => {
      if (observer.current) {
        observer.current.disconnect();
      }
    };

  }, [loading, clientId, lastPage, sizePage, countList, page, dataGiftHistory, cardCodeParent])

  const clientNameParts = store?.selected_client?.RzSocial.split(' ');
  const clientName = `${clientNameParts[0]} ${clientNameParts[1] ?? ""}`;

  const CardProductSlider = ({ itemCode, urlImage, precio, itemName, cantidad, onRemoveFromCart, onAddToCart }: Product) => {
    return (
      <Box style={{ display: "grid", placeItems: "center" }}>

        <ProductCard data={{ itemCode, urlImage, precio, itemName }} height={'100%'} visibleLabel={false} />

        <Box className='content-actions-cart'>
          <IconButton
            onClick={() => {
              onRemoveFromCart(itemCode);
            }}
            sx={{ p: "10px" }}
            aria-label=""
            disableFocusRipple={true}
            style={{ color: "#000", paddingTop: "0", paddingBottom: "0" }}
            color="primary"
            size="small"
          >
            <RemoveIcon fontSize="small" />
          </IconButton>

          <Typography className='quantity-cart' gutterBottom variant="h6" component="div">
            {cantidad || 0}
          </Typography>

          <IconButton
            onClick={() => {
              onAddToCart(itemCode);
            }}
            sx={{ p: "10px" }}
            aria-label=""
            disableFocusRipple={true}
            style={{ color: "#000", paddingTop: "0", paddingBottom: "0" }}
            color="primary"
            size="small"
          >
            <AddIcon fontSize="small" />
          </IconButton>
        </Box>
      </Box>
    );
  };

  const handleAddToCart = (itemCode: string) => {
    let isAddToCart: boolean = false;

    const updatedCards = dataProducts.map((product: any) => {
      const cantidad = (product?.cantidad || 0) + 1;
      const addProduct = product.precio > 0 && ((product.precio * cantidad) <= poinstClient);

      if (product?.itemCode === itemCode && /* cantidad <= product.stock && */ addProduct) {
        isAddToCart = true;
        const quantityToAdd = cantidad;
        return { ...product, cantidad: quantityToAdd };
      }
      return product;
    });

    if (isAddToCart === false) {
      return AlertRef?.current?.open("No dispone de saldo suficiente.", "error");
    }

    setDataProducts(updatedCards);
    setQuantityTotal(updatedCards);
  };

  const handleRemoveFromCart = (itemCode: string) => {
    const updatedCards = dataProducts.map((product: any) => {
      if (product?.itemCode === itemCode && product.cantidad > 0) {
        const quantity = product.cantidad - 1;
        return { ...product, cantidad: quantity };
      }
      return product;
    });

    setDataProducts(updatedCards);
    setQuantityTotal(updatedCards);
  };

  const setQuantityTotal = (updatedCards: any) => {
    const updatedCardsCount = updatedCards
      .filter((product: any) => product.cantidad > 0)
      .map((product: any) => (product.cantidad || 0))
      .reduce((total: number, cantidad: number) => total + cantidad, 0);
    setQuantityInCart(updatedCardsCount);
  }

  const openCartList = () => {
    const productsInCart = dataProducts.filter((product) => product.cantidad > 0);
    setDataProductsInCart(productsInCart);
    setOpenDialogCart(true);

    if ((!openDialogCart) === false) {
      dispatch({ type: typesDialog.close });
    }
  };

  const itemsNivel = Array.from({ length: 5 }, (_, index) => index);

  return (
    <div className="container-reward" >
      <Box sx={{ flexGrow: 1 }}>
        <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }} style={{ justifyContent: "center", alignItems: "center" }}>
          <Grid item xs={3}>
            <h2 className='title-client'>!{i18n.HELLO} {clientName.trim()}!</h2>
          </Grid>

          <Grid item xs={7}>
            <Box className='box-bg-title' style={{ background: `url(${bg_title})`, backgroundPosition: "center", backgroundRepeat: "no-repeat", height: "100px", display: "flex", alignItems: "center", justifyContent: "center", backgroundSize: "32%" }}>
              <Typography variant="h6" style={{ lineHeight: "normal" }}>{i18n.HAVE} {poinstClient} {i18n.POINTS} <br />{i18n.ACCUMULATED}</Typography>
            </Box>
          </Grid>

          <Grid item xs={2}>
            <Box className='content-icon-cart'>
              <IconButton
                onClick={() => {
                  openCartList();
                }}
                sx={{ p: "10px" }}
                aria-label=""
                disableFocusRipple={true}
                color="primary"
              >
                <IconCart />
              </IconButton>

              <span className='label-quantity-cart'>{quantityInCart}</span>
            </Box>
          </Grid>
        </Grid>

        <Stack spacing={2} >
          <Box className='section-box-title'>
            <Typography variant="h5" style={{ fontWeight: "bold" }}>{i18n.PROMOTIONS_AVAILABLE}</Typography>
          </Box>

          {dataProducts.length && (
            <Box sx={{ pl: 4, pr: 4 }}>
              <Swiper
                modules={[Navigation, Pagination, Scrollbar, A11y]}
                spaceBetween={50}
                slidesPerView={3}
                navigation
                // pagination={{ clickable: true }}
                // scrollbar={{ draggable: true }}
                // onSwiper={(swiper) => console.log(swiper)}
                // onSlideChange={() => console.log('slide change')}
                breakpoints={{
                  "@0.00": {
                    slidesPerView: 2,
                    spaceBetween: 20
                  },
                  "@1.00": {
                    slidesPerView: 6,
                    spaceBetween: 20
                  }
                }}
              >
                {dataProducts.map((item: any, key: number) => {
                  return <SwiperSlide key={key}>
                    <CardProductSlider  {...item} onAddToCart={handleAddToCart} onRemoveFromCart={handleRemoveFromCart} key={key} />
                  </SwiperSlide>
                })}

              </Swiper>
            </Box>
          )}

          <Box className='section-box-title'>
            <Typography variant="h5" style={{ fontWeight: "bold" }}>{i18n.LEVELS}</Typography>
          </Box>

          <Box style={{ paddingTop: "1rem" }}>
            <Grid container spacing={2} alignItems={'center'} justifyContent={'center'}>
              {itemsNivel.map((level) => {
                const numberLevel: Number = (level + 1);
                const isLevelEnabled = numberLevel <= levelClient;

                return <Grid item xs={2} key={level} className='p--0'>
                  <Box className='box-content-trophy' >
                    <img src={img_trophy_lightning} alt='Trofeo' width={70} className={`${isLevelEnabled ? '' : 'nivel-disabled'}`} />
                    <span>{numberLevel}</span>
                  </Box>
                </Grid>
              })}
            </Grid>

            <img src={img_banner_niveles} alt='niveles' style={{ maxWidth: "70%" }} />

            <Box paddingTop={'2rem'} marginBottom={'1rem'}>
              <Grid container spacing={2} alignItems={'center'} justifyContent={'center'} className='container-items-level'>
                <Grid item xs={2} className='p-0'>
                  <Box>
                    <Typography className='level-title-small'>25 {i18n.ACTIVATIONS}</Typography>
                    <Typography className='level-title-small'>{i18n.ENERGY_LEVELS.INITIAL}</Typography>
                  </Box>
                </Grid>

                <Grid item xs={2} className='p-0'>
                  <Box>
                    <Typography className='level-title-small'>50 {i18n.ACTIVATIONS}</Typography>
                    <Typography className='level-title-small'>{i18n.ENERGY_LEVELS.RECHARGE}</Typography>
                  </Box>
                </Grid>

                <Grid item xs={2} className='p-0'>
                  <Box>
                    <Typography className='level-title-small'>100 {i18n.ACTIVATIONS}</Typography>
                    <Typography className='level-title-small'>{i18n.ENERGY_LEVELS.OPTIMAL}</Typography>
                  </Box>
                </Grid>

                <Grid item xs={2} className='p-0'>
                  <Box>
                    <Typography className='level-title-small'>200 {i18n.ACTIVATIONS}</Typography>
                    <Typography className='level-title-small'>{i18n.ENERGY_LEVELS.INTENSIVE}</Typography>
                  </Box>
                </Grid>

                <Grid item xs={2} className='p-0'>
                  <Box>
                    <Typography className='level-title-small'>300 {i18n.ACTIVATIONS}</Typography>
                    <Typography className='level-title-small'>{i18n.ENERGY_LEVELS.EXTREME}</Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>

          <Accordion className='container-accordion' defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2-content"
              id="panel2-header"
              className='section-box-title toggle-title'
            >
              <Typography variant="h5" style={{ fontWeight: "bold", textAlign: "center" }}>{i18n.GIFT_HISTORY}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <List
                className='container-history'
                sx={{
                  width: "100%",
                  bgcolor: "background.paper",
                  position: "relative",
                  overflow: "auto",
                  maxHeight: "90vh",
                  "& ul": { padding: 0 },
                }}>

                {dataGiftHistory?.length > 0 && dataGiftHistory?.flat().map((history, key) => {
                  return history?.detalle?.map((detail, key) => {
                    return <ListItem style={{ padding: "1rem 0", borderBottom: "2px solid #B1B1B1" }} key={key}>
                      <div className="list-container">
                        <Box display={'flex'} justifyContent={'space-between'} columnGap={'0.5rem'}>
                          <Box display={'flex'} columnGap={'1rem'}>
                            <ProductCard data={{ itemName: detail.nombre, urlImage: detail.imagen }} height={'70'} size='small' visibleLabel={false} visibleFooter={false} />
                            <Box style={{ flex: "1" }}>
                              <Typography style={{ fontWeight: "600", color: "#6A7276", fontSize: "0.8rem" }}>{detail.cantidad} {detail.nombre}</Typography>
                              <Typography style={{ fontWeight: "600", color: "#6A7276", fontSize: "0.8rem" }}>{moment(history.fecha).format("DD/MM/yyyy HH:mm A")}</Typography>
                            </Box>
                          </Box>
                          <Box>
                            <Typography style={{ fontWeight: "600", fontSize: "0.8rem" }}>{detail.precio} Pts.</Typography>
                            {/* <Typography style={{ fontWeight: "600", fontSize: "0.8rem", textAlign: "center" }}>{detail.cantidad} unid.</Typography> */}
                          </Box>

                        </Box>
                      </div>
                    </ListItem>
                  })
                })}

                {loading && <div>Loading...</div>}
                <div ref={bottomBoundaryRef}></div>

              </List>
            </AccordionDetails>
          </Accordion>

        </Stack>
      </Box>

      {/* store.active_dialog === ACTIVE_DIALOG */ openDialogCart === true && (
        <Dialog title="Tu carrito" open closeCallback={openCartList}>
          <RewardCartList productsInCart={dataProductsInCart} quantityInCart={quantityInCart} poinstClient={poinstClient} closeCallback={openCartList} />
        </Dialog>
      )}

      <Alert ref={AlertRef} />

    </div>
  );
};

