import * as React from "react";
// import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import Box from "@mui/material/Box";

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { IconLabelCart } from '../icons/IconLabelCart';

interface ProductCardProps {
  data: any;
  height: string;
  visibleLabel: Boolean;
  size?: string;
  visibleFooter?: Boolean;
}

export const ProductCard = (props: ProductCardProps) => {
  return (
    <Card className={`card-product ${props.size}`} sx={{ maxWidth: 345, position: "relative" }}>
      <CardMedia
        component="img"
        alt="Producto"
        height={props.height}
        image={props.data.urlImage}
        className='card-image'
      />
      {props.visibleLabel === true && (
        <Box className="content-label-price">
          <IconLabelCart />
          <Typography className="price">
            PRECIO UNIT.
          </Typography>
          <Typography className="price">
            PEN. {props.data.precio}
          </Typography>
        </Box>
      )}
      {props.visibleFooter !== false && (
        <CardContent className='card-title'>
        <Typography className="product-name" gutterBottom variant="h6" component="div">
          {props.data.itemName}
        </Typography>
      </CardContent>
      )}
      
    </Card>
  );
};