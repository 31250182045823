import * as React from "react";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { StoreContext } from '../../store/StoreProvider';

interface BannerModalProps {
    open?: boolean;
    imgstatic?: boolean;
    image?: string;
    onClose?: () => void;
}
export const BannerModal = (props: BannerModalProps) => {
    const [store, dispatch] = React.useContext(StoreContext);
    const [open, setOpen] = React.useState(props.open ?? false);

    return (
        <div style={{}}>
            <Dialog fullWidth={true} open={open} onClose={() => console.log('')} className={"modal-banner-custom"}>

                <DialogContent style={{ textAlign: "center", padding: "0", lineHeight: "0" }}>
                    <IconButton
                        aria-label="close"
                        size="small"
                        onClick={() => {
                            if (!!props?.onClose) {
                                props.onClose();
                            }

                            setOpen(false);
                        }}
                        sx={{
                            position: 'absolute',
                            right: 9, top: 8,
                            // color: (theme) => theme.palette.grey[500],
                            // color: (theme) => theme.palette.grey[500],
                            background: "#FFFFFF!important",
                            color: "#000",
                            // fontSize: "0.5rem"
                            // width: "0.5rem"
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <img src={props.imgstatic ? store?.user?.url_banner : props.image} alt="ModalImg" style={{ maxWidth: '100%', maxHeight: '100%', borderRadius: "0.5rem" }} />
                </DialogContent>
            </Dialog>
        </div>
    );
};
