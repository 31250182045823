import React from 'react';
import { add_warranty_icon } from '../../assets/Media';

export function AddWarranty() {
  return (
    <>
      <img src={add_warranty_icon} alt="Nueva garantia" className="icon icon-new-warranti" />
    </>
  );
}

