import * as React from "react";
import { StoreContext } from "../../store/StoreProvider";
import { types as typesDialog } from "../../store/Dialog";
import { types } from "../../store/Warranty";
import { Dialog } from "./Dialog";
import Card from "@mui/material/Card";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { ClientList } from "./ClientList";

import IconButton from "@mui/material/IconButton";
import LogoutIcon from "@mui/icons-material/Logout";
import { types as typesAuth } from "../../store/Auth";
import AppConfig from "../../constants/AppConfig";
import { getI18N } from '../../i18n'

const {
  types: { seller, partner },
  colors: { primary },
} = AppConfig;

interface ClientCardProps {
  open?: any;
  callback?: any;
}

const ACTIVE_DIALOG = "client";

export const ClientCard = (props: ClientCardProps) => {
  const classes = useStyles();
  const [store, dispatch] = React.useContext(StoreContext);

  const { Country } = store?.user;
  const currentLocale: string = Country || "PE";
  const i18n = getI18N({ currentLocale })

  const open = () => {
    if (!!props.callback) {
      props.callback();
      return;
    }

    if (!store.dialog) {
      dispatch({ type: typesDialog.open, payload: ACTIVE_DIALOG });
    } else {
      dispatch({ type: typesDialog.close });
    }

    // dispatch({ type: types.setUpdateVersionApp, payload: true });
  };

  const logoutUser = () => {
    if (store?.user?.tipo === seller) {
      dispatch({ type: typesAuth.authLogoutRV });
    } else if (store?.user?.tipo === partner) {
      dispatch({ type: typesAuth.authLogoutSN });
    } else {
      dispatch({ type: typesAuth.authLogout });
    }

    // dispatch({ type: types.setUpdateVersionApp, payload: true });
  };

  React.useEffect(() => {
    if (props?.open) {
      open();
    }

  }, []);

  return (
    <>
      <Card className={classes.title} onClick={open}>
        <Typography style={{ fontSize: 16 }} variant="h6" align="center">
          {!!store?.selected_client?.RzSocial
            ? store?.selected_client?.RzSocial
            : `Seleccionar cliente`}
        </Typography>
      </Card>
      {store.active_dialog === ACTIVE_DIALOG && (
        <Dialog
          title={i18n.CUSTOMERS}
          hiddenClose={true}
          closeCallback={open}
          right={
            <IconButton
              edge="start"
              onClick={logoutUser}
              aria-label="close"
              style={{ color: '#000' }}
            >
              <LogoutIcon sx={{ color: "#000" }} />
            </IconButton>
          }
        >
          <ClientList />
        </Dialog>
      )}
    </>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
    drawer: {
      width: 300,
    },
    fullList: {
      width: "auto",
    },
  })
);
