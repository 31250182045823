import * as React from "react";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import Button from "@material-ui/core/Button";
import { StoreContext } from "../../store/StoreProvider";
import { types } from "../../store/Warranty";
import { types as typesDialog } from "../../store/Dialog";
import { Dialog } from "../App/Dialog";
import { ClientCard } from "../App/ClientCard";
import { NewBattery } from "./NewBattery";
import { ClientList } from "../App/ClientList";
import { NewBatteryForm } from "./NewBatteryForm";
import { Bottom } from "../App/Bottom";

import { NewWarranty } from "./NewWarranty";
import { Camera } from "../App/Camera";
import Typography from "@mui/material/Typography";
import AppConfig from "../../constants/AppConfig";

import {
  clientAddress,
  CombustionV,
  getWarranty,
  getWarrantyById,
  MarModeloV,
  TipoUsoV,
  WarrantyRequestParams,
  GetWarrantyRequest,
} from "../../store/sagas/Warranty";
import { Alert } from "../Alert";
import Loading from "../Loading";
import { types as typesWarranty } from "../../store/Warranty";
import { getWarrantyRequest } from "../../apis/warranty";
import { BatteryListModal } from "./BatteryListModal";
import { AddWarranty } from "../icons/AddWarranty";
import { getI18N } from '../../i18n'

interface TabTwoProps {
  callback?: any;
  type?: string;
  home?: boolean;
}

const { validTypes } = AppConfig;

const ACTIVE_DIALOG = "new_code";

export const TabTwo = (props: TabTwoProps) => {
  const [store, dispatch] = React.useContext(StoreContext);
  const [setClient, setClientToggle] = React.useState(false);
  const [loading, setLoad] = React.useState(false);
  const [loadingPlate, setLoadPlate] = React.useState(false);
  const [loadingDoc, setLoadDoc] = React.useState(false);
  const [plateCode, setPlate] = React.useState("");
  const [documentCode, setDoc] = React.useState("");
  const [batteryList, setBatteryList] = React.useState([]);
  const [showBatteryList, setShowBatteryList] = React.useState(false);
  const AlertRef = React.useRef<any>();

  const { Country } = store?.user;
  const currentLocale: string = Country || "PE";
  const i18n = getI18N({ currentLocale })

  const open = (clear: any = false) => {
    if (!!props.callback) {
      setLoad(true);

      if (store?.userAppCorp) {
        const dataFormWarranty = [
          {
            "DescCab": "Solicitud Garantía CAB",
            "DescDet": "Voltaje",
            "Estado": "",
            // "FecReg": "2020-07-05T00:22:20.0530000",
            // "FecUpd": "2020-07-05T00:22:20.0530000",
            "FlgObligatorio": true,
            "IdCab": 4,
            "IdDet": 1,
            "Maximo": 10,
            "Minimo": 0,
            "PlaceHolder": "Voltaje",
            "Tipo": "DEC",
            "UserReg": "",
            "Valor": ""
          },
          {
            "DescCab": "Solicitud Garantía CAB",
            "DescDet": "CCA",
            "Estado": "",
            // "FecReg": "2020-07-05T00:22:20.0900000",
            // "FecUpd": "2020-07-05T00:22:20.0900000",
            "FlgObligatorio": true,
            "IdCab": 4,
            "IdDet": 3,
            "Maximo": 10,
            "Minimo": 0,
            "PlaceHolder": "CCA",
            "Tipo": "INT",
            "UserReg": "",
            "Valor": ""
          },
          {
            "DescCab": "Solicitud Garantía CAB",
            "DescDet": "Densidad",
            "Estado": "",
            // "FecReg": "2020-12-12T03:29:05.1130000",
            // "FecUpd": "2020-12-12T03:29:05.1130000",
            "FlgObligatorio": false,
            "IdCab": 4,
            "IdDet": 5,
            "Maximo": 10,
            "Minimo": 0,
            "PlaceHolder": "Densidad",
            "Tipo": "DEC",
            "UserReg": "",
            "Valor": ""
          },
          {
            "DescCab": "Solicitud Garantía CAB",
            "DescDet": "Observaciones",
            "Estado": "",
            // "FecReg": "2020-07-05T00:22:20.1530000",
            // "FecUpd": "2020-07-05T00:22:20.1530000",
            "FlgObligatorio": false,
            "IdCab": 4,
            "IdDet": 6,
            "Maximo": 200,
            "Minimo": 0,
            "PlaceHolder": "Observaciones",
            "Tipo": "TXTA",
            "UserReg": "",
            "Valor": ""
          },
          // {
          //   "DescCab": "Solicitud Garantía CAB",
          //   "DescDet": "Imagen",
          //   "Estado": "",
          //   "FecReg": "2020-11-26T08:38:13.1930000",
          //   "FecUpd": "2020-11-26T08:38:13.1930000",
          //   "FlgObligatorio": false,
          //   "IdCab": 4,
          //   "IdDet": 7,
          //   "Maximo": 0,
          //   "Minimo": 0,
          //   "PlaceHolder": "Imagen",
          //   "Tipo": "IMG",
          //   "UserReg": "",
          //   "Valor": ""
          // }
        ];
        setLoad(false);
        dispatch({
          type: typesWarranty.setDataFormWarranty,
          payload: dataFormWarranty,
        });
        props.callback();
        return;
      }

      WarrantyRequestParams({OrigenBat: store?.user?.Country})
        .then((dataFormWarranty: any) => {
          setLoad(false);
          dispatch({
            type: typesWarranty.setDataFormWarranty,
            payload: dataFormWarranty,
          });
          props.callback();
        })
        .catch((error) => {
          setLoad(false);
          AlertRef?.current?.open("Error en el servidor", "error");
          return;
        });
      return;
    }

    if (!store.dialog) {

      let obj = {
        idTarjeta: -1,
        qrCode: "N",
        placa: "",
        ruc: "",
        cardCode: "",
        // origenBat: store?.user?.Country == "PE" ? "IMP" : store?.user?.Country,
        origenBat: store?.user?.Country,
      };

      setLoad(true);

      getWarranty(obj)
        .then((dataWarranty) => {
          new Promise(function (resolve, reject) {
            CombustionV({ country: store?.user?.Country }).then((dataComb) => {
              MarModeloV().then((data) => {
                let brands: any = [];
                let models: any = [];
                let dataMarMod = data.filter((d: any) => {
                  let res;
                  if (brands.indexOf(d.DESMARCA) === -1) {
                    brands.push(d.DESMARCA);
                    res = {
                      CODMARCA: d.CODMARCA,
                      DESMARCA: d.DESMARCA,
                    };
                  }
                  return res;
                });

                let dataMod = data.filter((d: any) => {
                  let res;
                  if (models.indexOf(d.DESMODELO) === -1) {
                    models.push(d.DESMODELO);
                    res = {
                      CODMODELO: d.CODMODELO,
                      DESMODELO: d.DESMODELO,
                    };
                  }
                  return res;
                });

                TipoUsoV({ country: store?.user?.Country }).then((dataTipo) => {
                  clientAddress({
                    key: store?.selected_client?.Id,
                  }).then((address) => {
                    let obj = {
                      clientAddress: address,
                      selectData: {
                        CombustionV: dataComb,
                        MarModeloV: dataMarMod,
                        ModeloV: dataMod,
                        TipoUsoV: dataTipo,
                      },
                      dataWarranty: dataWarranty[0],
                    };
                    resolve(dispatch({ type: types.setBattery, payload: obj }));
                  });
                });
              });
            });
          }).then(() => {
            setTimeout(() => {
              setLoad(false);
              dispatch({ type: typesDialog.open, payload: ACTIVE_DIALOG });
            }, 500);
          });
        })
        .catch((error) => {
          setLoad(false);
          AlertRef?.current?.open(
            "No es posible conectar con el servidor en este momento",
            "error"
          );
        });
    } else {
      dispatch({ type: typesDialog.close });
    }
  };

  const toggleClient = () => {
    setClientToggle(!setClient);
  };

  const findById = (type: string = "") => {
    let obj = {
      qrCode: "",
      placa: plateCode,
      ruc: documentCode,
      CardCode: "",
      // origenBat: store?.user?.Country == "PE" ? "IMP" : store?.user?.Country,
      origenBat: store?.user?.Country,
    };

    if (!!type) {
      if (type === "plate") {
        setLoadPlate(true);
        obj.ruc = "";
      }
      if (type === "document") {
        setLoadDoc(true);
        obj.placa = "";
      }
    } else {
      setLoad(true);
    }

    if (props?.type === "SC") {
      WarrantyRequestParams({OrigenBat: store?.user?.Country})
        .then((dataFormWarranty: any) => {
          getWarrantyById(obj)
            .then((data) => {
              if (!!data.length) {
                let {
                  CodBateria,
                  Documento,
                  IdTarjetaGarantia,
                  Marca,
                  Modelo,
                  Placa,
                  TieneTarjeta,
                  TipoDoc,
                } = data[0];
                let obj = {
                  idTarjeta: IdTarjetaGarantia,
                  qrCode: CodBateria,
                  placa: "",
                  ruc: "",
                  cardCode: Documento,
                  origenBat:
                    // store?.user?.Country == "PE" ? "IMP" : store?.user?.Country,
                    store?.user?.Country,
                };
                getWarranty(obj).then((dataWarranty) => {
                  let res = {
                    battery: dataWarranty[0],
                    warrantyFront: dataFormWarranty,
                  };
                  setLoad(false);
                  setLoadPlate(false);
                  setLoadDoc(false);
                  dispatch({
                    type: types.setWarrantyData,
                    payload: res,
                  });
                  if (!!props.callback) {
                    props.callback();
                    return;
                  }
                });
              }
            })
            .catch((error) => {
              setLoad(false);
              setLoadPlate(false);
              setLoadDoc(false);
              AlertRef?.current?.open("Codigo no encontrado", "error");
            });
        })
        .catch((error) => {
          setLoad(false);
          setLoadPlate(false);
          setLoadDoc(false);
          AlertRef?.current?.open("Error en el servidor", "error");
        });
    } else {
      getWarrantyById(obj)
        .then((data) => {
          if (!!data.length) {
            if (data.length === 1) {
              setBattery(data[0]);
            } else {
              setLoad(false);
              setLoadPlate(false);
              setLoadDoc(false);
              setBatteryList(data);
              setShowBatteryList(true);
            }
          }
        })
        .catch((error) => {
          setLoad(false);
          setLoadPlate(false);
          setLoadDoc(false);
          AlertRef?.current?.open("Codigo no encontrado", "error");
        });
    }
  };

  const setBattery = (obj: any) => {
    let objReq = {
      idTarjeta: obj?.IdTarjetaGarantia,
      qrCode: obj?.CodBateria,
      placa: "",
      ruc: "",
      cardCode: obj?.Documento,
      // origenBat: store?.user?.Country === "PE" ? "IMP" : store?.user?.Country,
      origenBat: store?.user?.Country,
    };

    getWarranty(objReq).then((dataWarranty: any) => {
      if (!dataWarranty.length) {
        AlertRef?.current?.open("Error en el servidor", "error");
      } else {
        new Promise(function (resolve, reject) {
          CombustionV({ country: store?.user?.Country }).then((dataComb) => {
            MarModeloV().then((data) => {
              let brands: any = [];
              let models: any = [];
              let dataMarMod = data.filter((d: any) => {
                let res;
                if (brands.indexOf(d.DESMARCA) === -1) {
                  brands.push(d.DESMARCA);
                  res = {
                    CODMARCA: d.CODMARCA,
                    DESMARCA: d.DESMARCA,
                  };
                }
                return res;
              });

              let dataMod = data.filter((d: any) => {
                let res;
                if (models.indexOf(d.DESMODELO) === -1) {
                  models.push(d.DESMODELO);
                  res = {
                    CODMODELO: d.CODMODELO,
                    DESMODELO: d.DESMODELO,
                  };
                }
                return res;
              });

              TipoUsoV({ country: store?.user?.Country }).then((dataTipo) => {
                clientAddress({
                  key: store?.selected_client?.Id,
                }).then((address) => {
                  let obj = {
                    clientAddress: address,
                    selectData: {
                      CombustionV: dataComb,
                      MarModeloV: dataMarMod,
                      ModeloV: dataMod,
                      TipoUsoV: dataTipo,
                    },
                    dataWarranty: dataWarranty[0],
                  };
                  resolve(dispatch({ type: types.setBattery, payload: obj }));
                });
              });
            });
          });
        }).then(() => {
          setTimeout(() => {
            setLoad(false);
            dispatch({
              type: typesDialog.open,
              payload: ACTIVE_DIALOG,
            });
          }, 500);
        });
      }
    });
  };

  const showWarranty = () => {
    if (store?.battery?.Id === -1) {
      AlertRef?.current?.open("Debe registrar primero la bateria", "error");
    } else {
      let obj = {
        idTarjeta: store?.battery?.Id,
        idMiniCod: store?.battery?.Id,
        cardCode: store?.battery?.CardCode,
      };

      WarrantyRequestParams().then((warrantyFront) => {
        GetWarrantyRequest(obj).then((warrantyData) => {
          if (!!warrantyData.length) {
            dispatch({ type: types.setWarrantyDetail, payload: warrantyData[0] });
          } else {
            dispatch({ type: types.setViewWarranty, payload: warrantyFront });
          }
        });
      });
    }
  };

  const SwitchView = () => {
    switch (store?.openView) {
      case validTypes.client:
        return <ClientList callback={toggleClient} />;
        break;
      case validTypes.warranty:
        return <NewWarranty callback={() => console.log()} />;
        break;
      case validTypes.warrantyDetail:
        return <NewWarranty callback={() => console.log()} />;
        break;
      case validTypes.battery:
        return <NewBatteryForm callback={open} />;
        break;

      default:
        return <NewBatteryForm callback={open} />;
        break;
    }
  };

  const RenderIcon = () => {
    switch (store?.openView) {
      case validTypes.battery:
        return (
          <IconButton
            style={{ color: "#000" }}
            sx={{ p: "10px" }}
            aria-label="Nueva garantia"
            onClick={showWarranty}
          >
            <AddWarranty />
          </IconButton>
        );
        break;
      case validTypes.warranty:
        return <Camera />;
        break;
      case validTypes.warrantyDetail:
        return <></>;
        break;

      default:
        return (
          <IconButton
            style={{ color: "#000" }}
            sx={{ p: "10px" }}
            aria-label="Nueva garantia"
            onClick={showWarranty}
          >
            <AddWarranty />
          </IconButton>
        );
        break;
    }
  };

  const RenderTitle = () => {
    let title: any;

    switch (store?.openView) {
      case validTypes.battery:
        title = store?.battery?.CardName || (
          <ClientCard callback={toggleClient} />
        );
        break;
      case validTypes.warranty:
        title = "Solicitud de garantia";
        break;
      case validTypes.warrantyDetail:
        title = "Detalle de solicitud de garantia";
        break;

      default:
        title = store?.battery?.CardName || (
          <ClientCard callback={toggleClient} />
        );
        break;
    }

    if (typeof title !== "string") {
      return <>{title}</>;
    } else {
      return (
        <Typography
          className="cardName-title"
          sx={{ ml: 2, flex: 1 }}
          variant="h6"
          component="div"
          align="center"
          style={{ color: "#000" }}
        >
          {title}
        </Typography>
      );
    }
  };



  return (
    <div className="tab-one-container">
      <Alert ref={AlertRef} />

      {!!props?.home && (
        <>
          <div className="input-container">
            <Paper
              component="form"
              sx={{
                p: "2px 4px",
                display: "flex",
                alignItems: "center",
                width: "100%",
              }}
            >
              <InputBase
                className="uppercase"
                onChange={(event) => setPlate(event.target.value)}
                sx={{ ml: 1, flex: 1 }}
                placeholder={i18n.PLATE}
                inputProps={{ "aria-label": "Placa" }}
              />
              <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
              <IconButton
                color="primary"
                sx={{ p: "10px" }}
                aria-label="Buscar código"
                onClick={() => findById("plate")}
              >
                <Loading loading={loadingPlate}>
                  <SearchIcon />
                </Loading>
              </IconButton>
            </Paper>
          </div>
          <div className="input-container">
            <Paper
              component="form"
              sx={{
                p: "2px 4px",
                display: "flex",
                alignItems: "center",
                width: "100%",
              }}
            >
              <InputBase
                className="uppercase"
                onChange={(event) => setDoc(event.target.value)}
                sx={{ ml: 1, flex: 1 }}
                placeholder="DNI / RUC"
                inputProps={{ "aria-label": "DNI / RUC" }}
                type="number"
              />
              <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
              <IconButton
                color="primary"
                sx={{ p: "10px" }}
                aria-label="Buscar código"
                onClick={() => findById("document")}
              >
                <Loading loading={loadingDoc}>
                  <SearchIcon />
                </Loading>
              </IconButton>
            </Paper>
          </div>
          <Divider sx={{ height: 28, mt: 1, mb: 1 }} orientation="horizontal" />
        </>
      )}
      {/* <Button
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        onClick={() => open(true)}
        disabled={loading}
      >
        <Loading loading={loading}>
          <strong>Nuevo</strong>
        </Loading>
      </Button> */}
      <Button
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        onClick={() => open(true)}
        disabled={loading}
        style={{ borderRadius: "20px", marginTop: "1rem", marginBottom: "1rem" }}
      >
        <Loading loading={loading}>
          <strong>{i18n.NO_BATTERY_CODE}</strong>
        </Loading>
      </Button>

      <BatteryListModal
        open={showBatteryList}
        handleClose={() => {
          setShowBatteryList(false);
        }}
        batteryList={batteryList}
        callback={setBattery}
        // type={store?.user?.Country === "PE" ? "IMP" : store?.user?.Country}
        type={store?.user?.Country}
      />

      {store.active_dialog === ACTIVE_DIALOG && (
        <Dialog
          closeCallback={open}
          title={<RenderTitle />}
          right={<RenderIcon />}
        >
          <SwitchView />
        </Dialog>
      )}
      {
        /*store.openCreateWarrantyRequest !== true &&*/ props?.type !== "SS" && (
          <Bottom />
        )
      }

    </div>
  );
};
