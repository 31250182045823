import * as React from 'react';
import Box from "@mui/material/Box";
import { StoreContext } from '../../store/StoreProvider';
import { Typography } from "@material-ui/core";
import { Stack } from '@mui/material';
import IconButton from "@mui/material/IconButton";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import { ProductCard } from "../App/ProductCard";
import { Button } from "@material-ui/core";
import { clientOrderAddExt } from "../../store/sagas/Clients";
import { IconTrash } from "../icons/IconTrash";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ClientAddressModal } from "./ClientAddressModal";
import { clientAddressExt } from "../../store/sagas/Clients";
import { BannerModal } from "./BannerModal";
import { banner_purcahse } from "../../assets/Media";
import { Alert } from "../Alert";
import { getI18N } from '../../i18n'

interface RewardCartListProps {
  poinstClient: number;
  productsInCart: any,
  quantityInCart: number,
  callback?: any;
  closeCallback?: any;
}

export const RewardCartList = (props: RewardCartListProps) => {

  interface Product {
    itemCode: string;
    itemName: string;
    urlImage: string;
    precio: number;
    cantidad: number;
    stock: number;
    linea: number;
    onAddToCart: (itemCode: string) => void;
    onRemoveFromCart: (itemCode: string) => void;
    onRemoveItemCart: (itemCode: string) => void;
  }

  const [store, dispatch] = React.useContext(StoreContext);
  const [dataProducts, setDataProducts] = React.useState<Product[]>(props?.productsInCart ?? []);
  const [quantityInCart, setQuantityInCart] = React.useState<number>(props?.quantityInCart ?? 0);
  const [objectAddress, setObjectAddress] = React.useState<any>(null);
  const [openAddressSucursal, setOpenAddressSucursal] = React.useState(false);
  const [dataDirections, setDataDirections] = React.useState<any>();

  const [loadDirections, setLoadDirections] = React.useState<boolean>(false);
  const [openModalSuccess, setOpenModalSuccess] = React.useState(false);
  const AlertRef = React.useRef<any>();
  const [load, setLoad] = React.useState<boolean>(false);

  const { Country } = store?.user;
  const currentLocale: string = Country || "PE";
  const i18n = getI18N({ currentLocale })

  const clientId: string = store?.user?.user_login/* store?.selected_client?.UserId */;
  // const clientNames: string = store?.selected_client?.Name;
  const cardCodeParent: string = store?.user?.cardCode2 ?? store?.user.id;
  // const cardCodeParent: string = (clientId?.slice(0, 2) === "AC" || clientId?.slice(0, 1) === "A") ? store?.user.id : clientId;
  
  const updateAddressSucursal = (address: any) => {
    setOpenAddressSucursal(!openAddressSucursal);
    return setObjectAddress(address);
  }

  const CardProductList = ({ itemCode, urlImage, precio, itemName, cantidad, onRemoveFromCart, onAddToCart }: Product) => {
    return (
      <Box display={'flex'} columnGap={'1rem'} width={'400px'}>
        <Box >
          <ProductCard data={{ itemCode, urlImage, precio, itemName, cantidad }} visibleLabel={false} height={'70'} size='small' />
        </Box>

        <Box flex={1}>
          <Typography style={{ fontWeight: "600", color: "#6A7276", fontSize: "0.8rem" }}>{itemName}</Typography>

          <Box display={'flex'} alignItems={'center'} marginTop={'1rem'}>
            <Box className='content-actions-cart' style={{ marginTop: 0 }}>
              <IconButton
                onClick={() => {
                  onRemoveFromCart(itemCode);
                }}
                sx={{ p: "10px" }}
                aria-label=""
                disableFocusRipple={true}
                style={{ color: "#000", paddingTop: "0", paddingBottom: "0" }}
                color="primary"
                size="small"
              >
                <RemoveIcon fontSize="small" />
              </IconButton>

              <Typography className='quantity-cart' gutterBottom variant="h6" component="div">
                {cantidad}
              </Typography>

              <IconButton
                onClick={() => {
                  onAddToCart(itemCode);
                }}
                sx={{ p: "10px" }}
                aria-label=""
                disableFocusRipple={true}
                style={{ color: "#000", paddingTop: "0", paddingBottom: "0" }}
                color="primary"
                size="small"
              >
                <AddIcon fontSize="small" />
              </IconButton>
            </Box>
            <IconButton
              onClick={() => {
                onRemoveItemCart(itemCode);
              }}
              sx={{ p: "10px" }}
              aria-label=""
              disableFocusRipple={true}
              style={{ color: "#000" }}
              color="primary"
              size="small"
            >
              <IconTrash />
            </IconButton>
          </Box>

          <Typography style={{ fontWeight: "600", fontSize: "0.8rem", textAlign: "right" }}>{precio} Pts.</Typography>
        </Box>
      </Box>
    );
  };

  const handleAddToCart = (itemCode: number) => {
    const updatedCards = dataProducts.map((product: any) => {
      const cantidad = (product?.cantidad || 0) + 1;
      const addProduct = product.precio > 0 && ((product.precio * cantidad) <= props.poinstClient);

      if (product?.itemCode === itemCode && /* cantidad <= product.stock && */ addProduct) {
        const quantityToAdd = cantidad;
        return { ...product, cantidad: quantityToAdd };
      }
      return product;
    });

    setDataProducts(updatedCards);
    setQuantityTotal(updatedCards);
  };

  const handleRemoveFromCart = (itemCode: number) => {
    const updatedCards = dataProducts.map((product: any) => {
      if (product?.itemCode === itemCode && product.cantidad > 0) {
        const quantityInCart = product.cantidad - 1;
        return { ...product, cantidad: quantityInCart };
      }
      return product;
    }).filter((product: any) => product.cantidad > 0);

    setDataProducts(updatedCards);
    setQuantityTotal(updatedCards);
  };

  const onRemoveItemCart = (itemCode: string) => {
    const updatedCards = dataProducts.filter((product: any) => product.itemCode !== itemCode);
    setDataProducts(updatedCards);
    setQuantityTotal(updatedCards);
  }

  const setQuantityTotal = (updatedCards: any) => {
    const updatedCardsCount = updatedCards
      .filter((product: any) => product.cantidad > 0)
      .map((product: any) => (product.cantidad || 0))
      .reduce((total: number, cantidad: number) => total + cantidad, 0);
    setQuantityInCart(updatedCardsCount);
  }

  const handleAddOrder = () => {

    if (!objectAddress) {
      return AlertRef?.current?.open("Seleccione una dirección", "error");
    }
    setLoad(true);

    const productsInCart = dataProducts
      .filter((product) => product.cantidad > 0)
      .map((product) => {
        return {
          "code": product.itemCode,
          "name": product.itemName,
          "cantidad": product.cantidad,
          "precio": product.precio,
          "linea": product.linea
        }
      });

    if (productsInCart.length <= 0) return;

    const dataRequest = {
      "cardCodePadre": cardCodeParent,
      "cardCode": clientId,
      "cardCodeName": store?.user?.name,
      "moneda": "S/",
      "almacen": "1010",
      "direccionDespacho": objectAddress.direccion,
      "direccionDespachoId": objectAddress.id,
      "items": productsInCart
    }

    clientOrderAddExt(dataRequest).then((response: any) => {
      setLoad(false);
      if (response.status === 'ok') {
        setOpenModalSuccess(true);
      }
      else {
        return AlertRef?.current?.open("Ocurrio un error al generar el pedido", "error");
      }
    })
  }

  const handleModalClose = () => {
    setOpenAddressSucursal(false);
  }

  const clearProductsInCart = () => {
    // setDataProducts([]);
    // setQuantityTotal([]);
    // setObjectAddress(null);
    props?.closeCallback();
  }

  const handleModalDirection = () => {
    setLoadDirections(true);

    const dataRequestHistory = {
      "cardCodePadre": cardCodeParent,
      "cardCode": store?.selected_client?.Id,
      "almacen": "1010",
    }

    clientAddressExt(dataRequestHistory).then((response) => {
      setLoadDirections(false);
      if (response.status === 99) {
        setDataDirections(response.data);
        setOpenAddressSucursal(true);
      }
      else {
        return AlertRef?.current?.open("Ocurrio un error al listar las direcciones", "error");
      }
    })
  }

  return (
    <div className="container-reward-cart" >
      <Box sx={{ flexGrow: 1 }}>
        <Stack spacing={2} >
          <Box className='section-box-title'>
            <Typography variant="h5" style={{ fontWeight: "bold" }}>{i18n.YOUR_CART} ({quantityInCart})</Typography>
          </Box>

          <Box>
            <List
              sx={{
                width: "100%",
                bgcolor: "background.paper",
                position: "relative",
                overflow: "auto",
                maxHeight: "90vh",
                "& ul": { padding: 0 },
              }}>
              {dataProducts.length > 0 && dataProducts.map((product: any, key: number) => {
                return <Box key={key}>
                  <ListItem style={{ padding: "1.5rem", justifyContent: "center" }}>
                    <CardProductList {...product} onAddToCart={handleAddToCart} onRemoveFromCart={handleRemoveFromCart} key={key} />
                  </ListItem>
                  <Divider />
                </Box>
              })}
            </List>
          </Box>

          <Box sx={{ pl: 1, pr: 1 }} textAlign={'center'}>
            <Button variant="outlined" disabled={loadDirections || quantityInCart <= 0} onClick={handleModalDirection}>{objectAddress?.direccion ?? `${i18n.SELECT_AN_ADDRESS}`}<ExpandMoreIcon /></Button>
          </Box>

          <Box sx={{ pl: 1, pr: 1 }} textAlign={'center'} >
            <Button
              variant="contained"
              className="btn-primary rounded-button"
              color="primary"
              style={{ borderRadius: "20px", fontWeight: "bold", width: "50%" }}
              onClick={handleAddOrder}
              disabled={(quantityInCart <= 0 && objectAddress?.id !== null) || load}
            >
              {i18n.MAKE_AN_ORDER}
            </Button>
          </Box>

        </Stack>
      </Box>

      {openAddressSucursal === true && (
        <ClientAddressModal dataDirections={dataDirections} callback={handleModalDirection} handleModalClose={handleModalClose} openModalSucursal={openAddressSucursal} onUpdateAddress={updateAddressSucursal} />
      )}

      {openModalSuccess === true && (<BannerModal open imgstatic={false} image={banner_purcahse} onClose={clearProductsInCart} />)}
      <Alert ref={AlertRef} />
    </div>
  );
};

