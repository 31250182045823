import {loginRequest,updateRequest, validateCodeRequest, loginCorporativoRequest} from '../../apis/auth';

export const loginUser = (payload) => {
  return new Promise((resolve, reject) => {
    try {
      loginRequest(payload).then((res) => {
        if (res.status === 200) {
          resolve(res.json());
        } else {
        }
      });
    } catch (error) {
      console.log(error);
      reject(error);
    }
  });
};

export const validateCode = (payload) => {
  return new Promise((resolve, reject) => {
    try {
      validateCodeRequest(payload).then((res) => {
        if (res.status === 200) {
          resolve(res.data);
        } else {
          reject(res.data);
        }
      });
    } catch (error) {
      console.log(error);
      reject(error);
    }
  });
};



export const updateUser = (payload) => {
  return new Promise((resolve, reject) => {
    try {
      updateRequest(payload).then((res) => {
        if (res.status === 200) {
          console.log(res);
          resolve(res.data);
        } else {
        }
      });
    } catch (error) {
      console.log(error);
      reject(error);
    }
  });
};

// login user corporativo
export const loginUserCorporativo = (payload) => {
  return new Promise((resolve, reject) => {
    try {
      loginCorporativoRequest(payload).then((res) => {
        if (res.status === 200) {
          resolve(res.json());
        } else {
        }
      });
    } catch (error) {
      console.log(error);
      reject(error);
    }
  });
};