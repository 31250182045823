import React from "react";
import { Home } from "./components/Home";
import { Login } from "./components/Login/Login";
import AppConfig from "./constants/AppConfig";

const {url: {seller, partner}} = AppConfig

const Routes = [
  {
    path: `"/login/${seller}`,
    sidebarName: `Login-${seller}`,
    component: Login,
  },
  {
    path: `/login/${partner}`,
    sidebarName: `Login-${partner}`,
    component: Login,
  },
  {
    path: `/login`,
    sidebarName: `Login`,
    component: Login,
  },
  {
    path: "/",
    sidebarName: "Home",
    component: Home,
  },
  {
    path: "/home",
    sidebarName: "Home",
    component: Home,
  },
  {
    path: "/activaciones",
    sidebarName: "Activaciones",
    component: Home,
  },
  {
    path: "/contactanos",
    sidebarName: "Contáctanos",
    component: Home,
  },
  {
    path: "/recompensas",
    sidebarName: "Recompensas",
    component: Home,
  },
];

export default Routes;
