import * as React from "react";
import { create } from "apisauce";
import { v4 as uuidv4 } from 'uuid';

const BASE_API = `${process.env.REACT_APP_API}/api`;
const BASE_API_CORP = `${process.env.REACT_APP_API_CORP}/api`;
const BASE_URL = `${BASE_API_CORP}/api`;

const api = {
  post: function (url, payload, corpApp = false, queryParams = false) {
    return new Promise((resolve, reject) => {
      const BASE_URI_API = corpApp ? BASE_API_CORP : BASE_API;

      let request = new URLSearchParams(payload);

      let optionsFetch = {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: {
          "Content-Type": "application/json",
        },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        // body: JSON.stringify(payload),
      }

      if( queryParams !== true ){
        optionsFetch.body = JSON.stringify(payload);
      }

      fetch(`${queryParams ? `${BASE_URI_API}${url}?${request}` : `${BASE_URI_API}${url}`}`, optionsFetch)
        .then((res) => resolve(res))
        .catch((err) => reject(err));
    });
  },
  put: function (url, payload, corpApp = false) {
    return new Promise((resolve, reject) => {
      const BASE_URI_API = corpApp ? BASE_API_CORP : BASE_API;

      fetch(`${BASE_URI_API}${url}`, {
        method: "PUT",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: {
          "Content-Type": "application/json",
        },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: JSON.stringify(payload),
      })
        .then((res) => resolve(res))
        .catch((err) => reject(err));
    });
  },
  get: function (url, payload, corpApp = false) {
    const BASE_URI_API = corpApp ? BASE_API_CORP : BASE_API;

    let request = new URLSearchParams(payload);
    return new Promise((resolve, reject) => {
      let URL = !!payload ? `${BASE_URI_API}${url}?${request}` : `${BASE_URI_API}${url}`;
      fetch(URL)
        .then((res) => resolve(res))
        .catch((err) => reject(err));
    });
  },
  bmgPost: function (url, payload) {
    return new Promise((resolve, reject) => {

      const authBasic = btoa(`${process.env.REACT_APP_BMG_API_UID}:${process.env.REACT_APP_BMG_API_PWD}`);

      fetch(`${process.env.REACT_APP_BMG_API}${url}`, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          "Authorization": `Basic ${authBasic}`
        },
        body: JSON.stringify(payload)
      })
        .then((res) => resolve(res))
        .catch((err) => reject(err));
    });
  },
  enerjetPost: function (url, payload) {
    return new Promise((resolve, reject) => {

      const isProduction = process.env.REACT_APP_ENVIORMENT === "Produccion";
      const API_BASE_URL = isProduction ? process.env.REACT_APP_ENERJET_API_PROD_PUBLIC : process.env.REACT_APP_ENERJET_API_DEV_PUBLIC;
      const authBasic = btoa(`${process.env.REACT_APP_ENERJET_API_UID}:${process.env.REACT_APP_ENERJET_API_PWD}`);

      const form = new FormData();
      form.append("data", JSON.stringify(payload));
      // const boundary = uuidv4();

      fetch(`${API_BASE_URL}${url}`, {
        method: "POST",
        headers: {
          // 'Content-Type': `multipart/form-data; boundary=${boundary};`,
          "Authorization": `Basic ${authBasic}`
        },
        body: form
      })
        .then((res) => resolve(res))
        .catch((err) => reject(err));
    });
  },
};

export { api, BASE_URL };
