import * as React from 'react';
import { StoreContext } from '../../../store/StoreProvider';
// import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { MultiInput } from '../../MultiInput';
// import Button from "@material-ui/core/Button";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import TextFieldDefault, { TextFieldProps } from "@mui/material/TextField";
import { InputAdornment } from '@material-ui/core';
import { getI18N } from '../../../i18n'
import AppConfig from '../../../constants/AppConfig';

interface TrinidadyTobagoProps { }

var ACTIVE_UPDATE = "";

type InputCustomProps = {};
type InputProps = InputCustomProps & TextFieldProps;
const TextField: any = React.forwardRef((props: InputProps, defRef: any) => {
  return <TextFieldDefault {...props} />;
});

const UserData = () => {
  const [store, dispatch] = React.useContext(StoreContext);
  const [showUpd, setUpd] = React.useState(false);
  const [docType, setDocType] = React.useState("NIC");
  const [docTypeNac, setDocTypeNac] = React.useState(
    store?.inputSelectCountry?.ch_tipDoc || ""
  );
  const { battery } = store;

  const { Country } = store?.user;
  const currentLocale: string = Country || "PE";
  const i18n = getI18N({ currentLocale })


  let num = 0;
  for (let index = 0; index < battery?.DatosUsuario.length; index++) {
    if (!!battery?.DatosUsuario[index].Valor) num++;
  }

  const updateRequest = () => {
    ACTIVE_UPDATE = "USER";
    let btn: any = document?.getElementById("btn-submit");
    btn?.click();
  };

  let update = Number(battery?.Id) !== -1 ? true : false;
  if (!!battery?.TienePreTarjeta) {
    update = true;
  }
  let doc: any = battery?.DatosUsuario.find(({ IdDet }: any) => IdDet === AppConfig.userItemsType.DOCUMENT);

  // let numDocValue = doc.Valor.split("-");

  // let numDocumentValue = doc.Valor.split("-");
  // numDocumentValue = numDocumentValue.length === 1 ? doc.Valor.split(" ") : numDocumentValue;
  let numDocumentValue = doc.Valor.split(" ");

  if (numDocumentValue.length > 1) {
    // if (numDocumentValue.length === 3) {

    doc = {
      ...doc,
      Valor: numDocumentValue[1],
    };
    // }

  }

  doc = {
    ...doc,
    Tipo: "TXT",
  };

  let phone: any = battery?.DatosUsuario.find(({ IdDet }: any) => IdDet === AppConfig.userItemsType.PHONE);

  const numPhoneValue = phone.Valor.split(" ");

  if (!!phone.Valor) {
    if (numPhoneValue.length > 1) {

      phone = {
        ...phone,
        Valor: numPhoneValue[1],
      };
    }
  }

  let email: any = battery?.DatosUsuario.find(({ IdDet }: any) => IdDet === AppConfig.userItemsType.EMAIL);

  let name: any = battery?.DatosUsuario.find(({ IdDet }: any) => IdDet === AppConfig.userItemsType.NAMES);

  // num !== battery?.DatosUsuario.length

  return (
    <details
      className={`details active ${num !== battery?.DatosUsuario.length ? "" : "success"
        }`}
    >
      <summary>
        <strong style={{ color: "#000" }}>
          {i18n.CLIENT_DATA}
        </strong>
        <div className="arrow-container d-flex">
          <span style={{ marginLeft: "10px" }}>{`(${num}/${battery?.DatosUsuario.length})`}</span>
          {/* <progress max={battery?.DatosUsuario.length} value={num}></progress> */}
          <ArrowDropDownIcon />
        </div>
      </summary>
      <div className="details-body">
        <div className="row" style={{ display: "flex" }}>
          {/* <div className="col-sm-2" style={{ flex: 1 }}>
            <FormControl fullWidth style={{ marginTop: 5 }}>
              <InputLabel id="demo-simple-select-label">
                DOCUMENT TYPE.
              </InputLabel>
              <Select
                id="tipDoc"
                disabled={update}
                value={docType}
                label="Tipo de documento"
                onChange={(event) => setDocType(event.target.value)}
                data-keyword={"Tipo de documento"}
                data-parent={"Tipo de documento"}
              >
                <MenuItem value={"TIN"}>TIN</MenuItem>
                <MenuItem value={"NIC"}>NIC</MenuItem>
              </Select>
            </FormControl>
          </div> */}
          <div className="col-sm-10" style={{ flex: 2 }}>
            <MultiInput
              data={doc}
              disabled={update}
              requred={doc?.FlgObligatorio}
              placeholder={doc.PlaceHolder}
              className="input-battery"
              parent="DatosUsuario"
              keyword={JSON.stringify(doc)}
              update={update}
              autoComplete="off"
              onChange={() => {
                if (!showUpd) setUpd(true);
              }}
              callback={updateRequest}
            />
          </div>
        </div>

        <FormControl fullWidth style={{ marginTop: 5 }}>
          <TextField
            fullWidth
            id="numPhone"
            disabled={update}
            required={true ?? phone?.FlgObligatorio}
            className={` ${"uppercase"} ${update ? "input-success" : ""}`}
            label={phone.PlaceHolder}
            data-parent={"DatosUsuario"}
            data-keyword={JSON.stringify(phone)}
            variant="outlined"
            value={((phone?.Valor) ? phone.Valor : undefined)}
            autoComplete="off"
            onChange={() => {
              if (!showUpd) setUpd(true);
            }}
            type={"text"}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">+868</InputAdornment>
              ),
              inputProps: {
                min: 7,
                max: 7,
                minLength: 7,
                maxLength: 7,
              },
            }}
            step={"any"}
            min={7}
            max={7}
          />
        </FormControl>

        <MultiInput
          data={email}
          disabled={update}
          requred={email?.FlgObligatorio}
          placeholder={email.PlaceHolder}
          className="input-battery"
          parent="DatosUsuario"
          keyword={JSON.stringify(email)}
          update={update}
          autoComplete="off"
          onChange={() => {
            if (!showUpd) setUpd(true);
          }}
          callback={updateRequest}
        />
        <MultiInput
          data={name}
          disabled={update}
          requred={name?.FlgObligatorio}
          placeholder={name.PlaceHolder}
          className="input-battery"
          parent="DatosUsuario"
          keyword={JSON.stringify(name)}
          update={update}
          autoComplete="off"
          onChange={() => {
            if (!showUpd) setUpd(true);
          }}
          callback={updateRequest}
        />
      </div>
    </details>
  );
};

export const TrinidadYTobago = (props: any) => {
  return (
    <>
      <UserData />
    </>
  );
};

