import React, { createContext, useEffect, useReducer } from "react";
import {getData, ASYNC_KEY, types} from './AsyncStore';
import storeReducer, { initialStore } from "./StoreReducer";

const StoreContext = createContext()

const StoreProvider = ({children}) => {

    const [store, dispatch] = useReducer(storeReducer, initialStore);

    useEffect(() => {
        getData(ASYNC_KEY).then((data) => {
            if (!store.rehidrate && !!data) {
                dispatch({type: types.rehidrate, payload: data});
            }
        });
    }, []);

    const storeContextValue = [store, dispatch];

    return(
            <StoreContext.Provider value={storeContextValue}>
                {children}
            </StoreContext.Provider>
        )
}

export {StoreContext};
export default StoreProvider;