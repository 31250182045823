import * as React from 'react';
// import { ClientCard } from '../App/ClientCard';
import { TabPanel } from "./TabPanel";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import AppConfig from '../../constants/AppConfig';
import { WarrantyList } from './WarrantyList';
import { StoreContext } from '../../store/StoreProvider';
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
// import { WarrantyActivationsList } from './WarrantyActivationsList';
import { types } from "../../store/Dialog";
import { Button } from "@material-ui/core";
import { types as typesDialog } from "../../store/Dialog";
import { types as typesWarranty } from "../../store/Warranty";
import { WarrantyRequestModal } from "../Home/WarrantyRequestModal";
import TextField from '@mui/material/TextField';
import MobileDatePicker from '@mui/lab/MobileDatePicker';
import moment from "moment";
import { getI18N } from '../../i18n'

interface WarrantyProps { }

const ACTIVE_DIALOG = "new_warranty";

const validTypes = {
  code: "SC",
  noCode: "SS",
};

const { colors: { primary, secondary, success, danger }, types: { seller, partner } } = AppConfig

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export const WarrantyComponent = (props: WarrantyProps) => {

  const [store, dispatch] = React.useContext(StoreContext)
  const [new_warranty, setState] = React.useState("");
  const [value, setValue] = React.useState(0);
  const [openWarrantyModal, setOpenWarrantyModal] = React.useState(false);
  const [valueFor, setValueFor] = React.useState<Date | null>(store?.initDateWarranty);
  const [valueTo, setValueTo] = React.useState<Date | null>(store?.endDateWarranty);
  // const [openDateFor, setOpenDateFor] = React.useState(false);
  // const [openDateTo, setOpenDateTo] = React.useState(false);

  const { Country } = store?.user;
  const currentLocale: string = Country || "PE";
  const i18n = getI18N({ currentLocale })

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    dispatch({ type: types.change_warranty_tab, payload: newValue });
  };

  const EmptyClient = ({ children }: any) => (
    <>
      {!!Object.keys(store?.selected_client).length ? (
        <div style={{ marginTop: 10 }}>{children}</div>
      ) : (
        <div className="empty-client-container" style={{ margin: 10, paddingBottom: 20, }}>
          <span className="empty-client">
            No se encontraron solicitudes de garantia.
          </span>
        </div>
      )}
    </>
  );

  // console.log(valueFor, valueTo)

  const WarrantyListContainer = () => (
    <EmptyClient>
      <WarrantyList initDate={valueFor} endDate={valueTo} />
    </EmptyClient>
  );

  // const MemoizedWarrantyListContainer = React.memo(WarrantyListContainer);

  const openDialogWarranty = () => {
    // setOpenWarrantyModal(true);

    if (!store.dialog) {
      setState("");
      dispatch({ type: typesDialog.close });
    }

    dispatch({ type: typesWarranty.openCreateWarrantyRequest, payload: true });
    // dispatch({ type: typesDialog.open, payload: ACTIVE_DIALOG ,activeTab: true});

  };

  const requestDate = (position: string = "") => {

    // let obj = {
    //   FechaIni: moment(valueFor).format("MM/DD/yyyy"),
    //   FechaFin: moment(valueTo).format("MM/DD/yyyy")
    // };

    if (!!position) {
      let selectedDate = position === "start" ? moment(valueFor).format() : moment(valueTo).format()

      if (position === "start") {
        dispatch({ type: typesWarranty.setWarrantyDateStart, date: selectedDate });
      } else {
        dispatch({ type: typesWarranty.setWarrantyDateEnd, date: selectedDate });
      }

      return;
    }
  }

  // const MemoizedWarrantyRequestModal = React.memo(WarrantyRequestModal);

  return (
    <div className="warranty-container">
      <Box sx={{ pl: 1, pr: 1 }}>
        <Grid container spacing={1}>
          {/* <Grid className="container-client" item xs={12}>
            <ClientCard />
          </Grid> */}

          <Grid container spacing={1} justifyContent="center" flexWrap="nowrap" alignItems="center" marginTop="1rem" marginBottom="1.5rem">
            <Grid item xs={3}>
              <span
                className="label-state_"
                style={{ fontWeight: "", borderBottom: `2px solid ${secondary}` }}
              >
                {i18n.WARRANTY_STATUS.RECEIVED}
              </span>
            </Grid>
            <Grid item xs={3}>
              <span
                className="label-state_"
                style={{ fontWeight: "", borderBottom: `2px solid ${primary}` }}
              >
                {i18n.WARRANTY_STATUS.IN_PROGRESS}
              </span>
            </Grid>
            <Grid item xs={3}>
              <span
                className="label-state_"
                style={{ fontWeight: "", borderBottom: `2px solid ${success}` }}
              >
                {i18n.WARRANTY_STATUS.ACCEPTED}
              </span>
            </Grid>
            <Grid item xs={3}>
              <span
                className="label-state_"
                style={{ fontWeight: "", borderBottom: `2px solid ${danger}` }}
              >
                {i18n.WARRANTY_STATUS.REFUSED}
              </span>
            </Grid>
          </Grid>

          {/* <Grid xs={4} sx={{ pt: 0.5 }}>
            <Grid container item spacing={1}>
              <React.Fragment>
                <Grid item xs={6}>
                  <span
                    className="label-state"
                    style={{ fontWeight: "bold", color: secondary }}
                  >
                    Recibido
                  </span>
                </Grid>
                <Grid item xs={6}>
                  <span
                    className="label-state"
                    style={{ fontWeight: "bold", color: primary }}
                  >
                    En Progreso
                  </span>
                </Grid>
              </React.Fragment>
            </Grid>
            <Grid container item spacing={1}>
              <React.Fragment>
                <Grid item xs={6}>
                  <span
                    className="label-state"
                    style={{ fontWeight: "bold", color: success }}
                  >
                    Aceptado
                  </span>
                </Grid>
                <Grid item xs={6}>
                  <span
                    className="label-state"
                    style={{ fontWeight: "bold", color: danger }}
                  >
                    Rechazado
                  </span>
                </Grid>
              </React.Fragment>
            </Grid>
          </Grid> */}
        </Grid>
      </Box>
      <Box sx={{ pl: 1, pr: 1 }}>
        <Button
          variant="contained"
          className="btn-primary rounded-button"
          color="primary"
          style={{ borderRadius: "20px" }}
          onClick={openDialogWarranty}
        >
          <strong>{i18n.ADD_REQUEST}</strong>
        </Button>
      </Box>

      {store?.userAppCorp && (
        <div className="date-container">
          <Grid container spacing={1} justifyContent="center" flexWrap="nowrap" alignItems="center" marginBottom="1rem">
            <Grid item >
              <h4>{i18n.FROM}</h4>
            </Grid>
            <Grid item >
              <MobileDatePicker
                // open={openDateFor}
                // label="Desde"
                inputFormat="DD/MM/yyyy"
                value={valueFor}
                onChange={(newValue: Date | null) => {
                  setValueFor(newValue);
                  // setOpenDateFor(false);
                }}
                // showToolbar={false}
                // okText=""
                // cancelText="Cerrar"
                // onClose={() => setOpenDateFor(false)}
                onAccept={() => {
                  requestDate("start");
                  
                }}
                // onClick={() => setOpenDateTo(true)} 
                renderInput={(params: any) => <TextField hiddenLabel {...params} variant="filled" size="small" />}
              />
            </Grid>
            <Grid item >
              <h4>{i18n.UNTIL}</h4>
            </Grid>
            <Grid item >
              <MobileDatePicker
                // open={openDateTo}
                // label="Hasta"
                inputFormat="DD/MM/yyyy"
                value={valueTo}
                onChange={(newValue: Date | null) => {
                  setValueTo(newValue);
                  // setOpenDateTo(false);
                }}
                // okText=""
                // cancelText="Cerrar"
                // onClose={() => setOpenDateTo(false)}
                onAccept={() => {
                  requestDate("end");
                }}
                // onClick={() => setOpenDateTo(true)}
                renderInput={(params: any) => <TextField  hiddenLabel {...params} variant="filled" size="small" />}
              />
            </Grid>

          </Grid>
        </div>
      )}

      {/* store.active_warranty_tab */}
      <Box sx={{ flexGrow: 5, pl: 1, pr: 1 }}>
        <div className="battery-warranty-list-container">
          <Tabs value={0} onChange={handleChange} centered>
            <Tab className="black-text-color" label={i18n.WARRANTIES} {...a11yProps(0)} />
          </Tabs>
          <TabPanel value={0} index={0} padding={true}>
            <WarrantyListContainer />
          </TabPanel>
        </div>
      </Box>

      <WarrantyRequestModal
        open={openWarrantyModal}
        onClose={() => { setOpenWarrantyModal(false); }}
        callbackOne={() => {
          setState(validTypes.code);
          dispatch({ type: typesDialog.open, payload: ACTIVE_DIALOG, validType: validTypes.code });
        }}
        callbackTwo={() => {
          setState(validTypes.noCode);
          dispatch({ type: typesDialog.open, payload: ACTIVE_DIALOG, validType: validTypes.noCode });
        }}
      />
    </div>

  );
};

