import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@material-ui/core/Button";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { StoreContext } from "../../store/StoreProvider";
import IconButton from "@mui/material/IconButton";
import CloseIcon from '@mui/icons-material/Close';
import { Alert } from "../Alert";
import { getI18N } from '../../i18n'
// import { useDebounce } from "../../hooks/useDebounce";
// import Loading from "../Loading";
// import { clientAddressExt } from "../../store/sagas/Clients";

interface ClientAddressModalProps {
  callback?: any;
  dataDirections: any;
  onUpdateAddress?: (address: any) => void;
  openModalSucursal?: boolean;
  handleModalClose?: any;
}
export const ClientAddressModal = (props: ClientAddressModalProps) => {
  const [store, dispatch] = React.useContext(StoreContext);
  // const [valueAddress, setValueAddress] = React.useState("");
  const [hide, setHide] = React.useState(false);
  const [objectAddress, setObjectAddress] = React.useState<any>();
  const AlertRef = React.useRef<any>();
  // const [loading, setLoad] = React.useState(false);
  // const [dataDirections, setDataDirections] = React.useState<any>(props?.dataDirections ?? []);

  const { Country } = store?.user;
  const currentLocale: string = Country || "PE";
  const i18n = getI18N({ currentLocale })

  const handleChangeAddress = (event: any) => {
    let address = props?.dataDirections.find(
      (a: any) => `${a.direccion}` === event.target.value
    );

    setObjectAddress(address);
  }

  return (
    <div style={{}}>
      <Dialog open={props?.openModalSucursal ?? false} onClose={() => console.log()} className={!!hide ? "hide-address modal-custom-bmg" : "modal-custom-bmg"}>
        <DialogTitle style={{ textAlign: "center" }}>{i18n.DELIVERY_ADDRESSES}</DialogTitle>

        <IconButton
          aria-label="close"
          onClick={() => {
            if (!!props.handleModalClose) {
              props.handleModalClose();
            }
          }}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
          style={{ zIndex: 1 }}
        >
          <CloseIcon />
        </IconButton>

        <DialogContent>
          <FormControl>
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              // value={valueAddress}
              onChange={handleChangeAddress}
            >
              {props?.dataDirections
                ?.map((address: any, i: number) => (
                  <FormControlLabel
                    key={i}
                    value={`${address.direccion}`}
                    control={<Radio size="small" />}
                    label={`${address.direccion}`}
                  />
                ))}
            </RadioGroup>
          </FormControl>
        </DialogContent>
        <DialogActions style={{ justifyContent: "center", alignContent: "center", paddingBottom: "1rem" }}>
          <Button
            variant="contained"
            className="btn-primary rounded-button"
            color="primary"
            style={{ borderRadius: "20px", fontWeight: "bold", width: "50%" }}
            onClick={() => {

              if (!objectAddress) {
                AlertRef?.current?.open("Seleccione una dirección", "error");
                return;
              }

              if (!!props.onUpdateAddress) {
                props.onUpdateAddress(objectAddress);
              }
            }}
          >
            {i18n.CONFIRM}
          </Button>
        </DialogActions>
        <Alert ref={AlertRef} />
      </Dialog>
    </div>
  );
};
