import * as React from 'react';
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { TabPanel } from "./TabPanel";
import { TabOne } from "./TabOne";
import { TabTwo } from "./TabTwo";
import { StoreContext } from '../../store/StoreProvider';
import { types } from '../../store/Dialog';
import { getI18N } from '../../i18n'

interface HomeProps { }

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export const HomeComponent = (props: HomeProps) => {

  const [store, dispatch] = React.useContext(StoreContext);

  const { Country } = store?.user;
  const currentLocale: string = Country || "PE";
  const i18n = getI18N({ currentLocale })

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    dispatch({ type: types.change_tab, payload: newValue });
  };

  return (
    <Box className="tab-container">
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={store.active_tab}
          onChange={handleChange}
          centered
          aria-label="basic tabs example"
        >
          <Tab className="black-text-color" label={i18n.ENTER_THE_BATTERY_CODE} {...a11yProps(0)} />
          {/* <Tab label="Sin código único" {...a11yProps(1)} /> */}
        </Tabs>
      </Box>
      <TabPanel value={store.active_tab} index={0}>
        <TabOne />
      </TabPanel>
      {/* <TabPanel value={store.active_tab} index={1}>
        <TabTwo home={true} />
      </TabPanel> */}
    </Box>
  );
};

