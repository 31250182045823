import * as React from "react";
import SearchIcon from "@mui/icons-material/Search";
import { ClientCard } from "./ClientCard";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { TabPanel } from "../Home/TabPanel";
import { TabOne } from "../Home/TabOne";
import { TabTwo } from "../Home/TabTwo";

import { IconButton, TextField } from "@material-ui/core";
import { StoreContext } from "../../store/StoreProvider";
import { Typography } from "@material-ui/core";
import { types as typesDialog } from "../../store/Dialog";
import { Dialog } from "./Dialog";
import { NewWarranty } from "../Home/NewWarranty";
import { NewWarrantyForm } from "../Home/NewWarrantyForm";
import { ClientList } from "./ClientList";
import { Camera } from "./Camera";
import { AddWarranty } from "../icons/AddWarranty";
import { Bottom } from "./Bottom";
import { types } from "../../store/Warranty";
import { WarrantyRequestModal } from "../Home/WarrantyRequestModal";
import { SuccessModal } from "../Home/SuccessModal";
import { getI18N } from '../../i18n'

interface SecondBarProps { }

const validTypes = {
  code: "SC",
  noCode: "SS",
};
const ACTIVE_DIALOG = "new_warranty";
// const ACTIVE_DIALOG_NEW_CODE = "new_code";

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}



function SearchBar() {
  const [store, dispatch] = React.useContext(StoreContext);
  const [activeSearch, setSearch] = React.useState(false);

  const { Country } = store?.user;
  const currentLocale: string = Country || "PE";
  const i18n = getI18N({ currentLocale })

  // setSearchWarranty;
  // clearSearchWarranty;

  const search = (search: string) => {
    if (!search) {
      dispatch({ type: types.clearSearchWarranty });
    } else {
      dispatch({ type: types.setSearchWarranty, payload: search });
    }
  }


  return (
    <>
      <div className="header-title-container">
        {!!activeSearch ? (
          <TextField
            fullWidth
            className="input-maintenance uppercase"
            label="Buscar"
            variant="standard"
            type="search"
            onChange={(event) => search(event.target.value)}
          />
        ) : (
          <Typography variant="h6">{i18n.WARRANTY}</Typography>
        )}
      </div>
      {/* <IconButton
        edge="end"
        className={`pull-right`}
        color="inherit"
        onClick={() => setSearch(!activeSearch)}
      >
        <SearchIcon />
      </IconButton> */}
    </>
  );
}

export const SecondBar = React.memo(function SecondBarComponent(
  props: SecondBarProps
) {
  const [store, dispatch] = React.useContext(StoreContext);
  const [active_tab, setTab] = React.useState(0);
  const [new_warranty, setState] = React.useState(store?.validTypeNewWarranty ?? "");
  const [setClient, setClientToggle] = React.useState(false);

  const { Country } = store?.user;
  const currentLocale: string = Country || "PE";
  const i18n = getI18N({ currentLocale })

  const [openWarrantyModal, setOpenWarrantyModal] = React.useState(false);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };

  const toggleClient = () => {
    setClientToggle(!setClient);
  };

  const open = () => {
    // if (!store.dialog) {
    // dispatch({ type: typesDialog.open, payload: ACTIVE_DIALOG });
    // } else {
    setState("");
    dispatch({ type: typesDialog.close, activeTab: true });
    // }
  };

  const openDialog = () => {
    if (!store.dialog) {
      setState("");
      dispatch({ type: typesDialog.close });
    }

    dispatch({ type: typesDialog.open, payload: ACTIVE_DIALOG });
  };

  const SwitchView = () => {

    // dispatch({ type: typesDialog.change_new_warranty_tab, payload: false });

    switch (new_warranty) {
      case validTypes.code:
        return <NewWarranty callback={() => setState("")} />;
        break;

      case validTypes.noCode:
        return <NewWarrantyForm callback={() => setState("")} />;
        break;

      default:
        return <NewWarrantyForm callback={() => setState("")} />;
        break;
    }
  };

  // console.log(new_warranty)

  React.useEffect(() => {

    dispatch({ type: types?.openCreateWarrantyRequest, payload: false })
    // if( !store.active_dialog ) {
    //   setState("");
    // }

    // dispatch({ type: typesDialog.change_new_warranty_tab, payload: false });

  }, []);

  return (
    <>
      <SearchBar />
      {/* <IconButton
        edge="end"
        className={`pull-right`}
        color="inherit"
        onClick={openDialog}
      >
        <AddWarranty />
      </IconButton> */}

      <WarrantyRequestModal
        open={store.openCreateWarrantyRequest}
        onClose={() => dispatch({ type: types.openCreateWarrantyRequest, payload: false })}
        callbackOne={() => {
          setState(validTypes.code);
          dispatch({ type: typesDialog.open, payload: ACTIVE_DIALOG, validType: validTypes.code });
        }}
        callbackTwo={() => {
          setState(validTypes.noCode);
          dispatch({ type: typesDialog.open, payload: ACTIVE_DIALOG, validType: validTypes.noCode });
        }}
      />

      <SuccessModal
        open={store?.openSuccessResponse}
        onClose={() => dispatch({ type: types.openSuccessResponse, payload: false })}
      />

      {/* && !store?.active_new_warranty_tab */}

      {(store.active_dialog === ACTIVE_DIALOG) && (
        <Dialog
          title={i18n.CREATE_WARRANTY_REQUEST}
          closeCallback={open}
          right={!!new_warranty && <Camera />}
        >
          {!!new_warranty ? (
            <SwitchView />
          ) : (
            <Box className="tab-container">
              <div className="client-container">
                <ClientCard callback={toggleClient} />
              </div>
              {!!setClient ? (
                <ClientList callback={toggleClient} />
              ) : (
                <>
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Tabs
                      value={active_tab}
                      onChange={handleChange}
                      centered
                      aria-label="basic tabs example"
                    >
                      <Tab label="Con código único" {...a11yProps(0)} />
                      <Tab label="Sin código único" {...a11yProps(1)} />
                    </Tabs>
                  </Box>
                  <TabPanel value={active_tab} index={0}>
                    <TabOne
                      callback={() => {
                        setState(validTypes.code);
                        dispatch({ type: typesDialog.change_new_warranty_tab, payload: false });
                      }}
                      type={validTypes.code}
                    />
                  </TabPanel>
                  <TabPanel value={active_tab} index={1}>
                    <TabTwo
                      callback={() => {
                        setState(validTypes.noCode);
                        dispatch({ type: typesDialog.change_new_warranty_tab, payload: false });
                      }}
                      type={validTypes.noCode}
                    />
                  </TabPanel>
                </>
              )}
            </Box>
          )}
          <Bottom />
        </Dialog>
      )}
    </>
  );
});
