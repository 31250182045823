import * as React from "react";
import { AsyncReducer, types as typesAsync, storeData } from './AsyncStore';
import { AuthReducer, types as typesAuth } from "./Auth";
import { DialogReducer, types as typesDialog } from "./Dialog";
import { ClientReducer, types as typesClients } from "./Clients";
import { types as typesWarranty, WarrantyReducer } from "./Warranty";
import moment from "moment";

const initialStore = {
  rehidrate: false,
  fetch: false,
  user: {
    auth: false,
    dateTimeLogin: ""
  },
  dialog: false,
  openAddress: false,
  openMaintenance: false,
  openView: "",
  active_pane: 0,
  searchCode: "",
  active_dialog: "",
  openCreateWarrantyRequest: false,
  active_new_warranty_tab: false,
  validTypeNewWarranty: "",
  openSuccessResponse: false,
  active_tab: 0,
  active_warranty_tab: 0,
  pagination: {},
  warranty_activation_list: [],
  warranty_activation_plate_list: [],
  warranty_activation_document_list: [],
  searchPlateCode: "",
  searchDocumentCode: "",
  downloadReportActivationsExcel: false,
  initDate: new Date(moment().startOf('month').format("LLLL")),
  endDate: new Date(moment().format("LLLL")),
  initDateWarranty: new Date(moment().startOf('month').format("LLLL")),
  endDateWarranty: new Date(moment().format("LLLL")),
  clients: [],
  clientAddress: [],
  clients_lite: [],
  selected_client: {},
  warranty: {},
  warranty_list: [],
  searchWarranty: "",
  warrantyDetail: {},
  batteryFront: {},
  warrantyFront: {},
  battery: {},
  maintenance: {},
  selectData: {},
  newWarranty: {},
  newBattery: {},
  inputSelectCountry: {},
  updateVersionApp: true,
  isLogoutTime: false,
  selectedAddressSucursal: false,
  objAddressSucursal: {}
};

const storeReducer = (state, action) => {
  //combine reducers
  if (Object.values(typesAsync).indexOf(action.type) >= 0) {
    return AsyncReducer(state, action);
  } else if (Object.values(typesDialog).indexOf(action.type) >= 0) {
    let store = DialogReducer(state, action);
    storeData(store)
    return store;
  } else if (Object.values(typesWarranty).indexOf(action.type) >= 0) {
    let store = WarrantyReducer(state, action);
    storeData(store)
    return store;
  } else if (Object.values(typesAuth).indexOf(action.type) >= 0) {
    let store = AuthReducer(state, action);
    storeData(store)
    return store;
  } else if (Object.values(typesClients).indexOf(action.type) >= 0) {
    let store = ClientReducer(state, action);
    storeData(store);
    return store;
  } else {
    return state;
  }

}

export { initialStore };
export default storeReducer