import * as React from 'react';
import { StoreContext } from '../../store/StoreProvider';
import { ActivationComponent } from './Activation';
import { HomeComponent } from './Home';
import { ContactusComponent } from './Contactus';
import { RewardComponent } from './Reward';
import './Home.css'
import { WarrantyComponent } from './Warranty';


interface HomeProps { }

export const Home = (props: HomeProps) => {

  const [store, dispatch] = React.useContext(StoreContext)

  const RenderScene = () => {
    switch (store.active_pane) {
      case 0:
        return <HomeComponent />;
        break;
      case 1:
        return <WarrantyComponent />;
      case 2:
        return <ActivationComponent />;
        break;
      case 3:
        if( (store?.user?.Country === "PE" || store?.user?.Country === "NAC") ){
          return <ContactusComponent />;
        }
        return <HomeComponent />;
        break;
      case 4:
        return <RewardComponent />;
      default:
        return <HomeComponent />;
        break;
    }
  }


  return <RenderScene />
};
