import * as React from "react";
import { StoreContext } from "../../../store/StoreProvider";
// import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { IntensivoTaxi, MultiInput, Particular, Pesado } from "../../MultiInput";
// import Button from "@material-ui/core/Button";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import TextFieldDefault, { TextFieldProps } from "@mui/material/TextField";
// import { DisplaySelects } from "../DisplaySelects";
// import { InputAdornment } from "@material-ui/core";
// interface RepublicaDominicanaProps { }
var ACTIVE_UPDATE = "";

type InputCustomProps = {};
type InputProps = InputCustomProps & TextFieldProps;
const TextField: any = React.forwardRef((props: InputProps, defRef: any) => {
  return <TextFieldDefault {...props} />;
});

const UserData = () => {
  const [store, dispatch] = React.useContext(StoreContext);
  const [showUpd, setUpd] = React.useState(false);

  const [docType, setDocType] = React.useState(
    store?.inputSelectCountry?.do_tipDoc || "CI"
  );
  const [docTypeNac, setDocTypeNac] = React.useState(
    store?.inputSelectCountry?.numDocNac || ""
  );
  const [codePhone, setCodePhone] = React.useState(
    store?.inputSelectCountry?.codePhone || "809"
  );
  const [numberPhone, setNumberPhone] = React.useState(
    store?.inputSelectCountry?.numPhone || ""
  );

  const { battery } = store;

  let num = 0;
  for (let index = 0; index < battery?.DatosUsuario.length; index++) {
    if (!!battery?.DatosUsuario[index].Valor) num++;
  }

  const updateRequest = () => {
    ACTIVE_UPDATE = "USER";
    let btn: any = document?.getElementById("btn-submit");
    btn?.click();
  };

  let update = Number(battery?.Id) !== -1 ? true : false;
  if (!!battery?.TienePreTarjeta) {
    update = true;
  }

  let doc: any = battery?.DatosUsuario.find(
    ({ DescDet }: any) =>
      DescDet.toLocaleLowerCase() ===
      "Documento de identificación".toLocaleLowerCase()
  );

  let numDocumentValue = doc.Valor.split(" ");
  // numDocumentValue = numDocumentValue.length === 1 ? doc.Valor.split(" ") : numDocumentValue;

  if (numDocumentValue.length > 1) {

    if (numDocumentValue.length === 2) {
      if (docTypeNac !== numDocumentValue[1]) {
        setDocTypeNac(numDocumentValue[1]);
      }
    }
    
    // if (numDocumentValue.length === 3) {
    //   if (docTypeNac !== numDocumentValue[0]) {
    //     setDocType("RNC");
    //     setDocTypeNac(numDocumentValue[0]);
    //   }
    // }

    doc = {
      ...doc,
      Valor: numDocumentValue[1],
    };

  }

  doc = {
    ...doc,
    Maximo: 9,
    Minimo: 7,
  };

  let phone: any = battery?.DatosUsuario.find(
    ({ DescDet }: any) =>
      DescDet.toLocaleLowerCase() === "Celular de cliente".toLocaleLowerCase()
  );

  if (!!phone.Valor) {
    let numPhoneValue = phone.Valor.split(" ");

    if (numPhoneValue.length > 1) {

      if (numberPhone !== numPhoneValue[1]) {
        setNumberPhone(numPhoneValue[1]);
      }

      phone = {
        ...phone,
        Valor: numPhoneValue[1],
      };

      let cmon = `${numPhoneValue[0]}`;
      if (codePhone !== cmon) {
        setCodePhone(cmon);
      }
    }
  }

  let email: any = battery?.DatosUsuario.find(
    ({ DescDet }: any) =>
      DescDet.toLocaleLowerCase() === "Correo de cliente".toLocaleLowerCase()
  );

  let name: any = battery?.DatosUsuario.find(
    ({ DescDet }: any) =>
      DescDet.toLocaleLowerCase() === "Nombre cliente".toLocaleLowerCase()
  );

  return (
    <details
      className={`details active ${num !== battery?.DatosUsuario.length ? "" : "success"
        }`}
    >
      <summary>
        <strong>Datos de cliente</strong>
        <div className="arrow-container d-flex">
          {/* <progress max={battery?.DatosUsuario.length} value={num}></progress> */}
          <span
            // className={
            //   num !== battery?.DatosUsuario.length ? "primary" : "success"
            // }
          >{`(${num}/${battery?.DatosUsuario.length})`}</span>
          <ArrowDropDownIcon />
        </div>
      </summary>
      <div className="details-body">
        <div className="row" style={{ display: "flex" }}>
          <div className="col-sm-2" style={{ flex: 0.7 }}>
            <FormControl fullWidth style={{ marginTop: 5 }}>
              <InputLabel id="demo-simple-select-label">
                Tipo de Doc.
              </InputLabel>
              <Select
                id="tipDoc"
                value={docType}
                label="Tipo de documento"
                onChange={(event) => {

                  if (event.target.value === "CI") {
                    let doc = docTypeNac;
                    const result = doc.substr(0, 7);
                    setDocTypeNac(result);
                  }

                  setDocType(event.target.value);
                }}
                disabled={update}
                data-keyword={"Tipo de documento"}
                data-parent={"Tipo de documento"}
              >
                <MenuItem value={"CI"}>CI</MenuItem>
                <MenuItem value={"RNC"}>RNC</MenuItem>
              </Select>
            </FormControl>
          </div>

          <div className="col-sm-10" style={{ flex: 3 }}>
            <FormControl fullWidth style={{ marginTop: 5 }}>
              <TextField
                fullWidth
                id="docNac"
                disabled={update}
                required={doc?.FlgObligatorio}
                className={` ${"uppercase"} ${update ? "input-success" : ""}`}
                label={"Número de documento"}
                data-parent={"DatosUsuario"}
                data-keyword={JSON.stringify(doc)}
                variant="outlined"
                // value={(doc?.Valor) ? doc.Valor : docTypeNac}
                value={docTypeNac}
                onChange={(event: any) => {
                  let selfValidation: any = () => {
                    if (event.target.value.length < docTypeNac.length) {
                      return true;
                    } else {
                      let v = /^[0-9]*$/;

                      const value = event.target.value;

                      if (value.match(v)) {
                        // setDocTypeNac(event.target.value);
                        return true;
                      }
                      return false;
                    }
                  };

                  if (!!selfValidation()) {
                    setDocTypeNac(event.target.value);
                  }
                }}
                type={"text"}
                InputProps={{
                  inputProps: {
                    min: docType === "CI" ? 7 : 9,
                    max: docType === "CI" ? 11 : 9,
                    minLength: docType === "CI" ? 7 : 9,
                    maxLength: docType === "CI" ? 11 : 9,
                    // min: 9,
                    // max: 13,
                    // minLength: 9,
                    // maxLength: 13,
                  },
                }}
                step={"any"}
                min={docType === "CI" ? 7 : 9}
                max={docType === "CI" ? 11 : 9}
              // min={9}
              // max={13}
              />
            </FormControl>
          </div>
        </div>
        <div className="row" style={{ display: "flex" }}>
          <div className="col-sm-1" style={{ flex: 0.7 }}>
            <span
              style={{
                display: "flex",
                justifyContent: "center",
                padding: "20px 0",
                fontSize: "1.1em",
                color: "#383838",
              }}
            >
              +1
            </span>
          </div>
          <div className="col-sm-1 dropdown-code" style={{ flex: 0.7 }}>
            <FormControl fullWidth style={{ marginTop: 5 }}>
              <InputLabel id="demo-simple-select-label">Código</InputLabel>
              <Select
                id="codePhone"
                value={codePhone}
                label=""
                onChange={(event) => setCodePhone(event.target.value)}
                disabled={update}
                data-keyword={""}
                data-parent={""}
              >
                <MenuItem value={"1809"}>809</MenuItem>
                <MenuItem value={"1829"}>829</MenuItem>
                <MenuItem value={"1849"}>849</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="col-sm-10" style={{ flex: 3 }}>

            <FormControl fullWidth style={{ marginTop: 5 }}>
              <TextField
                fullWidth
                id="numPhone"
                data={phone}
                disabled={update}
                required={true ?? phone?.FlgObligatorio}
                className={` ${"uppercase"} ${update ? "input-success" : ""}`}
                label={"Celular"}
                data-parent={"DatosUsuario"}
                data-keyword={JSON.stringify(phone)}
                variant="outlined"
                value={numberPhone}
                autoComplete="off"
                onChange={(event: any) => {
                  // if (!showUpd) setUpd(true);
                  setNumberPhone(event.target.value);
                }}
                type={"text"}
                InputProps={{
                  inputProps: {
                    min: 7,
                    max: 7,
                    minLength: 7,
                    maxLength: 7,
                  },
                }}
                step={"any"}
                min={7}
                max={7}
              />
            </FormControl>
          </div>
        </div>
        <MultiInput
          data={email}
          disabled={update}
          requred={false ?? email?.FlgObligatorio}
          placeholder={email.PlaceHolder}
          className="input-battery"
          parent="DatosUsuario"
          keyword={JSON.stringify(email)}
          update={update}
          onChange={() => {
            if (!showUpd) setUpd(true);
          }}
          callback={updateRequest}
        />
        <MultiInput
          data={name}
          disabled={update}
          requred={name?.FlgObligatorio}
          placeholder={name.PlaceHolder}
          className="input-battery"
          parent="DatosUsuario"
          keyword={JSON.stringify(name)}
          update={update}
          onChange={() => {
            if (!showUpd) setUpd(true);
          }}

          callback={updateRequest}
        />
      </div>
    </details>
  );
};

export const ReplublicaDominicana = (props: any) => {

  return (
    <>
      <UserData />
    </>
  );
};
