import { icon_search } from '../../assets/Media';

export function IconSearch() {
  return (
    <>
      <img src={icon_search} alt="Search" className="icon icon-custom icon-search-bmg" />
    </>
  );
}

