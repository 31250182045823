import React from "react";
import { api } from "./api";
import { TextareaAutosize } from "@mui/material";

export const getWarrantyByIdRequest = (payload) => api.get(`/WarrantyCardFindBy`, payload);

export const getWarrantyRequest = (payload) => api.get(`/WarrantyCardPWA`, payload);

export const CombustionVRequest = (payload) => api.get(`/CombustionV`, payload);

export const clientAddressRequest = (payload) => api.get(`/Address`, payload);

export const MarModeloVRequest = (payload) => api.get(`/MarModeloV`, payload);

export const TipoUsoVRequest = (payload) => api.get(`/TipoUsoV`, payload);

export const WarrantyCardRequest = (payload) => api.post(`/WarrantyCard`, payload);

export const WarrantyRequestRequest = (payload) => api.post(`/WarrantyRequest`, payload);

export const WarrantyRequestChatRequest = (payload) => api.post(`/WarrantyRequestChat`, payload);

export const GetWarrantyRequestRequest = (payload) => api.get(`/WarrantyRequest`, payload);

export const WarrantyCardCardCodeRequest = (payload) => api.get(`/WarrantyCardCardCode`, payload);

export const WarrantyCardMaintenanceRequest = (payload) => api.post(`/WarrantyCardMaintenance?IdTarjeta=${payload.id}`, payload.data);

export const WarrantyRequestParamsRequest = (payload) => api.get(`/WarrantyRequestParamsPWA`, payload);

export const WarrantyCardDetailRequest = (payload) => api.put(`/WarrantyCardDetail?IdTarjeta=${payload.id}`, payload.data);

export const GetReporteActivacionesRequest = (payload) => api.post(`/GetReporteActivacionesTradicional`,payload, false, true);

// corporativo

export const CorpWarrantyCardRequest = (payload) => api.post(`/TarjetaGarantia/PostWarrantyCard`, payload, true);

export const getCorpWarrantyByMinicoRequest = (payload) => api.get(`/TarjetaGarantia/GetWarrantyCardsByMinicod`, payload, true);

export const getCorpWarrantyRequest = (payload) => api.get(`/TarjetaGarantia/WarrantyCardPWA`, payload, true);

export const CorpMarcaModeloVehiculosRequest = (payload) => api.get(`/Vehiculo/GetMarcaModeloVehiculos`, payload, true);

export const CorpClientAddressRequest = (payload) => api.get(`/SocioNegocio/GetAdressesByClient`, payload, true);

export const getCorpFrecuenciaGarantiaRequest = (payload) => api.get(`/TarjetaGarantia/GetFrecuenciaGarantia`, payload, true);

export const CorpWarrantyCardsCodeRequest = (payload) => api.get(`/TarjetaGarantia/GetWarrantyCardsBy`, payload, true);

export const CorpWarrantyCardByIdRequest = (payload) => api.get(`/TarjetaGarantia/GetWarrantyCardsById`, payload, true);

// corp solicitud

export const CorpGetWarrantyRequestRequestBy = (payload) => api.get(`/SolicitudGarantia/GetWarrantyRequestBy`, payload, true);

export const CorpGetWarrantyRequestByMinicod = (payload) => api.get(`/SolicitudGarantia/GetWarrantyRequestByMinicod`, payload, true);

export const CorpPostWarrantyRequest = (payload) => api.post(`/SolicitudGarantia/PostWarrantyRequest`, payload, true);

export const CorpGetWarrantyRequestById = (payload) => api.get(`/SolicitudGarantia/GetWarrantyRequestById`, payload, true);

export const CorpGetDetailByMinicodRequest = (payload) => api.get('/Bateria/GetDetailByMinicod',payload, true);

export const CorpGetReporteActivacionesRequest = (payload) => api.get('/TarjetaGarantia/GetReporteActivaciones',payload, true);

export const CorpPostWarrantyCardMaintenanceRequest = (payload) => api.post('/TarjetaGarantia/PostWarrantyCardMaintenance',payload, true);

export const CorpGetWarrantyCardsMaintenanceByIdRequest = (payload) => api.get('/TarjetaGarantia/GetWarrantyCardsMaintenanceById',payload, true);

