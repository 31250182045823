import React from 'react';
import { api } from './api';

export const loginRequest = (payload) => api.post(`/Authenticate`, payload);

export const validateCodeRequest = (payload) => api.post(`/verification`, payload);

export const updateRequest = (payload) => api.post(`/update-user`, payload, {
  headers: {
    Authorization: `Bearer ${payload.token}`,
  },
});

// request user corporativo

export const loginCorporativoRequest = (payload) => api.post(`/Usuario/Authenticate`, payload, true);