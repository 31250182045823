import React, { useState } from "react";

import { NavLink, withRouter } from "react-router-dom";
import Routes from "../../Routes";

import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Drawer,
  MenuList,
  MenuItem,
} from "@material-ui/core";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@material-ui/icons/Menu";
import { StoreContext } from "../../store/StoreProvider";
import { types } from "../../store/Auth";
import { types as typesDialog } from "../../store/Dialog";
import { HomeBar } from "./HomeBar";
import { SecondBar } from "./SecondBar";

import { logo } from "../../assets/Media";
import LogoutIcon from "@mui/icons-material/Logout";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CarRepairIcon from "@mui/icons-material/CarRepair";
import AppConfig from "../../constants/AppConfig";
import { useHistory } from "react-router";
import { ActivationBar } from "./ActivationBar";
import StarsIcon from "@mui/icons-material/Stars";
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import { types as typesWarranty } from "../../store/Warranty";
import { ContactusBar } from "./ContactusBar";
import TaskIcon from '@mui/icons-material/Task';
import { IconReward } from "../icons/IconReward";
import { RewardBar } from "./RewardBar";
import { getI18N } from '../../i18n'

const {
  types: { seller, partner },
  colors: { primary },
} = AppConfig;

const NavigationBar: React.FC = (props: any) => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const [store, dispatch] = React.useContext(StoreContext);
  const history = useHistory();

  const { Country } = store?.user;
  const currentLocale: string = Country || "PE";
  const i18n = getI18N({ currentLocale })

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setIsOpen(open);
    };

  const activeRoute = (routeName: any) => {
    return props.location.pathname === routeName ? true : false;
  };

  const setRoute = (routeName: any) => {
    // let active;

    const modulesNavBar: { [key: string]: number } = {
      "/": 0,
      "home": 1,
      "activaciones": 2,
      "contactanos": 3,
      "recompensas": 4
    };

    dispatch({ type: typesDialog.change, payload: modulesNavBar[routeName] });
  };

  const logout = () => {
    if (store?.user?.tipo === seller) {
      dispatch({ type: types.authLogoutRV });
    } else if (store?.user?.tipo === partner) {
      dispatch({ type: types.authLogoutSN });
    } else {
      dispatch({ type: types.authLogout });
    }
  };

  return (
    <div>
      <div className={classes.root}>
        <AppBar position="fixed">
          <Toolbar>
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="menu"
              onClick={toggleDrawer(true)}
            >
              <MenuIcon />
            </IconButton>

            {store.active_pane === 0 && <HomeBar />}
            {store.active_pane === 1 && <SecondBar />}
            {store.active_pane === 2 && <ActivationBar />}
            {store.active_pane === 3 && <ContactusBar />}
            {store.active_pane === 4 && <RewardBar />}
          </Toolbar>
        </AppBar>
      </div>
      <Drawer
        classes={{ paper: classes.drawer }}
        open={isOpen}
        onClose={toggleDrawer(false)}
      >
        <div className="drawer-container">
          <div>
            <div className="drawer-header">
              <img src={logo} alt="logo" className="logo" />
            </div>
            <div
              className={classes.fullList}
              role="presentation"
              onClick={toggleDrawer(false)}
              onKeyDown={toggleDrawer(false)}
            >
              <MenuList>

                <NavLink
                  to={"/"}
                  style={{ textDecoration: "none" }}
                  onClick={() => setRoute("/")}
                >
                  <MenuItem>
                    <TaskIcon
                      sx={{
                        marginRight: 2,
                        color:
                          store?.active_pane === 0
                            ? AppConfig.colors.primary
                            : "#000",
                      }}
                    />
                    <ListItemText
                      primary={i18n.RECORD}
                      sx={{
                        color:
                          store?.active_pane === 0
                            ? AppConfig.colors.primary
                            : "#000",
                      }}
                    />
                  </MenuItem>
                </NavLink>

                <NavLink
                  to={"/home"}
                  style={{ textDecoration: "none" }}
                  onClick={() => setRoute("home")}
                >
                  <MenuItem>
                    <StarsIcon
                      sx={{
                        marginRight: 2,
                        color:
                          store?.active_pane === 1
                            ? AppConfig.colors.primary
                            : "#000",
                      }}
                    />
                    <ListItemText
                      primary={i18n.WARRANTIES}
                      sx={{
                        color:
                          store?.active_pane === 1
                            ? AppConfig.colors.primary
                            : "#000",
                      }}
                    />
                  </MenuItem>
                </NavLink>

                <NavLink
                  to={"/activaciones"}
                  style={{ textDecoration: "none" }}
                  onClick={() => setRoute("activaciones")}
                >
                  <MenuItem>
                    <CheckCircleOutlineIcon
                      sx={{
                        marginRight: 2,
                        color:
                          store?.active_pane === 2
                            ? AppConfig.colors.primary
                            : "#000",
                      }}
                    />
                    <ListItemText
                      primary={i18n.MY_ACTIVATIONS}
                      sx={{
                        color:
                          store?.active_pane === 2
                            ? AppConfig.colors.primary
                            : "#000",
                      }}
                    />
                  </MenuItem>
                </NavLink>

                {(store?.user?.Country === "PE" || store?.user?.Country === "NAC") &&
                  <NavLink
                    to={"/contactanos"}
                    style={{ textDecoration: "none" }}
                    onClick={() => setRoute("contactanos")}
                  >
                    <MenuItem>
                      <SupportAgentIcon
                        sx={{
                          marginRight: 2,
                          color:
                            store?.active_pane === 3
                              ? AppConfig.colors.primary
                              : "#000",
                        }}
                      />
                      <ListItemText
                        primary={i18n.CONTACT_US}
                        sx={{
                          color:
                            store?.active_pane === 3
                              ? AppConfig.colors.primary
                              : "#000",
                        }}
                      />
                    </MenuItem>
                  </NavLink>
                }

                {(store?.user?.Country === "PE" || store?.user?.Country === "NAC") && store?.user?.GroupCode !== "105" &&
                  <NavLink
                    to={"/recompensas"}
                    style={{ textDecoration: "none" }}
                    onClick={() => setRoute("recompensas")}
                  >
                    <MenuItem>
                      <IconReward />
                      <ListItemText
                        primary={i18n.REWARDS}
                        sx={{
                          color:
                            store?.active_pane === 4
                              ? AppConfig.colors.primary
                              : "#000",
                        }}
                      />
                    </MenuItem>
                  </NavLink>
                }
              </MenuList>
            </div>
          </div>

          <div className="drawer-footer">
            <NavLink
              to={"/"}
              style={{ textDecoration: "none" }}
              onClick={logout}
            >
              <MenuItem>
                <LogoutIcon sx={{ marginRight: 2, color: "#000" }} />
                <ListItemText
                  primary={i18n.LOGOUT}
                  sx={{ color: "#000" }}
                />
              </MenuItem>
            </NavLink>
            <span
              style={{
                margin: "14px 15px",
                padding: "25px 0",
                color: "#cacaca",
              }}
            >
              {process.env.REACT_APP_VERSION}
            </span>
          </div>
        </div>
      </Drawer>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
    drawer: {
      width: 300,
    },
    fullList: {
      width: "auto",
    },
  })
);

export default withRouter(NavigationBar);
