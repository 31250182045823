import React from "react";
import {
  getWarrantyByIdRequest,
  clientAddressRequest,
  getWarrantyRequest,
  CombustionVRequest,
  MarModeloVRequest,
  TipoUsoVRequest,
  WarrantyCardRequest,
  WarrantyRequestRequest,
  GetWarrantyRequestRequest,
  WarrantyCardDetailRequest,
  WarrantyRequestChatRequest,
  WarrantyRequestParamsRequest,
  WarrantyCardMaintenanceRequest,
  WarrantyCardCardCodeRequest,

  // corporativo
  getCorpWarrantyByMinicoRequest,
  CorpMarcaModeloVehiculosRequest,
  CorpClientAddressRequest,
  CorpWarrantyCardRequest,
  getCorpFrecuenciaGarantiaRequest,
  CorpWarrantyCardsCodeRequest,
  CorpWarrantyCardByIdRequest,
  CorpGetWarrantyRequestRequestBy,
  CorpGetWarrantyRequestByMinicod,
  CorpPostWarrantyRequest,
  CorpGetWarrantyRequestById,

  CorpGetDetailByMinicodRequest,
  CorpGetReporteActivacionesRequest,
  CorpPostWarrantyCardMaintenanceRequest,
  CorpGetWarrantyCardsMaintenanceByIdRequest,
  GetReporteActivacionesRequest
} from "../../apis/warranty";

export const getWarrantyById = (payload) => {
  return new Promise((resolve, reject) => {
    try {
      getWarrantyByIdRequest(payload).then((res) => {
        if (res.status === 200) {
          resolve(res.json());
        } else {
          reject(res);
        }
      });
    } catch (error) {
      console.log(error);
      reject(error);
    }
  });
};

export const getWarranty = (payload) => {
  return new Promise((resolve, reject) => {
    try {
      getWarrantyRequest(payload).then((res) => {
        if (res.status === 200) {
          resolve(res.json());
        } else {
          reject(res);
        }
      });
    } catch (error) {
      console.log(error);
      reject(error);
    }
  });
};

export const CombustionV = (payload) => {
  return new Promise((resolve, reject) => {
    try {
      CombustionVRequest(payload).then((res) => {
        if (res.status === 200) {
          resolve(res.json());
        } else {
        }
      });
    } catch (error) {
      console.log(error);
      reject(error);
    }
  });
};

export const MarModeloV = (payload) => {
  return new Promise((resolve, reject) => {
    try {
      MarModeloVRequest(payload).then((res) => {
        if (res.status === 200) {
          resolve(res.json());
        } else {
        }
      });
    } catch (error) {
      console.log(error);
      reject(error);
    }
  });
};

export const TipoUsoV = (payload) => {
  return new Promise((resolve, reject) => {
    try {
      TipoUsoVRequest(payload).then((res) => {
        if (res.status === 200) {
          resolve(res.json());
        } else {
        }
      });
    } catch (error) {
      console.log(error);
      reject(error);
    }
  });
};

export const clientAddress = (payload) => {
  return new Promise((resolve, reject) => {
    try {
      clientAddressRequest(payload).then((res) => {
        if (res.status === 200) {
          resolve(res.json());
        } else {
        }
      });
    } catch (error) {
      console.log(error);
      reject(error);
    }
  });
};


export const WarrantyCard = (payload) => {
  return new Promise((resolve, reject) => {
    try {
      console.log(payload)
      WarrantyCardRequest(payload).then((res) => {
        if (res.status === 200) {
          resolve(res.json());
        } else {
          reject(res);
        }
      });
    } catch (error) {
      console.log(error);
      reject(error);
    }
  });
};

export const WarrantyRequestParams = (payload) => {
  return new Promise((resolve, reject) => {
    try {
      WarrantyRequestParamsRequest(payload).then((res) => {
        if (res.status === 200) {
          resolve(res.json());
        } else {
          reject(res);
        }
      });
    } catch (error) {
      console.log(error);
      reject(error);
    }
  });
};

export const WarrantyCardMaintenance = (payload) => {
  return new Promise((resolve, reject) => {
    try {
      WarrantyCardMaintenanceRequest(payload).then((res) => {
        if (res.status === 200) {
          resolve(res.json());
        } else {
          reject(res);
        }
      });
    } catch (error) {
      console.log(error);
      reject(error);
    }
  });
};

export const WarrantyRequestChat = (payload) => {
  return new Promise((resolve, reject) => {
    try {
      WarrantyRequestChatRequest(payload).then((res) => {
        if (res.status === 200) {
          resolve(res.json());
        } else {
        }
      });
    } catch (error) {
      console.log(error);
      reject(error);
    }
  });
};

export const WarrantyCardDetail = (payload) => {
  return new Promise((resolve, reject) => {
    try {
      WarrantyCardDetailRequest(payload).then((res) => {
        if (res.status === 200) {
          resolve(res.json());
        } else {
        }
      });
    } catch (error) {
      console.log(error);
      reject(error);
    }
  });
};

export const WarrantyRequest = (payload) => {
  return new Promise((resolve, reject) => {
    try {
      WarrantyRequestRequest(payload).then((res) => {
        if (res.status === 200) {
          resolve(res.json());
        } else {
          reject(res);
        }
      });
    } catch (error) {
      console.log(error);
      reject(error);
    }
  });
};

export const GetWarrantyRequest = (payload) => {
  return new Promise((resolve, reject) => {
    try {
      GetWarrantyRequestRequest(payload).then((res) => {
        if (res.status === 200) {
          resolve(res.json());
        } else {
        }
      });
    } catch (error) {
      console.log(error);
      reject(error);
    }
  });
};

export const WarrantyCardCardCode = (payload) => {
  return new Promise((resolve, reject) => {
    try {
      WarrantyCardCardCodeRequest(payload).then((res) => {
        if (res.status === 200) {
          resolve(res.json());
        } else {
        }
      });
    } catch (error) {
      console.log(error);
      reject(error);
    }
  });
};

export const GetReporteActivaciones = (payload) => {
  return new Promise((resolve, reject) => {
    try {
      GetReporteActivacionesRequest(payload).then((res) => {
        if (res.status === 200) {
          resolve(res.json());
        } else {
        }
      });
    } catch (error) {
      console.log(error);
      reject(error);
    }
  });
};

// corporativo

export const CorpWarrantyCard = (payload) => {
  return new Promise((resolve, reject) => {
    try {
      console.log(payload)
      CorpWarrantyCardRequest(payload).then((res) => {
        if (res.status === 200) {
          resolve(res.json());
        } else {
          reject(res);
        }
      });
    } catch (error) {
      console.log(error);
      reject(error);
    }
  });
};

export const getCorpWarrantyMinicod = (payload) => {
  return new Promise((resolve, reject) => {
    try {
      getCorpWarrantyByMinicoRequest(payload).then((res) => {
        if (res.status === 200) {
          resolve(res.json());
        } else {
          reject(res);
        }
      });
    } catch (error) {
      console.log(error);
      reject(error);
    }
  });
};

export const CorpMarcaModeloVehiculos = (payload) => {
  return new Promise((resolve, reject) => {
    try {
      CorpMarcaModeloVehiculosRequest(payload).then((res) => {
        if (res.status === 200) {
          resolve(res.json());
        } else {
        }
      });
    } catch (error) {
      console.log(error);
      reject(error);
    }
  });
};

export const getCorpFrecuenciaGarantia = (payload) => {
  return new Promise((resolve, reject) => {
    try {
      getCorpFrecuenciaGarantiaRequest(payload).then((res) => {
        if (res.status === 200) {
          resolve(res.json());
        } else {
        }
      });
    } catch (error) {
      console.log(error);
      reject(error);
    }
  });
};

export const CorpClientAddress = (payload) => {
  return new Promise((resolve, reject) => {
    try {
      CorpClientAddressRequest(payload).then((res) => {
        if (res.status === 200) {
          resolve(res.json());
        } else {
        }
      });
    } catch (error) {
      console.log(error);
      reject(error);
    }
  });
};

export const CorpWarrantyCardCardCode = (payload) => {
  return new Promise((resolve, reject) => {
    try {
      CorpWarrantyCardsCodeRequest(payload).then((res) => {
        if (res.status === 200) {
          resolve(res.json());
        } else {
        }
      });
    } catch (error) {
      console.log(error);
      reject(error);
    }
  });
};

// CorpWarrantyCardByIdRequest

export const CorpWarrantyCardById = (payload) => {
  return new Promise((resolve, reject) => {
    try {
      CorpWarrantyCardByIdRequest(payload).then((res) => {
        if (res.status === 200) {
          resolve(res.json());
        } else {
        }
      });
    } catch (error) {
      console.log(error);
      reject(error);
    }
  });
};

export const CorpGetWarrantyBy = (payload) => {
  return new Promise((resolve, reject) => {
    try {
      CorpGetWarrantyRequestRequestBy(payload).then((res) => {
        if (res.status === 200) {
          resolve(res.json());
        } else {
        }
      });
    } catch (error) {
      console.log(error);
      reject(error);
    }
  });
};

export const CorpGetWarrantyByMinicod = (payload) => {
  return new Promise((resolve, reject) => {
    try {
      CorpGetWarrantyRequestByMinicod(payload).then((res) => {
        if (res.status === 200) {
          resolve(res.json());
        } else {
        }
      });
    } catch (error) {
      console.log(error);
      reject(error);
    }
  });
};

export const CorpPostWarranty = (payload) => {
  return new Promise((resolve, reject) => {
    try {
      CorpPostWarrantyRequest(payload).then((res) => {
        if (res.status === 200) {
          resolve(res.json());
        } else {
        }
      });
    } catch (error) {
      console.log(error);
      reject(error);
    }
  });
};

export const CorpWarrantyRequestById = (payload) => {
  return new Promise((resolve, reject) => {
    try {
      CorpGetWarrantyRequestById(payload).then((res) => {
        if (res.status === 200) {
          resolve(res.json());
        } else {
        }
      });
    } catch (error) {
      console.log(error);
      reject(error);
    }
  });
};

export const CorpGetDetailByMinicod = (payload) => {
  return new Promise((resolve, reject) => {
    try {
      CorpGetDetailByMinicodRequest(payload).then((res) => {
        if (res.status === 200) {
          resolve(res.json());
        } else {
        }
      });
    } catch (error) {
      console.log(error);
      reject(error);
    }
  });
};

export const CorpGetReporteActivaciones = (payload) => {
  return new Promise((resolve, reject) => {
    try {
      CorpGetReporteActivacionesRequest(payload).then((res) => {
        if (res.status === 200) {
          resolve(res.json());
        } else {
        }
      });
    } catch (error) {
      console.log(error);
      reject(error);
    }
  });
};

export const CorpPostWarrantyCardMaintenance = (payload) => {
  return new Promise((resolve, reject) => {
    try {
      CorpPostWarrantyCardMaintenanceRequest(payload).then((res) => {
        if (res.status === 200) {
          resolve(res.json());
        } else {
        }
      });
    } catch (error) {
      console.log(error);
      reject(error);
    }
  });
};

export const CorpGetWarrantyCardsMaintenanceById = (payload) => {
  return new Promise((resolve, reject) => {
    try {
      CorpGetWarrantyCardsMaintenanceByIdRequest(payload).then((res) => {
        if (res.status === 200) {
          resolve(res.json());
        } else {
        }
      });
    } catch (error) {
      console.log(error);
      reject(error);
    }
  });
};

// CorpGetWarrantyCardsMaintenanceByIdRequest

// CorpPostWarrantyCardMaintenanceRequest
